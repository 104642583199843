import {Input, InputWrapper} from './styled';
import React, {memo, MouseEvent, useEffect, useState} from 'react'
import {Image} from '../../common';
import image from '../../../assets/icons/image.svg'
import imageDisabled from '../../../assets/icons/imageDisabled.svg'
import send from '../../../assets/icons/sendIcon.svg'
import {useMutation} from "@apollo/client";
import {LOGIN, UPLOAD_FILE} from "../../../api";
import Loader from "../../loader";

const ChatInput = ({text, setText, isCustomerActive, sendCommentDriver, isDriverActive, sendComment, isCustomer, isAdmin, sendCommentAdmin, userId}: PropsType) => {
    let isDriver = isCustomer === 'Driver' ? 'Type here to chat with driver' : 'Type here to chat with customer service'
    const [uploadFile, {loading, data}] = useMutation(UPLOAD_FILE, {onCompleted: data => data})

    async function onChange({
                                target: {
                                    validity,
                                    files,
                                },
                            }: React.ChangeEvent<HTMLInputElement>) {
        if (validity.valid) {
            let file
            if (files && files[0]) {
                file = files[0]
                await uploadFile({variables: {file, userId: Number(userId), isAdmin, toggle: isCustomer, isDriverActive, isCustomerActive}});
            }
        } else {
        }
    }
    if (loading) return <Loader/>
    return (
        <InputWrapper>

            {isCustomerActive && isCustomer === 'Customer' &&
                <>
                    <button className="chat__image-btn">
                        <label htmlFor="upload-file">
                            <Image src={image}/>
                        </label>
                        <input
                            type="file"
                            name="photo"
                            id="upload-file"
                            onChange={onChange}
                            accept={".png, .jpeg, .jpg"}
                            required
                        />
                    </button>
                    <Input
                        type="text"
                        placeholder={isDriver}
                        value={text}
                        onChange={(e) => {
                            setText(e.target.value)
                        }
                        }
                        onKeyUp={(evt) => {
                            if (evt.key === 'Enter' || evt.code === 'Enter') {
                                sendComment();
                            }
                        }}
                    />
                </>
            }
            {isCustomerActive && isCustomer === 'Driver' &&
                <>
                    <button className="chat__image-btn">
                        <label htmlFor="upload-file">
                            <Image src={image}/>
                        </label>
                        <input
                            type="file"
                            name="photo"
                            id="upload-file"
                            onChange={onChange}
                            accept={".png, .jpeg, .jpg"}
                            required
                        />
                    </button>
                    <Input
                        type="text"
                        placeholder={isDriver}
                        value={text}
                        onChange={(e) => {
                            setText(e.target.value)
                        }
                        }
                        onKeyUp={(evt) => {
                            if (evt.key === 'Enter' || evt.code === 'Enter') {
                                sendComment();
                            }
                        }}
                    />
                </>
            }

            {isDriverActive && isCustomer === 'Customer' &&
                <>
                    <button className="chat__image-btn">
                        <label htmlFor="upload-file">
                            <Image src={image}/>
                        </label>
                        <input
                            type="file"
                            name="photo"
                            id="upload-file"
                            onChange={onChange}
                            accept={".png, .jpeg, .jpg"}
                            required
                        />
                    </button>
                    <Input
                        type="text"
                        placeholder={isDriver}
                        value={text}
                        onChange={(e) => {
                            setText(e.target.value)
                        }
                        }
                        onKeyUp={(evt) => {
                            if (evt.key === 'Enter' || evt.code === 'Enter') {
                                sendCommentDriver();
                            }
                        }}
                    />
                </>
            }
            {isDriverActive && isCustomer === 'Driver' &&
                <>
                    <button className="chat__image-btn">
                        <label htmlFor="upload-file">
                            <Image src={image}/>
                        </label>
                        <input
                            type="file"
                            name="photo"
                            id="upload-file"
                            onChange={onChange}
                            accept={".png, .jpeg, .jpg"}
                            required
                        />
                    </button>
                    <Input
                        type="text"
                        placeholder={isDriver}
                        value={text}
                        onChange={(e) => {
                            setText(e.target.value)
                        }
                        }
                        onKeyUp={(evt) => {
                            if (evt.key === 'Enter' || evt.code === 'Enter') {
                                sendCommentDriver();
                            }
                        }}
                    />
                </>
            }

            {isAdmin && isCustomer === 'Customer' &&
                <>
                    <button className="chat__image-btn">
                        <label htmlFor="upload-file">
                            <Image src={image}/>
                        </label>
                        <input
                            type="file"
                            name="photo"
                            id="upload-file"
                            onChange={onChange}
                            accept={".png, .jpeg, .jpg"}
                            required
                        />
                    </button>
                    <Input
                        type="text"
                        placeholder={isDriver}
                        value={text}
                        onChange={(e) => {
                            setText(e.target.value)
                        }
                        }
                        onKeyUp={(evt) => {
                            if (evt.key === 'Enter' || evt.code === 'Enter') {
                                sendCommentAdmin();
                            }
                        }}
                    />
                </>
            }
            {isAdmin && isCustomer === 'Driver' &&
                <>
                    <button className="chat__image-btn">
                        <label htmlFor="upload-file">
                            <Image src={image}/>
                        </label>
                        <input
                            type="file"
                            name="photo"
                            id="upload-file"
                            onChange={onChange}
                            accept={".png, .jpeg, .jpg"}
                            required
                        />
                    </button>
                    <Input
                        type="text"
                        placeholder={isDriver}
                        value={text}
                        onChange={(e) => {
                            setText(e.target.value)
                        }
                        }
                        onKeyUp={(evt) => {
                            if (evt.key === 'Enter' || evt.code === 'Enter') {
                                sendCommentDriver();
                            }
                        }}
                    />
                </>
            }
            {isAdmin && isCustomer === 'Customer' &&<button className={text.length ? "chat__send-btn active" : "chat__send-btn"}
                                                            onClick={isAdmin ? sendCommentAdmin : sendComment}>
                <Image src={send}/>
            </button>}
            {isAdmin && isCustomer === 'Driver' &&<button className={text.length ? "chat__send-btn active" : "chat__send-btn"}
                                                          onClick={isAdmin ? sendCommentDriver : sendComment}>
                <Image src={send}/>
            </button>}
            {isDriverActive && <button className={text.length ? "chat__send-btn active" : "chat__send-btn"}
                                       onClick={isDriverActive ? sendCommentDriver : sendComment}>
                <Image src={send}/>
            </button>}
            {isCustomerActive && <button className={text.length ? "chat__send-btn active" : "chat__send-btn"}
                                         onClick={isDriverActive ? sendCommentAdmin : sendComment}>
                <Image src={send}/>
            </button>}
        </InputWrapper>
    )
}

export default ChatInput

interface PropsType {
    text: string
    setText: (text: string) => void
    sendComment: () => void
    sendCommentAdmin: () => void
    sendCommentDriver: () => void
    isCustomer: string
    isAdmin: boolean
    isDriverActive: boolean
    userId: number
    isCustomerActive: boolean
}
