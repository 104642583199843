import {AnimatePage, Loader} from '../../';
import {ButtonFlex, Image, BackButtonAdmin, Flex} from '../../common';
import {
    CancelOrderAdmin,
    OrderEdit,
    OrderContainerAdmin,
    WrapperAdmin,
    AdminMessage,
    TextBetweenButton,
    Text,

    TextScheduled,
    ProductsWrapperAdmin
} from './styled';
import {FlexDriver, CommentsOrder,TooltipPhoneContent,
    TooltipPhoneWrapper,} from '../styled';
import React, {useEffect, useState} from 'react';
import {
    ACCEPT_ADMIN_ORDER,
    COMPLETED_ADMIN_ORDER,
    ETF_ADMIN_ORDER,
    ETF_PAYMENT_ADMIN_ORDER,
    FIVE_MINUTES_ADMIN_ORDER,
    HERE_ADMIN_ORDER,
    REMOVE_ADMIN_ORDER,
    TEN_MINUTES_ADMIN_ORDER,
    TWENTY_MINUTES_ADMIN_ORDER
} from "../../../api";
import {OrderReviseType} from '../../../types';
import Product from '../../cart/product/product';
import arrowLeft from "../../../assets/icons/arrowLeftWhite.svg";
import {useMutation} from "@apollo/client";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import Name from "../../../assets/orders/Name.svg";
import Call from "../../../assets/orders/Call.svg";
import DeliveryDate from "../../../assets/orders/DeliveryDate.svg";
import Address from "../../../assets/orders/Address.svg";
import Price from "../../../assets/orders/Price.svg";
import Cart from "../../../assets/orders/Cart.svg";
import Comments from "../../../assets/orders/Comments.svg";
import NoComments from "../../../assets/orders/NoComments.svg";
import Scheduled from "../../../assets/orders/Scheduled.svg";
import {useToasts} from "react-toast-notifications";
import Change from "../../../assets/orders/Change.svg";
import moment from "moment";
import {useParams} from "react-router";
import Dollar from "../../../assets/orders/dollar.svg";
import Note from "../../../assets/orders/Note.svg";
const Revise = ({
                    isAdmin,
                    isDriverActive,
                    id,
                    token,
                    order,
                    setRoute,
                    data,
                    day,
                    comments,
                    allCount,
                    setActiveDate,
                    setActiveUread,
                    setAll,
                    setCompleted,
                    setEtf,
                    getActiveOrders
    }: PropsType) => {

    const location = useLocation()
    const {orderId} = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentId, setCurrentId] = useState<string | null>(null)
    const [noDraft, setNoDraft] = useState(false)
    const [removeAdminOrder, {loading: removeLoading}] = useMutation(REMOVE_ADMIN_ORDER)
    const [acceptAdminOrder, {loading: acceptLoading}] = useMutation(ACCEPT_ADMIN_ORDER)
    const [fiveMinutesAdminOrder, {loading: fiveMinutesLoading}] = useMutation(FIVE_MINUTES_ADMIN_ORDER)
    const [tenMinutesAdminOrder, {loading: tenMinutesLoading}] = useMutation(TEN_MINUTES_ADMIN_ORDER)
    const [twentyMinutesAdminOrder, {loading: twentyMinutesLoading}] = useMutation(TWENTY_MINUTES_ADMIN_ORDER)
    const [hereAdminOrder, {loading: hereLoading}] = useMutation(HERE_ADMIN_ORDER)
    const [etfAdminOrder, {loading: etfLoading}] = useMutation(ETF_ADMIN_ORDER)
    const [etfPaymentOrderAdmin, {loading: etfPaymentLoading}] = useMutation(ETF_PAYMENT_ADMIN_ORDER)
    const [completedAdminOrder, {loading: completedLoading}] = useMutation(COMPLETED_ADMIN_ORDER)
    const [status, setStatus] = useState<string>()
    const [savings, setSavings] = useState<number>(0)
    const [discount, setDiscount] = useState<string>()
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const removeOrderAdmin = async () => {
        await removeAdminOrder({
            variables: {
                phone: order?.phone,
                id: order?.id,
            }
        }).then(({data}) => {
            // @ts-ignore
            getActiveOrders({variables: {
                phone: token, isAdmin, isDriverActive, toggle: true
                }})
            // setActiveDate(true)
            // setActiveUread(false)
            // setAll(true)
            // setCompleted(false)
            // setEtf(false)
            setRoute('orders')
            addToast(`Order status was changed to "Canceled"`, {appearance: 'error', autoDismiss: true});
            setStatus('Canceled')

        })
    }
    async function acceptOrderAdmin() {
        await acceptAdminOrder({
            variables: {
                phone: order?.phone,
                id: order?.id,
            }
        }).then(({data}) => {
            // @ts-ignore
            getActiveOrders({variables: {
                    phone: token, isAdmin, isDriverActive, toggle: true
                }})
            // setActiveDate(false)
            // setActiveUread(true)
            // setAll(true)
            // setCompleted(false)
            // setEtf(false)
            setRoute('orders')

            setStatus('Accepted')

            addToast(`Order status was changed to "Accepted"`, {appearance: 'success', autoDismiss: true});
        })

    }
    async function fiveMinutesOrderAdmin() {
        await fiveMinutesAdminOrder({
            variables: {
                phone: order?.phone,
                id: order?.id,
            }
        }).then(({data}) => {
            setStatus('In 5 min')
            // @ts-ignore
            getActiveOrders({variables: {
                    phone: token, isAdmin, isDriverActive, toggle: true
                }})
            // setActiveDate(true)
            // setActiveUread(false)
            // setAll(true)
            // setCompleted(false)
            // setEtf(false)
            setRoute('orders')

            addToast(`Order status was changed to "5 min"`, {appearance: 'success', autoDismiss: true});
        })

    }
    async function tenMinutesOrderAdmin() {
        await tenMinutesAdminOrder({
            variables: {
                phone: order?.phone,
                id: order?.id,
            }
        }).then(({data}) => {
            setStatus('In 10 min')
            // @ts-ignore
            getActiveOrders({variables: {
                    phone: token, isAdmin, isDriverActive, toggle: true
                }})
            // setActiveDate(true)
            // setActiveUread(false)
            // setAll(true)
            // setCompleted(false)
            // setEtf(false)
            setRoute('orders')

            addToast(`Order status was changed to "10 min"`, {appearance: 'success', autoDismiss: true});
        })
    }
    async function twentyMinutesOrderAdmin() {
        await twentyMinutesAdminOrder({
            variables: {
                phone: order?.phone,
                id: order?.id,
            }
        }).then(({data}) => {
            setStatus('In 20 min')
            // @ts-ignore
            getActiveOrders({variables: {
                    phone: token, isAdmin, isDriverActive, toggle: true
                }})
            // setActiveDate(true)
            // setActiveUread(false)
            // setAll(true)
            // setCompleted(false)
            // setEtf(false)
            setRoute('orders')

            addToast(`Order status was changed to "20 min"`, {appearance: 'success', autoDismiss: true});
        })

    }
    async function hereOrderAdmin() {
        await hereAdminOrder({
            variables: {
                phone: order?.phone,
                id: order?.id,
            }
        }).then(({data}) => {
            setStatus('Here')
            // @ts-ignore
            getActiveOrders({variables: {
                    phone: token, isAdmin, isDriverActive, toggle: true
                }})
            // setActiveDate(true)
            // setActiveUread(false)
            // setAll(true)
            // setCompleted(false)
            // setEtf(false)
            addToast(`Order status was changed to "Here"`, {appearance: 'success', autoDismiss: true});
        })

    }
    async function etfOrderAdmin() {
        await etfAdminOrder({
            variables: {
                phone: order?.phone,
                id: order?.id,
            }
        }).then(({data}) => {
            setStatus('Etf')
            // @ts-ignore
            getActiveOrders({variables: {
                    phone: token, isAdmin, isDriverActive, toggle: true
                }})
            // setActiveDate(true)
            // setActiveUread(false)
            // setAll(false)
            // setCompleted(false)
            // setEtf(true)
            setRoute('orders')
            addToast(`Order status was changed to "Etf"`, {appearance: 'success', autoDismiss: true});
        })

    }

    async function paymentOrderAdmin() {
        await etfPaymentOrderAdmin({
            variables: {
                phone: order?.phone,
                id: order?.id,
            }
        }).then(({data}) => {
            setStatus('Etf-completed')
            // @ts-ignore
            getActiveOrders({variables: {
                    phone: token, isAdmin, isDriverActive, toggle: true
                }})
            // setActiveDate(true)
            // setActiveUread(false)
            // setAll(false)
            // setCompleted(false)
            // setEtf(true)
            setRoute('orders')
            addToast(`Order status was changed to "Etf-completed"`, {appearance: 'success', autoDismiss: true});
        })

    }
    async function completedOrderAdmin() {
        await completedAdminOrder({
            variables: {
                phone: order?.phone,
                id: order?.id,
            }
        }).then(({data}) => {
            setStatus('Completed')
            // @ts-ignore
            getActiveOrders({variables: {
                    phone: token, isAdmin, isDriverActive, toggle: true
                }})
            setRoute('orders')
            addToast(`Order status was changed to "Completed"`, {appearance: 'success', autoDismiss: true});
        })

    }
    useEffect(() => {
        if(order) {
            if(order.total && order.total_after_discount){
                setSavings(order.total - order.total_after_discount)
            }
            if(order?.user?.six_weeks_limited && order?.user?.seven_weeks_limited){
                const sixWeeks = moment().isAfter(order?.user?.six_weeks_limited)
                const sevenWeeks = moment().isAfter(order?.user?.seven_weeks_limited)
                if(sixWeeks && !sevenWeeks) {
                    setDiscount('10%')
                }
            }
        }
    }, [order, setSavings, setDiscount])
    if (removeLoading ||
        acceptLoading ||
        fiveMinutesLoading ||
        tenMinutesLoading ||
        twentyMinutesLoading ||
        hereLoading ||
        etfLoading ||
        completedLoading ||
        etfPaymentLoading
    ) return <Loader/>
    return (
        <AnimatePage>
            <OrderContainerAdmin>
                <div className="order__wrapper">
                    <Flex margin={"5px 10px 5px 5px"} className='order__admin-header'>
                        <OrderEdit className={"admin__edit"}>Order № {order?.id}</OrderEdit>
                        <div className={"admin__scheduled"}>
                            <img alt={'scheduled'} src={Scheduled}/>
                            {order?.status === 'draft'
                                ?<TextScheduled>Scheduled</TextScheduled>
                                :<TextScheduled>{status || order?.status}</TextScheduled>
                            }

                        </div>
                    </Flex>
                    <WrapperAdmin>
                        <ProductsWrapperAdmin>
                            {order?.products && order?.products.map((el, idx) =>
                                <Product key={idx ** 20}
                                         editMode={false}
                                         product={el}
                                         index={idx}
                                         products={order.products}
                                         deleteProduct={() => console.log('')}
                                         updateProduct={() => console.log('')}
                                         isAdmin={isAdmin}
                                         isDriverActive={isDriverActive}

                                />
                            )}
                        </ProductsWrapperAdmin>
                    </WrapperAdmin>
                    <div>
                        <AdminMessage>
                            <FlexDriver top={"1px"}>
                                <img alt={'name'} src={Name}/>
                                <Text>{order?.user?.first_name}</Text>
                                <div>
                                    <TooltipPhoneWrapper id={order?.id} onClick={(e) => {
                                        setCurrentId(e.currentTarget.id)
                                        setTimeout(()=>{setCurrentId('')},3000)
                                    }} className={currentId === order?.id? 'tooltip' : ''}>
                                        <a className='cart__info-image' >{order?.phone}</a>
                                        <TooltipPhoneContent className={currentId === order?.id? 'tooltip' : ''} >
                                            <a href={`tel:${order?.phone}`}>
                                                <div className="text">
                                                    <img alt={'call'} src={Call}/>
                                                    <p>Call</p>
                                                </div>
                                            </a>
                                        </TooltipPhoneContent>

                                    </TooltipPhoneWrapper>
                                </div>
                            </FlexDriver>
                            <FlexDriver>
                                <img alt={'date'} src={DeliveryDate}/><Text>{day} {data}, {order?.timeslot}</Text>
                            </FlexDriver>
                            <FlexDriver>
                                <img alt={'address'}
                                     src={Address}/><Text>{order?.address} | {order?.district.split('.')[0]}</Text>
                            </FlexDriver>
                            <FlexDriver>
                                <img alt={'price'} src={Price}/>
                                <Text>${order?.total_after_discount} ({discount ? `${discount}` : `$${savings}`})</Text>
                                <img  className={"img__change"} alt={'change'} src={Change}/>
                                {order?.change !== 'no change'
                                    ?<Text>${order?.change}</Text>
                                    :<Text>$0</Text>
                                }
                            </FlexDriver>
                            <FlexDriver>
                                <img alt={'dollar'}
                                     src={Dollar}/><Text>${order?.profit}</Text>
                                <img alt={'cart'} src={Cart}/>
                                <Text> x{order?.products.length}, {allCount}</Text>
                            </FlexDriver>
                            <FlexDriver>
                                <img alt={'note'}
                                     src={Note}/><Text>{order?.customer_comment || "none"}</Text>
                            </FlexDriver>
                            <CommentsOrder onClick={() => {
                                navigate(`customer_service/${order?.user?.id}?first_name=${order?.user?.first_name}&phone_user=${order?.phone}&toggle=Driver&orderFromDriver=${order?.id}`, {
                                })
                            }}>
                                {comments && comments > 0
                                    ?
                                    <>
                                        {comments > 9
                                        ?
                                            <>
                                                <img alt={'comments'} src={Comments}/>
                                                <Text>9+ new</Text>
                                            </>
                                            :
                                            <>
                                                <img alt={'comments'} src={Comments}/>
                                                <Text>{comments} new</Text>
                                            </>
                                        }

                                    </>
                                    :
                                    <>
                                        <img alt={'comments'} src={NoComments}/>
                                        <Text> </Text>
                                    </>
                                }
                            </CommentsOrder>
                        </AdminMessage>
                    </div>
                    {order?.status === 'draft' && <>
                        <ButtonFlex margin={'24px 0 0 0'}>
                            <BackButtonAdmin width={'160px'} onClick={acceptOrderAdmin}>
                                Accept
                            </BackButtonAdmin>
                            <CancelOrderAdmin onClick={removeOrderAdmin}>
                                Cancel
                            </CancelOrderAdmin>
                        </ButtonFlex>
                        <ButtonFlex margin={'64px 0 10px 0'} className={"margin__top"}>
                            <BackButtonAdmin width={"140px"} onClick={() => {

                                setRoute('orders')
                            }}>
                                <Image src={arrowLeft}/>
                                Back
                            </BackButtonAdmin>
                        </ButtonFlex>
                    </>}
                    {order?.status === 'etf' && <>
                        <TextBetweenButton className={"text__between-etf"}>Order was delivered at {moment(order?.delivery_date).format('HH:mm, D MMM').toLowerCase()}</TextBetweenButton>
                        <ButtonFlex margin={'12px 0 0 0'} justify={'center'}>
                            <BackButtonAdmin width={'270px'} onClick={paymentOrderAdmin}>
                                Payment received
                            </BackButtonAdmin>
                        </ButtonFlex>
                        <ButtonFlex margin={'30px 0 10px 0'} className={"margin__top"}>
                            <BackButtonAdmin width={"140px"} onClick={() => setRoute('orders')}>
                                <Image src={arrowLeft}/>
                                Back
                            </BackButtonAdmin>
                        </ButtonFlex>
                    </>}
                    {order?.status !== 'draft' && order?.status !== 'etf' &&
                        <>
                            <TextBetweenButton>Getting close?</TextBetweenButton>
                            <ButtonFlex margin={'0 0 0 0'}>
                                <BackButtonAdmin width={"72px"} onClick={fiveMinutesOrderAdmin}>
                                    5
                                </BackButtonAdmin>
                                <BackButtonAdmin width={"72px"} onClick={tenMinutesOrderAdmin}>
                                    10
                                </BackButtonAdmin>
                                <BackButtonAdmin width={"72px"} onClick={twentyMinutesOrderAdmin}>
                                    20
                                </BackButtonAdmin>
                                <BackButtonAdmin width={"94px"} onClick={hereOrderAdmin}>
                                    Here
                                </BackButtonAdmin>
                            </ButtonFlex>
                            <TextBetweenButton>Delivered?</TextBetweenButton>
                            <ButtonFlex>
                                <BackButtonAdmin width={"140px"} onClick={etfOrderAdmin}>
                                    ETF
                                </BackButtonAdmin>
                                <BackButtonAdmin width={"180px"} onClick={completedOrderAdmin}>
                                    Completed
                                </BackButtonAdmin>
                            </ButtonFlex>
                            <ButtonFlex margin={'60px 0 10px 0'} className={"margin__top"}>
                                <BackButtonAdmin width={"140px"} onClick={() =>
                                {
                                    setRoute('orders')}
                                    }>
                                    <Image src={arrowLeft}/>
                                    Back
                                </BackButtonAdmin>
                                <CancelOrderAdmin onClick={removeOrderAdmin}>
                                    Cancel
                                </CancelOrderAdmin>
                            </ButtonFlex>
                        </>
                    }
                </div>
            </OrderContainerAdmin>
        </AnimatePage>
    );
}

export default Revise;

interface PropsType {
    token: string | null
    id?: string
    order?: OrderReviseType
    setRoute: (route: string) => void
    day?: string
    data?: string
    allCount?: number
    comments?: number
    isAdmin: boolean
    isDriverActive: boolean
    setActiveUread: (active:boolean) => void
    setActiveDate: (active:boolean) => void
    setAll: (active:boolean) => void
    setEtf: (active:boolean) => void
    setCompleted: (active:boolean) => void
    getActiveOrders: () => any
}

