import './index.css';

import {ApolloProvider,} from '@apollo/client'
import App from './App';
import {BrowserRouter} from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';
import {ToastProvider} from 'react-toast-notifications';
import {client} from './apollo';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <ToastProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </ToastProvider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
reportWebVitals();

// @ts-ignore
serviceWorker.register();

