import {gql} from "@apollo/client";


export const GET_DRAFT_ORDERS = gql`
    query GetDraftOrders($phone: String!) {
        getDraftOrders(phone: $phone) {
            isClose
            address
        }
    }
`

export const GET_ORDERS = gql`
    query GetAllOrders($phone: String!) {
        getAllOrders(phone: $phone) {
            status
            delivery_date
            id
        }
    }
`
export const GET_ACTIVE_ORDERS = gql`
    query GetActiveOrders($phone: String!, $isAdmin: Boolean!, $isDriverActive: Boolean!, $toggle: Boolean!) {
        getActiveOrders(phone: $phone, isAdmin: $isAdmin, isDriverActive: $isDriverActive, toggle: $toggle) {
            id
            customer_comment
            phone
            status
            user {
                first_name
                six_weeks_limited
                seven_weeks_limited
                id
                comments {
                    isRead
                    role
                    isAdminRead
                }
            }
            change
            products {
                name
                pack
                measure
                category
                id
                img_url
                group
                cost
                count
                got_gift_pairs {
                    name
                    is_gift
                    gift_holder
                    cost
                }
                costs {
                    unit
                    cost
                }
            }
            total_after_discount
            total
            timeslot
            district
            
            cut_offs
            address
            delivery_date
            profit
            comments {
                text
                isRead
            }
        }
    }
`

export const GET_STATISTICS = gql`
    query GetStatistics($phone: String!) {
        getStatistics(phone: $phone) {
            year
            total_year
            total_after_discount_year
            profit_year
            total_etf_year
            total_after_discount_etf_year
            profit_etf_year
            months {
                month
                total
                total_after_discount
                profit
                total_etf
                total_after_discount_etf
                profit_etf
            }

        }
    }
`

export const GET_ORDER = gql`
    query GetOrder($id: String!) {
        getOrder(id: $id) {
            id
            phone
            user {
                first_name
                six_weeks_limited
                seven_weeks_limited
                id
                comments {
                    isRead
                    role
                    isAdminRead
                }
            }
            products {
                name
                pack
                measure
                category
                id
                img_url
                group
                cost
                count
                got_gift_pairs {
                    name
                    is_gift
                    gift_holder
                    cost
                }
                costs {
                    unit
                    cost
                }
            }
            total_after_discount
            timeslot
            district
            cut_offs
            address
            delivery_date
            comments {
                text
                isRead
            }
        }
    }
`
