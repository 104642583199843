import "moment-timezone"

import {RouteResponseType} from '../../types';
import moment from 'moment';

const map_day = {
    "Monday": '1',
    "Tuesday": '2',
    "Wednesday": '3',
    "Thursday": '4',
    "Friday": '5',
    "Saturday": '6',
    "Sunday": '7',
} as { [key: string]: string }

const delivery = {
    getDileveryWindows: (routes: RouteResponseType[]): RouteResponseType[] => {
        const currentDay = moment().format('dddd')
        const tomorrow = moment().add(1, 'day').format('dddd');
        const dayAfterTomorrow = moment().add(2, 'day').format('dddd');
        const newArray = routes.map(i => i.weekday)
        const array = Array.from(new Set(newArray))
        let index = routes.findIndex(el => el.weekday === currentDay)
        if(index === -1) {
            index = 1
        }
        let temp = [] as RouteResponseType[]
        if (routes.length > 0) {
            const scheduleChanges = moment().startOf('week').add(1, 'week').fromNow()
            const minutes = 30
            const hours = 11
            const sunday = moment().startOf('week').add(1, 'week').format(`dddd, MMMM Do, ${hours}:${minutes}:ss a`)
            const isAfter = moment().isAfter(sunday)
            for (let i = index; i < routes.length; i++) {
                let {cutOff, timeslot, weekday} = routes[i]
                if (weekday === tomorrow) {
                    if (isAfter && tomorrow === 'Monday') {
                        temp.push({cutOff: '', timeslot: `Menu changes ${scheduleChanges}`, weekday})
                    } else {
                        temp.push({cutOff, timeslot, weekday})

                    }
                }
                if (weekday === currentDay) {
                    temp.push({cutOff, timeslot, weekday})
                }
                if (weekday === dayAfterTomorrow) {
                    if (isAfter && dayAfterTomorrow === 'Monday') {
                        temp.push({cutOff: '', timeslot: `Menu changes ${scheduleChanges}`, weekday})
                    } else if (isAfter && dayAfterTomorrow === 'Tuesday') {
                        temp.push({cutOff: '', timeslot: `Menu changes ${scheduleChanges}`, weekday})
                    } else {
                        temp.push({cutOff, timeslot, weekday})

                    }
                }
            }
        } else {
            return [
                {weekday: 'Monday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Tuesday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Wednesday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Thursday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Friday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Saturday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Sunday', timeslot: 'Day Off!', cutOff: ''},
            ]
        }
        return temp.map(i => {
            return {cutOff: i.cutOff, timeslot: i.timeslot.replace(/[a-zа-яё]/gi, ''), weekday: i.weekday}
        })

    },

    getChooseDeliveryWindow: (routes: RouteResponseType[]): RouteResponseType[] => {
        const currentTime = moment().format('hh:mma')
        const currentDay = moment().format('dddd')
        const tomorrow = moment().add(1, 'day').format('dddd');
        const dayAfterTomorrow = moment().add(2, 'day').format('dddd');
        let temp = [] as RouteResponseType[]
        if (routes.length > 0) {
            let lastCutOff
            const newTime = moment().format('YYYY-MM-DD HH:mma')
            const sundayArray = routes.filter(i => i.weekday === 'Sunday')
            if(sundayArray && sundayArray.length === 1){
                lastCutOff = sundayArray[0].cutOff
            }
            if(sundayArray && sundayArray.length === 2){
                if(sundayArray[0].cutOff === sundayArray[1].cutOff) {
                    lastCutOff = sundayArray[0].cutOff
                }
                if(moment(`${newTime.split(' ')[0]} ${sundayArray[0].cutOff}`).isAfter(`${newTime.split(' ')[0]} ${sundayArray[1].cutOff}`)) {
                    lastCutOff = sundayArray[0].cutOff
                } else {
                    lastCutOff = sundayArray[1].cutOff
                }
            }
            const noTimeslot = routes.filter( i => i.timeslot === "No timeslot")
            noTimeslot.forEach(i => {
                if(i.cutOff){
                    lastCutOff = i.cutOff
                }
            })
            let presentTime
            if(lastCutOff && lastCutOff.includes('am')) {
                let hours = Number(lastCutOff.split(':')[0])
                let minutes = Number(lastCutOff.split(':')[1].replace(/[a-zа-яё]/gi, ''))
                presentTime = moment().hour(hours).minutes(minutes)
                if(currentDay === "Saturday") {
                    presentTime = moment().hour(hours).minutes(minutes).add(1, 'day')
                }
                if(currentDay === "Sunday") {
                    presentTime = moment().hour(hours).minutes(minutes)
                }
            }
            if(lastCutOff && lastCutOff.includes('pm')) {
                let hours = Number(lastCutOff.split(':')[0])
                if(hours !== 12) {
                    hours += 12
                }
                let minutes = Number(lastCutOff.split(':')[1].replace(/[a-zа-яё]/gi, ''))
                presentTime = moment().hour(hours).minutes(minutes)
                if(currentDay === "Saturday") {
                    presentTime = moment().hour(hours).minutes(minutes).add(1, 'day')
                }
                if(currentDay === "Sunday") {
                    presentTime = moment().hour(hours).minutes(minutes)
                }
            }
            const isBeforeCutoffSunday = moment().isBefore(presentTime)
            const scheduleChanges = moment(presentTime).fromNow()
            for (let i = 0; i < routes.length; i++) {
                let {cutOff, timeslot, weekday} = routes[i]
                if (weekday === currentDay) {
                    let time
                    const current = moment().format('DD-MM-YYYY h:mma')
                    const current_date = moment.tz(`${current}`, 'DD-MM-YYYY h:mma', "America/Winnipeg").format();
                    if(cutOff && cutOff.includes('am')) {
                        let hours = Number(cutOff.split(':')[0])
                        let minutes = Number(cutOff.split(':')[1].replace(/[a-zа-яё]/gi, ''))
                        time = moment(current_date).hour(hours).minutes(minutes)
                    }

                    if(cutOff && cutOff.includes('pm')) {
                        let hours = Number(cutOff.split(':')[0])
                        if(hours !== 12) {
                            hours += 12
                        }
                        let minutes = Number(cutOff.split(':')[1].replace(/[a-zа-яё]/gi, ''))
                        time = moment(current_date).hour(hours).minutes(minutes)
                    }
                    const noCutoff = moment(current_date).isBefore(time)
                    if(!noCutoff) {
                        temp.push({cutOff: '', timeslot: `Cutoff has passed!`, weekday})
                    } else {
                        temp.push({cutOff, timeslot: `${timeslot}pm`, weekday})
                    }
                }
                if (weekday === tomorrow) {
                    if (tomorrow === 'Monday') {
                        if(isBeforeCutoffSunday){
                            temp.push({cutOff: '', timeslot: `Menu changes ${scheduleChanges.replace(/ago/g, '')}`, weekday})
                        } else {
                            temp.push({cutOff, timeslot: `${timeslot}pm`, weekday})
                        }
                    } else {
                        temp.push({cutOff, timeslot: `${timeslot}pm`, weekday})
                    }
                }
            }
        } else {
            const newRoutes = [
                {weekday: 'Monday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Tuesday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Wednesday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Thursday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Friday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Saturday', timeslot: 'Day Off!', cutOff: ''},
                {weekday: 'Sunday', timeslot: 'Day Off!', cutOff: ''},
            ]
            for (let i = 0; i < newRoutes.length; i++) {
                let {cutOff, timeslot, weekday} = newRoutes[i]
                if (weekday === tomorrow) {
                        temp.push({cutOff, timeslot, weekday})
                }
                if (weekday === currentDay) {
                    temp.push({cutOff, timeslot, weekday})
                }
            }
        }
        const todayFind = temp.find(i => i.weekday === currentDay)
        const tomorrowFind = temp.find(i => i.weekday === tomorrow)
        const dayAfterTomorrowFind = temp.find(i => i.weekday === dayAfterTomorrow)
        if(!todayFind) {
            temp.push({weekday: currentDay, timeslot: 'Day Off!', cutOff: ''})
        }
        if(!tomorrowFind) {
            temp.push({weekday: tomorrow, timeslot: 'Day Off!', cutOff: ''})
        }
            return temp.sort((a,b)=> {
                if(moment(a.weekday).isAfter(b.weekday)){
                    return 1
                } else return -1
            })
    },
    formatTimeForDelivery: (day: string, time: string) => {
        const curr = moment().format()
        const currentDay = moment(curr).format('dddd');
        const isDayOff = currentDay === 'Sunday' || currentDay === 'Saturday' ? -1 : 0;
        const delivery_day = moment().day(map_day[day]).format('DD-MM-YYYY')
        const delivery_date = moment.tz(`${delivery_day} ${time}`, 'DD-MM-YYYY h:mma', "America/Winnipeg").format();
        const current = moment().format('DD-MM-YYYY h:mma')
        moment.tz.setDefault('America/Winnipeg')
        const current_date = moment.tz(`${current}`, 'DD-MM-YYYY h:mma', "America/Winnipeg").format();
        return {delivery_date, current_date, currentDay}
    },
    checkCutoff: (deliveryDate: string, cutoff: string): boolean => {
        const currentDay = moment().format('DD-MM-YYYY h:mma')
        const delivery_day = moment(deliveryDate).format('DD-MM-YYYY')
        const cutoff_day = moment.tz(`${delivery_day} ${cutoff}`, 'DD-MM-YYYY h:mma', "America/Winnipeg").format();
        const formattedDay = moment.tz(`${currentDay}`, 'DD-MM-YYYY h:mma', "America/Winnipeg").format();
        moment.tz.setDefault('America/Winnipeg')
        const isExpired = formattedDay > cutoff_day
        return isExpired
    },
    isSaturdayOrSunady: (): boolean => {
        const include = ['Saturday', 'Sunday']
        const currentDay = moment().format('dddd')
        return include.includes(currentDay)
    },

    formatTimeForDelivery2: (routes: RouteResponseType[]) => {
        let data = [] as deliveryTimeType[];
        const curr = moment().format()
        const currentDay = moment(curr).format('dddd');
        const tomorrow = moment(curr).add(1, 'day').format('dddd');
        const dayAfterTomorrow = moment(curr).add(2, 'day').format('dddd');
        const isDayOff = currentDay === 'Sunday' || currentDay === 'Saturday' ? -1 : 0;
        const current = moment().format('DD-MM-YYYY h:mma')
        const current_date = moment.tz(`${current}`, 'DD-MM-YYYY h:mma', "America/Winnipeg").format();
        routes.forEach((route, idx) => {
            const delivery_day = moment().day(map_day[route.weekday]).add(isDayOff, 'week').format('DD-MM-YYYY')
            const delivery_date = moment.tz(`${delivery_day} ${route.timeslot}`, 'DD-MM-YYYY h:mma', "America/Winnipeg").format();
            if (route.weekday === currentDay && delivery_date < current_date) {
                data.push({...route, isCutoff: true, day: ''})
            } else {
                data.push({...route, isCutoff: false, day: ''})
            }
            if (route.timeslot === 'Day Off!') {
                data[idx] = {...data[idx], dayOff: true}
            } else {
                data[idx] = {...data[idx], dayOff: false}
            }
            if (route.timeslot.slice(0, 4) === 'Menu') {
                data[idx] = {...data[idx], changedPeriod: true}
            } else {
                data[idx] = {...data[idx], changedPeriod: false}
            }
            if (route.weekday === tomorrow) {
                data[idx] = {...data[idx], day: 'Tomorrow'}
            }
            if (route.weekday === currentDay) {
                data[idx] = {...data[idx], day: 'Today'}
            }
            if (route.weekday === dayAfterTomorrow) {
                data[idx] = {...data[idx], day: route.weekday}
            }
        });
        return data
    },
    formatRoutesDeliveryWindows: (routes: RouteResponseType[]) => {
        let schedule = [] as deliveryWindowsTwoTimeslotType[];
        let monday = routes.filter((route, idx) => {
            if (route.weekday === 'Monday') {
                return route
            }
        })
        if (monday.length === 0) {
            monday = [{weekday: 'Monday', timeslot: 'Day Off!', cutOff: ''}]
        }

        if (!monday) return null
        let tuesday = routes.filter((route, idx) => {
            if (route.weekday === 'Tuesday') {
                return route
            }
        })
        if (tuesday.length === 0) {
            tuesday = [{weekday: 'Tuesday', timeslot: 'Day Off!', cutOff: ''}]
        }
        if (!tuesday) return null
        let wednesday = routes.filter((route, idx) => {
            if (route.weekday === 'Wednesday') {
                return route
            }
        })
        if (wednesday.length === 0) {
            wednesday = [{weekday: 'Wednesday', timeslot: 'Day Off!', cutOff: ''}]
        }
        if (!wednesday) return null
        let thursday = routes.filter((route, idx) => {
            if (route.weekday === 'Thursday') {
                return route
            }
        })
        if (thursday.length === 0) {
            thursday = [{weekday: 'Thursday', timeslot: 'Day Off!', cutOff: ''}]
        }
        if (!thursday) return null
        let friday = routes.filter((route, idx) => {
            if (route.weekday === 'Friday') {
                return route
            }
        })
        if (friday.length === 0) {
            friday = [{weekday: 'Friday', timeslot: 'Day Off!', cutOff: ''}]
        }
        if (!friday) return null
        let saturday = routes.filter((route, idx) => {
            if (route.weekday === 'Saturday') {
                return route
            }
        })
        if (saturday.length === 0) {
            saturday = [{weekday: 'Saturday', timeslot: 'Day Off!', cutOff: ''}]
        }
        if (!saturday) return null

        let sunday = routes.filter((route, idx) => {
            if (route.weekday === 'Sunday') {
                return route
            }
        })
        if (sunday.length === 0) {
            sunday = [{weekday: 'Sunday', timeslot: 'Day Off!', cutOff: ''}]
        }
        if (!sunday) return null
        return {monday, tuesday, wednesday, thursday, friday, saturday, sunday}
    },
}

export default delivery;

interface deliveryTimeType {
    timeslot: string
    weekday: string
    cutOff: string
    isCutoff?: boolean
    dayOff?: boolean
    changedPeriod?: boolean
    day: string
}

interface deliveryWindowsTwoTimeslotType {

    weekday: string
    one: {
        cutOff: string
        timeslot: string
    },
    two: {
        cutOff: string
        timeslot: string
    },


}

interface deliveryWindowsOneTimeslotType {
    timeslot: string
    weekday: string
    cutOff: string

}
