import {GET_USERS, GET_SEARCH, GET_LOGGING} from '../../api'
import React, {memo, useEffect, useState} from 'react'
import {useLazyQuery} from '@apollo/client'
import {
    AdminWrapper,
    AdminMessage,
    Text,
    TimeAdmin,
    LastMessage,
    TitleAdmin,
    TimeAdminRead,
    SearchInput,
    LastLogin
} from './styled'
import {v4 as uuidv4} from "uuid";
import Loader from "../loader";
import moment from "moment";
import {useNavigate, useParams} from "react-router";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import Search from "../../assets/Search.svg";
import Close from "../../assets/Close.svg";
import Check from "../../assets/Check.svg";
import SignInLog from "../../assets/SignInLog.svg";
import BackTo from "../../assets/backTo.svg";

const Admin = ({token, isAdmin}: PropsType) => {
    const [getUsersCommentsForAdmin, {loading, data}] = useLazyQuery(GET_USERS)
    const [getUsersLoggingForAdmin, {loading: loadingLogging, data: dataLogging}] = useLazyQuery(GET_LOGGING)
    const [toggle, setToggle] = useState(false)
    const [users, setUsers] = useState(null)
    const [search, setSearch] = useState<string>('')
    const [result, setResult] = useState()
    const [route, setRoute] = useState("comments")
    const navigate = useNavigate()
    const location = useLocation()
    const [getSearchForAdmin, {
        loading: loadingSearch,
        data: dataSearch
    }] = useLazyQuery(GET_SEARCH, {variables: {search: search}, fetchPolicy: 'no-cache'})
    moment.updateLocale('en', {
        relativeTime: {
            future: "in %s ago",
            past: "%s ago",
            s: '1s. ago',
            ss: '%d.s ago',
            m: "1m. ago",
            mm: "%dm. ago",
            h: "1h. ago",
            hh: "%dh. ago",
            d: "1d. ago",
            dd: "%dd. ago",
            // w: "a week ago",
            // ww: "%d weeks ago",
            M: "1mo. ago",
            MM: "%dmo. ago",
            y: "a year ago",
            yy: "%d years ago"
        }
    });
    useEffect(() => {
        if (isAdmin) {
            token && getUsersCommentsForAdmin()
                .then(({data}) => {
                    if (data?.getUsersCommentsForAdmin) {
                        if (search.length <= 2) {
                            setUsers(data?.getUsersCommentsForAdmin)
                            setToggle(false)
                        }
                    }
                })
        }
        if (isAdmin && search && search.length > 2) {
            getSearchForAdmin()
                .then(({data}) => {
                    if (dataSearch?.getSearchForAdmin) {
                        setResult(dataSearch?.getSearchForAdmin)
                        console.log('search.length:',search.length)
                        if (search.length > 2) {
                            setToggle(true)
                            setUsers(dataSearch?.getSearchForAdmin)
                        }
                    }
                })
        }
        if (isAdmin && route === "log") {
            token && getUsersLoggingForAdmin()
                .then(({data}) => {})
        }
    }, [token, isAdmin, data, search, route, dataSearch?.getSearchForAdmin])
    if (loading || loadingLogging) {
        return <Loader/>
    }
    // console.log('search.length:',search.length)
    // console.log("result_______:", result, search, users)

    function clearSearch() {
        setSearch('')
    }

    return (
        <>
            <AdminWrapper>
                {route === "log" && <>
                    <div className="back__all-user" onClick={() => setRoute("comments")}>
                        <img alt={'back'} src={BackTo}/>
                        <div>Back to Customer Service</div>
                    </div>
                    <TitleAdmin>Sign-in log</TitleAdmin>
                    {dataLogging?.getUsersLoggingForAdmin && dataLogging?.getUsersLoggingForAdmin.map((i: {
                        user_id: number;
                        first_name: string,
                        phone: string,
                        isAdminRead: boolean,
                        id: string,
                        created: Date
                    }) => {
                        return (
                            <>
                                <AdminMessage
                                    key={uuidv4()}
                                    onClick={() => {
                                        navigate(`customer_service/${i.user_id}?first_name=${i.first_name}&phone_user=${i.phone}&toggle=Customer`, {
                                            // replace: true,
                                            // state: {phone_user: i.phone, first_name: i.first_name, id_user: i.id},
                                        })
                                    }}
                                >
                                    <Text>{i.first_name} {i.phone}</Text>
                                    <LastLogin>
                                        Last login:&nbsp;
                                        <TimeAdminRead>
                                            {moment(i.created).fromNow(true)}
                                        </TimeAdminRead>
                                    </LastLogin>
                                </AdminMessage>
                            </>
                        )
                    })
                    }
                </>}
                {route === "comments" &&
                    <>
                        {isAdmin &&
                            <div className="wrapper-admin-link">
                                <Link to={'/broadcast'}>
                                    <div className="back__all-user">
                                        <img alt={'back'} src={Check}/>
                                        <div>Send broadcast</div>
                                    </div>
                                </Link>
                                <Link to={'/'} onClick={() => {
                                    setRoute("log")
                                }
                                }>
                                    <div className="back__all-user">
                                        <img alt={'sign-in-log'} src={SignInLog}/>
                                        <div>Sign-in log</div>
                                    </div>
                                </Link>
                            </div>
                        }
                        <TitleAdmin>Customer service</TitleAdmin>
                        <div className="search__all-user">
                            <div className={"flex-input"}>
                                <img className="img-search" alt={"search"} src={Search}/>
                                {search.length > 0 &&
                                    <img className="img-search-close" alt={"close"} src={Close} onClick={clearSearch}/>}
                                <SearchInput
                                    autoFocus
                                    type="text"
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    placeholder='Search'
                                />
                            </div>
                            <div
                                className={"text-search"}>{search.length > 2 ? `${dataSearch?.getSearchForAdmin.length || 0} found` : ""}</div>
                        </div>
                        {toggle && dataSearch?.getSearchForAdmin && dataSearch?.getSearchForAdmin.map((i: {
                            last_comment: string;
                            first_name: string,
                            phone: string,
                            isAdminRead: boolean,
                            id: number,
                            last_login: Date
                        }) => {
                            return (
                                <>
                                    <AdminMessage
                                        key={uuidv4()}
                                        onClick={() => {
                                            navigate(`customer_service/${i.id}?first_name=${i.first_name}&phone_user=${i.phone}&toggle=Customer`, {
                                                // replace: true,
                                                // state: {phone_user: i.phone, first_name: i.first_name, id_user: i.id},
                                            })
                                        }}
                                    >
                                        <Text>{i.first_name} {i.phone}</Text>
                                        <LastMessage>
                                                <span>
                                                  Last
                                                message: {i.isAdminRead
                                                    ?
                                                    <TimeAdminRead>{i.last_comment === "none" ? "none" : moment(i.last_comment).fromNow(true)}</TimeAdminRead>
                                                    :
                                                    <TimeAdmin>{i.last_comment === "none" ? "none" : moment(i.last_comment).fromNow(true)}</TimeAdmin>
                                                }
                                                </span>
                                            <span>
                                                   Last login:&nbsp;
                                                <TimeAdminRead>{i.last_login ? moment(i.last_login).fromNow(true) : "none"}</TimeAdminRead>
                                                </span>
                                        </LastMessage>
                                    </AdminMessage>
                                </>
                            )
                        })
                        }
                        {!toggle && data?.getUsersCommentsForAdmin && data?.getUsersCommentsForAdmin.map((i: {
                            last_comment: string;
                            first_name: string,
                            phone: string,
                            isAdminRead: boolean,
                            id: number,
                            last_login: Date
                        }) => {
                            return (
                                <>
                                    <AdminMessage
                                        key={uuidv4()}
                                        onClick={() => {
                                            navigate(`customer_service/${i.id}?first_name=${i.first_name}&phone_user=${i.phone}&toggle=Customer`, {
                                                // replace: true,
                                                // state: {phone_user: i.phone, first_name: i.first_name, id_user: i.id},
                                            })
                                        }}
                                    >
                                        <Text>{i.first_name} {i.phone}</Text>
                                            <LastMessage>
                                                <span>
                                                  Last
                                                message: {i.isAdminRead
                                                    ?
                                                    <TimeAdminRead>{moment(i.last_comment).fromNow(true)}</TimeAdminRead>
                                                    :
                                                    <TimeAdmin>{moment(i.last_comment).fromNow(true)}</TimeAdmin>
                                                }
                                                </span>
                                                <span>
                                                   Last login:&nbsp;
                                                    <TimeAdminRead>{i.last_login ? moment(i.last_login).fromNow(true) : "none"}</TimeAdminRead>
                                                </span>
                                            </LastMessage>
                                    </AdminMessage>
                                </>
                            )
                        })
                        }
                    </>
                }
            </AdminWrapper>
        </>
    )
}

export default memo(Admin);

interface PropsType {
    token: string,
    isAdmin: boolean
}
