//@ts-nocheck
import {CREATE_ORDER_COMMENT, CREATE_USER_COMMENT, GET_ORDERS, CREATE_ADMIN_COMMENT} from '../../api'
import React, {memo, useCallback, useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'

import ChatInput from './input'
import {ChatWrapper, FlexLink} from './styled'
import DeskTopNotification from './header/notification'
import Dialogues from './dialogues'
import Header from './header'
import {OrderType} from '../../types'
import {local} from '../../utils';
import useDriverComments from '../../hooks/subscription/useDriverComments'
import useUserComments from '../../hooks/subscription/useUserComments'
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import BackTo from "../../assets/backTo.svg"
import Undo from "../../assets/orders/Undo.svg"
import {useParams} from "react-router";

const include = ['draft', 'approved', 'canceled', 'completed', 'pending', 'in 20 min', 'in 10 min', 'in 5 min', 'here', 'etf', 'etf-completed']

const Chat = ({role, id, orderId, isAdmin ,isDriverActive}: PropsType) => {
    let phone = local?.getToken()
    const location = useLocation()
    const {userId} = useParams()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const first_name = searchParams.get("first_name")
    const phone_user = searchParams.get("phone_user")
    const queryToggle = searchParams.get("toggle")
    const orderFromDriver = searchParams.get("orderFromDriver")
    console.log('queryToggle:', queryToggle)
    console.log('orderFromDriver:', orderFromDriver)
    const [setDriver, setOrderFormDriver] = useState("")

    console.log('userId, first_name, phone_user, isAdmin,isDriverActive, orderId, isCustomerActive:', userId, first_name, phone_user, isAdmin, orderId)
    let isCustomerActive = true
    if(isAdmin) {
        phone = phone_user
        isCustomerActive = false
    }
    if(isDriverActive) {
        phone = phone_user
        isCustomerActive = false
    }
    // console.log('location:',location)
    const [orderUserComments] = useMutation(CREATE_ORDER_COMMENT)
    const [userComments] = useMutation(CREATE_USER_COMMENT)
    const [createAdminComment] = useMutation(CREATE_ADMIN_COMMENT)
    ;
    const {getDriverComments, reloadDriver} = useDriverComments(phone || '')
    const {getUserComments, reloadUser} = useUserComments(phone || '')
    const {data: orders} = useQuery<{ getAllOrders: OrderType[] }, { phone: string | null }>(GET_ORDERS, {
        variables: {phone: isCustomerActive ? phone : phone_user},
        fetchPolicy: "cache-and-network"
    })
    const isOrders = orders?.getAllOrders.filter(order => include.includes(order.status)) || [];
    // console.log('comments:',getDriverComments)
    //======================ADMIN============================//
    const [adminReadPhone, setAdminReadPhone] = useState<string>('')
    const [adminReadFirstName, setAdminReadFirstName] = useState<string>('')
    const [adminReadId, setAdminReadId] = useState<number>(null)
    // @ts-ignore
    useEffect(() => {
        if (isAdmin) {
            if(orderFromDriver){
                setOrderFormDriver(orderFromDriver)
            }
            if(location.state) {
                setAdminReadPhone(location.state.phone_user)
            } else {

                setAdminReadPhone(phone_user)

            }
            if(location.state){
                setAdminReadFirstName(location.state.first_name )
            } else {
                setAdminReadFirstName(first_name)

            }
            if(location.state){
                setAdminReadId(Number(location.state.id_user))
            } else {
                setAdminReadId(Number(userId))

            }

        }
        if (isDriverActive) {

            if(location.state) {
                setAdminReadPhone(location.state.phone_user)
            } else {

                setAdminReadPhone(phone_user)

            }
            if(location.state){
                setAdminReadFirstName(location.state.first_name )
            } else {
                setAdminReadFirstName(first_name)

            }
            if(location.state){
                setAdminReadId(Number(location.state.id_user))
            } else {
                setAdminReadId(Number(userId))

            }

        }
        if(queryToggle){
            setToggle(queryToggle)
        }
    },[location, phone, orderFromDriver, phone_user, first_name, userId, queryToggle])
    const isUserRead = getUserComments?.filter(i =>
        i.role === 'admin' ||
        i.role === 'trusted' ||
        i.role === 'newbie' ||
        i.role === 'announcement' ||
        i.role === 'notification' ||
        i.role === 'five_weeks' ||
        i.role === 'six_weeks' ||
        i.role === 'seven_weeks' ||
        i.role === 'refer_a_friend' ||
        i.role === 'copied' ||
        i.role === 'max_rating' ||
        i.role === 'dont_max_rating' ||
        i.role === 'etf-completed' ||
        i.role === 'completed' ||
        i.role === 'images' ||
        i.role === 'images_admin' ||
        i.role === 'images_driver' ||
        i.role === 'images_client_driver' ||
        i.role === 'images_admin_driver'


    ).some(el => !el.isRead)


    const isAdminRead = getUserComments?.filter(i =>
        i.role === 'admin' ||
        i.role === 'trusted' ||
        i.role === 'newbie' ||
        i.role === 'announcement' ||
        i.role === 'notification' ||
        i.role === 'five_weeks' ||
        i.role === 'six_weeks' ||
        i.role === 'seven_weeks' ||
        i.role === 'refer_a_friend' ||
        i.role === 'copied' ||
        i.role === 'max_rating' ||
        i.role === 'dont_max_rating' ||
        i.role === 'etf-completed' ||
        i.role === 'completed' ||
        i.role === 'images' ||
        i.role === 'images_admin' ||
        i.role === 'images_driver' ||
        i.role === 'images_client_driver' ||
        i.role === 'images_admin_driver'
    ).some(el => !el.isAdminRead)

    const isDriverRead = getUserComments?.filter(i =>
        i.role === 'draft' ||
        i.role === 'approved' ||
        i.role === 'in 20 min' ||
        i.role === 'in 10 min' ||
        i.role === 'in 5 min' ||
        i.role === 'here' ||
        i.role === 'etf' ||
        i.role === 'canceled' ||
        i.role === 'send_order_comments_user' ||
        i.role === 'driver_order' ||
        i.role === 'images' ||
        i.role === 'images_admin' ||
        i.role === 'images_driver' ||
        i.role === 'images_client_driver' ||
        i.role === 'images_admin_driver'
    ).some(el => !el.isRead)
    const isMoreThanOne = isOrders && isOrders?.length > 1 || false;
    const [toggle, setToggle] = useState<string>(isOrders?.length ? 'Customer' : 'Driver')
    const [text, setText] = useState<string>('')
    // console.log('isDriverRead:',isDriverRead)
    // console.log('isUserRead:',isUserRead)
    const orderIds = orders?.getAllOrders.map(order => order.id) || [];
    let order_id: string
    if (orderId) {
        order_id = orderId
    } else {
        if (orderIds) {
            order_id = orderIds[orderIds.length - 1]
        }
    }
    console.log("setDriver:", setDriver)
    const sendUserOrderComment = async () => {
        if (id && role && text.length) {
            setText('')
            await orderUserComments({
                variables: {
                    id,
                    comment: text,
                    role: 'send_order_comments_user',
                    image: '',
                    order_id,
                    isRead: true
                }
            }).then(data => {

            })
        } else {
            console.log(`can't create comment from order ${orderId}`)
        }
    }
    const sendCommentAdmin = async () => {
        if (id && role && text.length) {
            setText('')
            await createAdminComment({
                variables: {
                    phone: phone_user,
                    comment: text,
                    role: 'admin',
                    image: '',
                    isRead: false
                }
            }).then(data => {
            })
        } else {
            console.log(`can't create comment from order ${orderId}`)
        }
    }
    const sendCommentDriver = async () => {
        if (id && role && text.length) {
            setText('')
            await createAdminComment({
                variables: {
                    phone: phone_user,
                    comment: text,
                    role: 'driver_order',
                    image: '',
                    isRead: false
                }
            }).then(data => {
            })
        } else {
            console.log(`can't create comment from order ${orderId}`)
        }
    }
    const sendUserComment = async () => {
        if (id && role && text.length) {
            setText('')

            await userComments({
                variables: {
                    id,
                    comment: text,
                    role,
                    image: '',
                    isRead: true
                }
            }).then(data => {
            })
        } else {
            console.log("can't create user comment")
        }
    }
    return (
        <>
            <ChatWrapper>
                {isAdmin && toggle === 'Customer' &&
                    <a>
                    <div className="back__all-user" onClick={() => navigate('/?adminToggle=Customer', {
                        replace: true,
                        state: {phone_user: adminReadPhone, first_name: adminReadFirstName, id_user: adminReadId},
                    })}>
                        <img alt={'back'} src={BackTo}/>
                        <div>Back to all Users</div>
                    </div>
                </a>}
                {isAdmin && toggle === 'Driver' &&
                    <FlexLink>
                        {queryToggle !== "Customer" &&
                            <a>
                                <div className="back__all-user" onClick={() => navigate(`/?adminToggle=Driver&orderFromClient=${setDriver}`, {
                                    replace: true,
                                    state: {phone_user: adminReadPhone, first_name: adminReadFirstName, id_user: adminReadId},
                                })}>
                                    <img alt={'back'} src={BackTo}/>
                                    <div>Back to the Orders</div>
                                </div>
                            </a>
                        }

                        <a>
                            <div className="back__all-user" onClick={() => navigate('/?adminToggle=Driver', {
                                replace: true,
                                state: {phone_user: adminReadPhone, first_name: adminReadFirstName, id_user: adminReadId},
                            })}>
                                <img alt={'back'} src={Undo}/>
                                <div>To all Orders</div>
                            </div>
                        </a>
                    </FlexLink>
                }
                {isDriverActive &&
                    <FlexLink>
                        <a>
                            <div className="back__all-user" onClick={() => navigate(`/?adminToggle=Driver&orderFromClient=${setDriver}`, {
                                replace: true,
                                state: {phone_user: adminReadPhone, first_name: adminReadFirstName, id_user: adminReadId},
                            })}>
                                <img alt={'back'} src={BackTo}/>
                                <div>Back to the Orders</div>
                            </div>
                        </a>
                        <a>
                            <div className="back__all-user" onClick={() => navigate('/', {
                                replace: true,
                                state: {phone_user: adminReadPhone, first_name: adminReadFirstName, id_user: adminReadId},
                            })}>
                                <img alt={'back'} src={Undo}/>
                                <div>To all Orders</div>
                            </div>
                        </a>
                    </FlexLink>
                }
                {isAdmin &&
                    <>
                        <Header
                            adminReadPhone={adminReadPhone}
                            adminReadFirstName={adminReadFirstName}
                            isAdminRead={isAdminRead}
                            toggle={toggle}
                            setToggle={setToggle}
                            noOrders={!isOrders?.length}
                            isMoreThanOne={isMoreThanOne}
                            isUserRead={isUserRead}
                            isDriverRead={isDriverRead}
                            reloadUser={reloadUser}
                            reloadDriver={reloadDriver}
                            id={orderId}
                            phone={phone}
                            isAdmin={isAdmin}
                            isDriverActive={isDriverActive}
                        />
                        {phone && getDriverComments && toggle === 'Driver' &&
                            <Dialogues
                                getComments={getDriverComments}
                                toggle={toggle}
                                isAdmin={isAdmin}
                                isDriverActive={isDriverActive}
                                adminReadPhone={adminReadPhone}
                                adminReadFirstName={adminReadFirstName}
                                isMoreThanOne={isMoreThanOne}
                                isCustomerActive={isCustomerActive}

                            />}
                        {phone && getUserComments && toggle === 'Customer' &&
                            <Dialogues
                                getComments={getUserComments}
                                toggle={toggle}
                                isAdmin={isAdmin}
                                isDriverActive={isDriverActive}
                                adminReadPhone={adminReadPhone}
                                adminReadFirstName={adminReadFirstName}
                                isMoreThanOne={isMoreThanOne}
                                isCustomerActive={isCustomerActive}

                            />}
                        <ChatInput
                            text={text}
                            sendComment={toggle === 'Customer' ? sendUserComment : sendUserOrderComment}
                            setText={setText}
                            isCustomer={toggle}
                            isAdmin={isAdmin}
                            isDriverActive={isDriverActive}
                            sendCommentAdmin={sendCommentAdmin}
                            sendCommentDriver={sendCommentDriver}
                            userId={adminReadId}
                            isMoreThanOne={isMoreThanOne}
                            isCustomerActive={isCustomerActive}
                        />
                    </>
                }
                {isDriverActive &&
                    <>
                        <Header
                            adminReadPhone={adminReadPhone}
                            adminReadFirstName={adminReadFirstName}
                            isAdminRead={isAdminRead}
                            toggle={toggle}
                            setToggle={setToggle}
                            noOrders={!isOrders?.length}
                            isMoreThanOne={isMoreThanOne}
                            isUserRead={isUserRead}
                            isDriverRead={isDriverRead}
                            reloadUser={reloadUser}
                            reloadDriver={reloadDriver}
                            id={orderId}
                            phone={phone}
                            isAdmin={isAdmin}
                            isDriverActive={isDriverActive}
                        />
                        {phone && getDriverComments && toggle === 'Driver' &&
                            <Dialogues
                                isCustomerActive={isCustomerActive}
                                getComments={getDriverComments}
                                toggle={toggle}
                                isAdmin={isAdmin}
                                isDriverActive={isDriverActive}
                                adminReadPhone={adminReadPhone}
                                adminReadFirstName={adminReadFirstName}
                                isMoreThanOne={isMoreThanOne}
                            />}
                        {phone && getUserComments && toggle === 'Customer' &&
                            <Dialogues
                                isCustomerActive={isCustomerActive}
                                getComments={getUserComments}
                                toggle={toggle}
                                isAdmin={isAdmin}
                                adminReadPhone={adminReadPhone}
                                adminReadFirstName={adminReadFirstName}
                                isMoreThanOne={isMoreThanOne}
                                isDriverActive={isDriverActive}
                            />}
                        <ChatInput
                            text={text}
                            sendComment={toggle === 'Customer' ? sendUserComment : sendUserOrderComment}
                            setText={setText}
                            isCustomer={toggle}
                            isAdmin={isAdmin}
                            isDriverActive={isDriverActive}
                            sendCommentAdmin={sendCommentAdmin}
                            sendCommentDriver={sendCommentDriver}
                            userId={adminReadId}
                            isMoreThanOne={isMoreThanOne}
                            isCustomerActive={isCustomerActive}
                        />
                    </>
                }
                {isCustomerActive &&
                    <>
                        <Header
                            adminReadPhone={adminReadPhone}
                            adminReadFirstName={adminReadFirstName}
                            toggle={toggle}
                            isAdminRead={isAdminRead}
                            setToggle={setToggle}
                            noOrders={!isOrders?.length}
                            isMoreThanOne={isMoreThanOne}
                            isUserRead={isUserRead}
                            isDriverRead={isDriverRead}
                            reloadUser={reloadUser}
                            reloadDriver={reloadDriver}
                            id={orderId}
                            phone={phone}
                            isAdmin={isAdmin}
                        />
                        {phone && isOrders?.length > 0 && getDriverComments && toggle === 'Driver' &&
                            <Dialogues
                                isCustomerActive={isCustomerActive}
                                getComments={getDriverComments}
                                       toggle={toggle}
                                       isAdmin={isAdmin}
                                       adminReadPhone={adminReadPhone}
                                       adminReadFirstName={adminReadFirstName}
                                       isMoreThanOne={isMoreThanOne}/>}
                        {phone && getUserComments && toggle === 'Customer' &&
                            <Dialogues
                                isCustomerActive={isCustomerActive}
                                getComments={getDriverComments}
                                       toggle={toggle}
                                       isAdmin={isAdmin}
                                       adminReadPhone={adminReadPhone}
                                       adminReadFirstName={adminReadFirstName}
                                       isMoreThanOne={isMoreThanOne}/>}
                        <ChatInput
                            isAdmin={isAdmin}
                            isDriverActive={isDriverActive}
                            isCustomerActive={isCustomerActive}
                            text={text}
                            sendComment={toggle === 'Customer' ? sendUserComment : sendUserOrderComment}
                            setText={setText}
                            isCustomer={toggle}
                            sendCommentAdmin={sendCommentAdmin}
                            sendCommentDriver={sendCommentDriver}
                            userId={id}
                        />
                    </>
                }


            </ChatWrapper>

            <DeskTopNotification isAdmin={isAdmin} isDriverActive={isDriverActive} toggle={toggle} isMoreThanOne={isMoreThanOne}/>
        </>
    )
}

export default Chat

interface PropsType {
    id?: number
    role?: string
    orderId?: string
    isAdmin?: boolean
    isDriverActive?: boolean
}
