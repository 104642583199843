import {HeaderToggleWrapper, HeaderWrapper, Toggle} from './styled'
import React, { useEffect } from 'react'
import { SET_USER_COMMENT_READ, SET_ADMIN_COMMENT_READ } from '../../../api'

import useElementSize from '../../../hooks/useElementSize'
import { useMutation } from '@apollo/client'

const Header = ({
                    isAdminRead,
                    isAdmin,
                    isDriverActive,
                    adminReadPhone,
                    adminReadFirstName,
                    toggle,
                    setToggle,
                    noOrders,
                    isMoreThanOne,
                    isUserRead,
                    isDriverRead,
                    reloadUser,
                    reloadDriver,
                    id,
                    phone}: PropsType) => {
    const [squareRef] = useElementSize()
    const [setUserCommentRead] = useMutation(SET_USER_COMMENT_READ)
    const [setAdminCommentRead] = useMutation(SET_ADMIN_COMMENT_READ)
    const loadDriver = async () => {
        if (phone && isDriverRead) {
            await setUserCommentRead({
                variables: {phone}
            })
            setTimeout(() => reloadDriver(), 2000)
        }
        setToggle('Driver')
    }
    const loadUser = async () => {
        if (phone && isUserRead) {
            await setUserCommentRead({
                variables: {phone}
            })
            setTimeout(() => reloadUser(), 2000)
        }
        setToggle('Customer')

    }
    const loadAdmin = async () => {
        if(toggle === 'Customer' && isAdmin && phone && !isAdminRead){
            await setAdminCommentRead({
                variables: {phone, isAdmin, isDriverActive, toggle}
            })
            setTimeout(() => reloadUser(), 2000)
        }
        if(toggle=== 'Driver' && isAdmin && phone && !isDriverRead){
            await setAdminCommentRead({
                variables: {phone, isAdmin, isDriverActive, toggle}
            })
            setTimeout(() => reloadUser(), 2000)
        }
    }
    const loadDriverActive = async () => {
        if (isDriverActive && phone && isDriverRead) {
            await setAdminCommentRead({
                variables: {phone, isAdmin, isDriverActive, toggle}
            })
            setTimeout(() => reloadUser(), 2000)
        }
        setToggle('Driver')
    }
    useEffect(() => {
        //========================Admin=======================//
        if(isAdmin) {
            loadAdmin()
        }
        if(isDriverActive) {
            setToggle('Driver')
            loadDriverActive()
        }
        //========================Admin=end===================//

        if(toggle === 'Driver') {

            if (id && isDriverRead) {
                loadDriver()
            }
        }
        if(toggle === 'Customer') {
            if(isAdmin) {
                // setToggle('Customer')
                loadAdmin()
            }
            if (id && isUserRead) {
                loadUser()
            }
        }
    },[isDriverRead, isUserRead, toggle, isAdminRead, isAdmin, isDriverActive])
    return (
        <HeaderWrapper ref={squareRef}>
            <HeaderToggleWrapper>
                    { !noOrders &&
                    <Toggle className={toggle === 'Driver' ? 'active' : ''}
                            onClick={loadDriver}
                            indicate={toggle !== 'Driver' && isDriverRead}
                            >
                        <span>Driver</span>
                    </Toggle>}
                    <Toggle
                        className={toggle === 'Customer' ? 'active' : ''}
                        noOrders={noOrders}
                        onClick={loadUser}
                        isCustomer={true}
                        indicate={toggle !== 'Customer' && isUserRead}
                    >
                        <span>Customer service</span>
                    </Toggle>
            </HeaderToggleWrapper>
        </HeaderWrapper>
    )
}

export default Header

interface PropsType {
    toggle: string
    setToggle: (toggle: string) => void
    noOrders: boolean
    isMoreThanOne: boolean
    isUserRead: boolean | undefined
    isDriverRead: boolean | undefined
    isAdminRead: boolean | undefined
    reloadDriver: () => void
    reloadUser: () => void
    id?: string
    phone: string | null
    isAdmin: boolean
    isDriverActive: boolean
    adminReadPhone: string
    adminReadFirstName: string
}
