const packConverter = (pack: number, index: number, group: string): number => {
    let result = 1;
    switch(pack) {
        case 1:
            if (index < 3) {
                result = result * index
            } else {
                result = pack * 4
            }
            break;
        case 2:

                if (index < 3) {
                    result = result * index * 2
                } else {
                    result = pack * 3
           }
            break;
        case 30:
            if (index < 3) {
                result = result * index
            }else {
                result = pack * 4
            }
            break;
        case 7:
        case 6:
        case 28:
            if (index < 3) {
                result = pack * index
            } else {
                result = pack * 4
            }
            break;

        case 14:
            if (index < 3) {
                result = pack * index
            } else {
                result = pack * 4
            }
            break;

    }

    return result
}

export default packConverter
