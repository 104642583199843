import {Date, DialogueScroll, DialogueWrapper} from './styled';
import React, {useEffect, useState} from 'react';

import {CommentType} from '../../../types';
import Dialogue from './dialugue';
import {animateScroll} from "react-scroll";
import moment from 'moment'
import {v4 as uuidv4} from 'uuid';

const Dialogues = ({getComments, isCustomerActive, toggle, isDriverActive, isAdmin, adminReadFirstName, adminReadPhone, isMoreThanOne}: PropsType) => {
    const chatContainer = React.createRef<HTMLInputElement>();
    const messageScroll = React.createRef<HTMLInputElement>();
    const [refer, setRefer] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)

    const scrollToBottom = () => {
        animateScroll.scrollToBottom({
            containerId: "message__scroll",
            offset: 100
        });
    }
    const ordersComments = getComments.filter(i =>
        i.role === 'draft' ||
        i.role === 'approved' ||
        i.role === 'in 20 min' ||
        i.role === 'in 10 min' ||
        i.role === 'in 5 min' ||
        i.role === 'here' ||
        i.role === 'etf' ||
        i.role === 'canceled' ||
        i.role === 'send_order_comments_user' ||
        i.role === 'driver_order' ||
        i.role === 'images_driver' ||
        i.role === 'images_client_driver' ||
        i.role === 'images_admin_driver'
    )
    const userComments = getComments.filter(i =>
        i.role === 'admin' ||
        i.role === 'trusted' ||
        i.role === 'newbie' ||
        i.role === 'announcement' ||
        i.role === 'notification' ||
        i.role === 'five_weeks' ||
        i.role === 'six_weeks' ||
        i.role === 'seven_weeks' ||
        i.role === 'five_weeks' ||
        i.role === 'six_weeks' ||
        i.role === 'seven_weeks' ||
        i.role === 'refer_a_friend' ||
        i.role === 'copied' ||
        i.role === 'max_rating' ||
        i.role === 'dont_max_rating' ||
        i.role === 'etf-completed' ||
        i.role === 'completed' ||
        i.role === 'images' ||
        i.role === 'images_admin'
    )
    const dateCustomer = Array.from(new Set(userComments.map(item => moment(item.created).format('D MMMM')))).reverse()
    const dateOrders = Array.from(new Set(ordersComments.map(item => moment(item.created).format('D MMMM')))).reverse()
    useEffect(() => {
        scrollToBottom()
    }, [scrollToBottom])

    const onScroll = () => {
        if (messageScroll.current) {
            const {scrollTop, scrollHeight, clientHeight} = messageScroll.current;
            if (scrollTop + clientHeight + 2 >= scrollHeight) {
            }
        }
    }
    return (
        <DialogueScroll ref={messageScroll} onScroll={onScroll} id="message__scroll">
            <DialogueWrapper ref={chatContainer}>
                {isAdmin && toggle === 'Customer' &&
                    <div className={toggle === 'Customer' ? 'wrapper__p active ' : 'wrapper__p hide'}>
                        <p className={toggle === 'Customer' ? 'dialogue__p active ' : 'dialogue__p hide'}>
                            You are chatting with {adminReadFirstName},<br/>
                            {adminReadPhone}
                            <br/>
                            <br/>
                            <a className="call__user-link" href={`tel:${adminReadPhone}`}>Call this user</a>
                        </p>
                    </div>
                    }
                {isAdmin && toggle === 'Driver' &&
                    <div className={toggle === 'Driver' ? 'wrapper__p active ' : 'wrapper__p hide'}>
                        <p className={toggle === 'Driver' ? 'dialogue__p active ' : 'dialogue__p hide'}>
                            You are chatting with {adminReadFirstName},<br/>
                            {adminReadPhone}
                            <br/>
                            <br/>
                            <a className="call__user-link" href={`tel:${adminReadPhone}`}>Call this user</a>
                        </p>
                    </div>
                }
                {isDriverActive &&
                    <div className={toggle === 'Driver' ? 'wrapper__p active ' : 'wrapper__p hide'}>
                        <p className={toggle === 'Driver' ? 'dialogue__p active ' : 'dialogue__p hide'}>
                            You are chatting with {adminReadFirstName},<br/>
                            {adminReadPhone}
                            <br/>
                            <br/>
                            <a className="call__user-link" href={`tel:${adminReadPhone}`}>Call this user</a>
                        </p>
                    </div>
                }
                    {!isAdmin &&
                    <div className={toggle === 'Customer' ? 'active wrapper__p' : 'wrapper__p hide'}>
                        <p className={toggle === 'Customer' ? 'active dialogue__p' : 'dialogue__p hide'}>
                            Here you can ask all your questions! We appreciate feedback
                            and suggestions so be honest, say whatever you want :)
                            <br/>
                            <br/>
                            We also send info here about sales, news, events, etc
                        </p>
                    </div>

                }
                {isCustomerActive &&
                    <div className={toggle === 'Driver' ? 'wrapper__p active ' : 'wrapper__p hide'} >
                        <p className={toggle === 'Driver' ? 'dialogue__p active ' : 'dialogue__p hide'}>
                            Here is the chat with your driver. Send any details
                            which may help your delivery, need change, etc
                            <br/>
                            <br/>
                            {!isMoreThanOne ?
                                <>Questions about the website? Switch to <span>Customer Service</span></>
                                :
                                <>
                                    You’re chatting with the driver of the nearest order, scheduled on 28nov.
                                    <br/>
                                    <br/>
                                    If you want to discuss another order, please switch to <span>Customer Service</span>
                                </>}
                        </p>
                    </div>
                }
                {toggle === 'Customer'
                    ?
                    dateCustomer?.map(date => {
                        if (toggle === 'Customer') {
                            return <React.Fragment key={uuidv4()}>
                                <Date key={uuidv4()}>{date}</Date>
                                {userComments?.map(message => {
                                    if (date === moment(message.created).format('D MMMM')) {
                                        return (
                                            <>
                                                <Dialogue
                                                    key={uuidv4()}
                                                    text={message.text}
                                                    created={message.created}
                                                    role={message.role}
                                                    isDriver={message.isDriver}
                                                    refer_code={message.refer_code}
                                                    user_id={message.user_id}
                                                    message_id={message.message_id}
                                                    image={message.image}
                                                    setRefer={setRefer}
                                                    refer={refer}
                                                    toggle={toggle}
                                                    rating={message.rating}
                                                    disabled={disabled}
                                                    setDisabled={setDisabled}
                                                    isAdmin={isAdmin}
                                                    isDriverActive={isDriverActive}
                                                    isCustomerActive={isCustomerActive}
                                                />
                                            </>
                                        )
                                    }
                                })}
                            </React.Fragment>
                        }
                    }).reverse()
                    :
                    dateOrders?.map(date => {
                        if (toggle === 'Driver') {
                            return <React.Fragment key={uuidv4()}>
                                <Date key={uuidv4()}>{date}</Date>
                                {ordersComments?.map(message => {
                                    if (date === moment(message.created).format('D MMMM')) {
                                        return (
                                            <>
                                                <Dialogue
                                                    key={uuidv4()}
                                                    text={message.text}
                                                    created={message.created}
                                                    role={message.role}
                                                    isDriver={message.isDriver}
                                                    refer_code={message.refer_code}
                                                    user_id={message.user_id}
                                                    message_id={message.message_id}
                                                    image={message.image}
                                                    setRefer={setRefer}
                                                    refer={refer}
                                                    toggle={toggle}
                                                    rating={message.rating}
                                                    disabled={disabled}
                                                    setDisabled={setDisabled}
                                                    isAdmin={isAdmin}
                                                    isDriverActive={isDriverActive}
                                                    isCustomerActive={isCustomerActive}

                                                />
                                            </>
                                        )
                                    }
                                })}
                            </React.Fragment>
                        }
                    }).reverse()
                }
            </DialogueWrapper>
        </DialogueScroll>
    )
}

export default Dialogues;

interface PropsType {
    getComments: CommentType[]
    toggle: string
    isAdmin: boolean
    isDriverActive: boolean,
    adminReadPhone: string
    adminReadFirstName: string
    isMoreThanOne: boolean
    isCustomerActive: boolean

}

