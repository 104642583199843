import styled from 'styled-components'

export const Wrapper = styled.div`

    //@media screen and (min-width: 1280px){
    //    flex-grow: 1;
    //}
`
export const SearchInput = styled.input`
  width: 100%;
  background: #F7F7F7;
  border: 2px solid rgba(217, 217, 217, 0.3);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 17px 35px;
  margin-top: 20px;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  color: #616A5C;
  opacity: 0.7;
  height: 53px;
  width: 209px;
  
  @media screen and (min-width: 743px) {
    width: 209px;
    height: 60px;
    font-size: 18px;
    padding: 19px 24px;
    margin: 20px auto 20px auto;
    border-radius: 13.8214px;
    
    &.instruction {
      height: 96px;
      padding: 19px 24px 54px;
    }
  }
`
export const AdminWrapper = styled.div`
  padding: 30px 12px;
  max-width: 700px;
  border-radius: 12px;
  .wrapper-admin-link {
    display: flex;
    justify-content: space-between;
  }
  .back__all-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-bottom: 30px;
    img{
      margin-right: 4px;
      width: 24px;
      height: 22px;
      
    }
    div{
      color: #49812F;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
    }
  }
  .flex-input {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    //width: 209px;
    .img-search-close {
      width: 19px;
      position: absolute;
      margin: 17px 0 0 175px;
      cursor: pointer;
      z-index: 100;
    }
    .img-search {
      z-index: 100;
      width: 19px;
      position: absolute;
      margin: 17px 0 0 12px;
    }
  }
  .search__all-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
  .text-search {
    width: 115px;
    height: 7px;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #616A5C;
    opacity: 0.8;
    margin-left: 12px;
  }
    @media screen and (min-width: 1280px){
        position: relative;
        background: none;
        width: 484px;
        padding: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

  
`

export const TitleAdmin = styled.h3<{color?: string}>`
  height: 31px;
  font-weight: 600;
  font-size: 26px;
  width: 100%;
  text-align: left;
  line-height: 120%;
  color: #445A3B;
  margin-bottom: 20px;
  opacity: 0.9;
`

export const AdminMessage = styled.div`
  margin: 0 0 16px 0;
  background: #FFFFFF;
    border-radius: 12px;
    padding: 14px 12px 14px 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
  cursor: pointer;
  .wrapper-message {
    display: flex;
    justify-content: space-between;
  }
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 7px;

        p {
            font-weight: bold;
            font-size: 8px;
            line-height: 130%;
          
            text-transform: uppercase;
            color: #616A5C;
            opacity: 0.6;
        }
    }
`

export const TimeAdmin = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 61, 0, 0.8);
    opacity: 0.8;
    align-self: flex-end;
`
export const TimeAdminRead = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #616A5C;
    opacity: 0.8;
    align-self: flex-end;
`

export const Date = styled.p`
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #616A5C;
    opacity: 0.8;
    margin-top: 18px;
    margin-bottom: 12px;
    @media screen and (min-width: 743px){
        font-size: 16px;
    }
`

export const Text = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;
  margin-bottom: 8px;


`
export const ContentTablet = styled.ul`
  width: 430px;
  //height: 368px;
  left: 142px;
  top: 165px;
  background: #FFFFFF;
  box-shadow: 0px 2.50877px 5.01754px rgba(73, 129, 47, 0.1);
  border-radius: 30.1053px;


`
export const LastMessage = styled.p`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;
`
export const LastLogin = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;
`

export const RoutesText = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-align: center;
  color: #616A5C;
  opacity: 0.8;
  margin: 18px 0 0 0;
`

export const Content = styled.ul`
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(73, 129, 47, 0.1);
  border-radius: 0px 0px 24px 24px;
  width: 312px;
  left: 24px;
  top: 244px;
  margin: 0 auto;

  @media screen and (min-width: 743px) {
    width: 312px;
    height: 372px;
    padding-top: 17px;
  }
`
export const MainContainerWindow = styled.div<{padding?: string, maxWidth?: string, translateY?: string}>`
  padding-top: ${({padding}) => padding};
  margin: 0 auto;
  width: 100%;
  max-width: ${({maxWidth}) => maxWidth || '336px'};

  height: calc(100vh + 500px); /* Fallback for browsers that do not support Custom Properties */
  min-height: calc((var(--vh, 1vh) * 100) + 500px);
  display: flex;
  flex-direction: column;

  &.reffer__container {
    padding-top: 72px;
    
  }

  .desktop__header, .desktop__btn-wrapper {
        display: none;
  }

  @media screen and (min-width: 743px) {
    transform: ${({translateY}) => translateY || 'translateY(-22px)'};
    
     //min-height: calc(100vh - 124px); /* Fallback for browsers that do not support Custom Properties */
    min-height: calc((var(--vh, 1vh) * 100) - 124px);
    /* max-width: 343px; */
    max-width: 430px;
    z-index: 103;
    padding-top: 0;

    .mobile__header, .reffer__buttons {
      display: none;
    }
    
    &.reffer__container {
      padding-top: 0;
      display: flex;
      align-items: self-start;
    }

    .reffer__buttons {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    &.change__address {
      max-width: 493px;
      margin-top: 50px;
    }

    .desktop__btn-wrapper {
      display: flex;
      margin-bottom: 0;
    }
    .desktop__header {
      display: block
    }
    
    .friend__content {
       height: 435px;
    }
  }

  @media screen and (min-width: 1280px) {
    transform: ${({translateY}) => translateY || 'translateY(-54px)'};
      &.change__address {
        max-width: 351px;
        min-height: 100%;
        margin-top: 0;
      }
  }
`
export const TitleBlock = styled.div`
  h3 {
    font-weight: 600;
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    color: #445A3B;
    opacity: 0.9;
  }

  @media screen and (min-width: 743px) {
    h3 {
      font-size: 24px;
      color: #FFFFFF;
    }
    margin: 0 auto;
    padding-top: 12px;
    width: 370px;
    height: 54px;
    background: linear-gradient(113.41deg, rgba(91, 160, 59, 0.6) 21.91%, rgba(36, 115, 0, 0.6) 88.74%);
    border-radius: 36px 36px 0px 0px;
  }  
`

export const ContentHeader = styled.div`
  background: #FFFFFF;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 32px 32px 0 0;
  width: 350px;
  height: 42px;
  left: 24px;
  top: 202px;
  margin: 0 auto;
  opacity: 0.6;

  @media screen and (min-width: 743px) {
    width: 312px;
    height: 42px;
    padding-top: 17px;
  }
`
export const ContentBody = styled.div`
  background: #FFFFFF;
  display: flex;
  justify-content: space-around;
  gap: 35px;
  align-items: center;
  border-radius: 0px 0px 0 0;
  width: 350px;
  height: 42px;
  left: 24px;
  top: 202px;
  margin: 0 auto;
  opacity: 0.6;

  @media screen and (min-width: 743px) {
    width: 312px;
    height: 42px;
    padding-top: 17px;
  }
`

export const ContentHeaderDay = styled.div`
  width: 15px;
  height: 19px;
  padding-left: 0;
  left: 38px;
  top: 213px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #616A5C;
  opacity: 0.8;
`
export const ContentHeaderMon = styled.div`
  width: 29px;
  padding-left: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #616A5C;
  opacity: 0.8;
`
export const ContentHeaderOne = styled.div`
  width: 5px;
  padding-left: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #616A5C;
  opacity: 0.8;
`
export const ContentHeaderHigh = styled.div`
  width: 29px;
  height: 40px;
  padding-left: 0;
  left: 38px;
  top: 213px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #616A5C;
  opacity: 0.8;
`

export const ContentHeaderTimeslotTablet = styled.div`
  width: 56px;
  height: 17px;
  left: 267px;
  top: 255px;
  padding-left: 10px;
  margin-right: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #616A5C;
  opacity: 0.8;
`

export const ContentHeaderCutoffTablet = styled.div`
  width: 44px;
  height: 17px;
  left: 350px;
  top: 255px;
  margin-right: 30px;
  padding-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #616A5C;
  opacity: 0.8;
`
export const Block = styled.div`
  margin-top: 20px;

  @media screen and (min-width: 743px) {
    margin-top: 0;
    z-index: 103;
  }
`
export const BlockAut = styled.div`
  display: flex;
  justify-content: space-between;
  

`

export const TextAround = styled.p`
  width: 310px;
  height: 42px;
  left: 25px;
  top: 139px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #616A5C;
  opacity: 0.8;

`
export const Route = styled.li`
  height: 64px;
  display: flex;
  align-self: center;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #37751a33;
  }

  @media screen and (min-width: 743px) {
    p {
      line-height: 160%;
    }
  }
`
export const RouteSpanDay = styled.div`
  width: 106px;
  height: 62px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #616A5C;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RouteTwoTimeslots = styled.li`
  height: 124px;
  display: flex;
  justify-content: space-around;
  align-self: center;


  &:not(:last-child) {
    border-bottom: 1px solid #37751a33;
  }

  @media screen and (min-width: 743px) {
    p {
      line-height: 160%;
    }
  }
`

export const RouteSpanDayTwoTimeslots = styled.div`
  width: 106px;
  height: 124px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #616A5C;
  display: flex;
  align-items: center;
  justify-content: center;
  
`

export const RouteSpanTimeslotAndCutoffBlockTwoColumn = styled.div`
  width: 206px;
  height: 124px;
  display: flex;
  flex-direction: column;
  
`
export const RouteSpanCutoffTwo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 62px;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #616A5C;
  opacity: 0.6;
  margin-right: 7px;
`
export const RouteSpanTimeslotTwoTablet = styled.div`
  width: 94px;
  height: 18px;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  color: #616A5C;
  opacity: 0.9;
  flex: none;
  order: 0;
  flex-grow: 0

`
