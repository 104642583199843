import {ProductsType} from '../../types'

const updateProductCount = (product: ProductsType, amount: number, plus = false, minus = false) => {
    if (minus) {
        if (amount >= product.count) {
            return 'you cannot delete as much or more than you have'
        } else {
            return {...product, count: product.count - amount}
        }
    }
    if (plus) {
        if (product.count + amount > product.available) {
            return `available product - ${product.available - product.count > 0 ? product.available - product.count : 0}`
        } else {
            return {...product, count: product.count + amount}
        }
    }
}

export default updateProductCount;
