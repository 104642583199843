import styled from 'styled-components'


export const ToggleAdmin  = styled.button<{isCustomer?: boolean, noOrders?: boolean, indicate?: boolean}>`
    background: transparent;
    opacity: 0.8;
    border-radius: 8px;
    width: ${({isCustomer}) => isCustomer ? '49%' : '47%'};
    transition: 0.4s;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.active {
        background: #49812F;
    }

    ${({noOrders}) => noOrders && {
    width: '100%',
    background: '#49812F'
}};
    
    span {
        position: relative;
        font-weight: bold;
        font-size: 17px;
        line-height: 100%;
        color: #FFFFFF;
        
        ${({indicate}) => indicate && {

}}
        &:before {
            content: '';
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            right: -13px;
            top: -3px;
            background: #FBFF28;
            opacity: ${({indicate}) => indicate ? '0.9': 0};
        }
    }

    @media screen and (min-width: 743px) and (max-width: 1279px){
        span {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 390px){
        span {
            font-size: 14px;
            &:before {
                right: -8px;
            }
        }
    }
`

export const FlexDriver = styled.div<{top?: string}>`
  margin-top: ${({top}) => top || '0'};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9px;
  width: 100%;
  height: 100%;
  padding-bottom: 4.5px;
  //margin-bottom: 10.5px;
  img {
    width: 24px;
    height: 24px;
  }
  //img.img__change{
  //  height: 30px;
  //}
  a {
    text-decoration: underline;
  }
  div {
    a {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #616A5C;
      opacity: 0.8;
    }
  }
`
export const TooltipPhoneContent = styled.div<{maxHeight?: boolean, isError?: boolean}>`
        //position: absolute;
        //top: -200px;
        //left: -155px;
        right: 0;
        //width: 342px;
        position: absolute;
        width: 154px;
        height: 83px;
        left: -20px;
        top: -95px;

        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(4px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 20px;
        //height: 169px;
        //background: rgba(0, 0, 0, 0.8);
        //backdrop-filter: blur(8px);
        //border-radius: 20px;
        //padding: 22px 18px;
        transition: 0.4s;
        transform: translateY(-100px);
        opacity: 0;
        z-index: -100;

        &:after {
                content: '';
                position: absolute;
                bottom: -15px;
                left: 25px;
                width: 0;
                height: 0;
                border-left: 14px solid transparent;
                border-right: 14px solid transparent;
                border-top: 15px solid rgba(0, 0, 0, 0.8);
                transition: 0.4s 0.1s;
                opacity: 1;
        }
  
  a {
    text-decoration: none;

  }

  .text, .below__text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 22.4px !important;
    color: #FFFFFF !important;
    opacity: 1 !important;
    //b {
    //    font-weight: 900;
    //}
    
    .below__text {
      margin-top: 45px;
    }
  }

  @media screen and (max-width: 743px) {  
          left: -17px;
    &:after {
      left: 30px;
    }
  }

  @media screen and (max-width: 414px) {
          left: -17px;
  .text {
    .below__text {
      margin-top: 20px;
    }
  }
    &:after {
            left: 30px;
    }
  }
`
export const TooltipPhoneWrapper  = styled.div`
  transition: 0.1s;
  position: relative;
        
  /* margin-top: 4px; */
  //height: 60px;
  
  .cart__info-image {
    z-index: 102;
  }
  .tooltip {
    transform: translateY(0);
    opacity: 1;
    z-index: 100;

    &:after {
      opacity: 1;
    }
  }
  

  
  @media screen and (min-width: 743px) {
    //height: 31px;
  }
`
export const CommentsOrder = styled.div`
  display: flex;
  //width: 40px;
  flex-direction: column;
  position: absolute;
  top: 17px;
  right: 10px;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 20px;
  }
  p {
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    color: #57AB30;
  }

`

export const AdminWrapper = styled.div`
  padding: 0 12px;
  max-width: 700px;
  border-radius: 12px;
  gap: 11px;
  .back__all-user{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-bottom: 19px;
    
    img{
      margin-right: 4px;
      width: 24px;
      height: 22px;
    }
    div{
      color: #49812F;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      cursor: pointer;
    }
  }
    @media screen and (min-width: 1280px){
        position: relative;
        background: none;
        width: 484px;
        padding: 0;
        //margin: 0 0 16px 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        //height: calc(100vh - 112px); /* Fallback for browsers that do not support Custom Properties */
        //height: calc((var(--vh, 1vh) * 100) - 112px);
    }

  
`
export const TitleAdmin = styled.h3<{color?: string}>`
  height: 31px;
  font-weight: 600;
  font-size: 26px;
  width: 100%;
  text-align: center;
  line-height: 120%;
  color: #445A3B;
  margin-bottom: 20px;
  opacity: 0.9;
`


export const AdminMessage = styled.div`
  margin: 16px 0 0 0;
  position: relative;
  background: #FFFFFF;
    border-radius: 12px;
    padding: 14px 12px 14px 12px;
    display: flex;
    flex-direction: column;
  //gap: 9px;
  width: 100%;
  cursor: pointer;

`

export const TimeAdmin = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 61, 0, 0.8);
    opacity: 0.8;
    align-self: flex-end;
`
export const TimeAdminRead = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #616A5C;
    opacity: 0.8;
    align-self: flex-end;
`

export const Date = styled.p`
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #616A5C;
    opacity: 0.8;
    margin-top: 18px;
    margin-bottom: 12px;
    @media screen and (min-width: 743px){
        font-size: 16px;
    }
`

export const Text = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;


`

export const Orders = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: #445A3B;
  padding-top: 24px;
  padding-left: 6px;
  opacity: 0.9
`
export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 6px;
`

export const SortBy = styled.div`
  width: 45%;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;
`

export const Show = styled.div`
  width: 55%;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;
`

export const ToggleSortBy = styled.div`
  display: flex;
  gap: 22px;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #37751A;
  opacity: 0.8;
  div {
    cursor: pointer;

    a {
      &.active__sort {
        text-decoration: underline;
      }
    }
  }
`
export const ToggleShow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  margin-right: 6px;
  line-height: 140%;
  color: #37751A;
  opacity: 0.8;
  div {
    cursor: pointer;
    a {
      &.active {
        text-decoration:  underline;
      }
    }
  }
`
export const LastMessage = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;
`
export const Plug = styled.div`
    width: 300px;
    margin: 100px auto 0 auto;
    text-align: center;

    h5 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    span {
        font-size: 18px;
    }

    a {
        color: blue;
    }

    @media screen and (min-width: 743px){
        width: 400px;
        h5 {
        font-size: 27px;
        span {
            font-size: 22px;
        }
    }
    }

    
`

export const ChatWrapper = styled.div`
    margin: 20px auto 0 auto;
    padding: 0 12px;
    max-width: 700px;

    @media screen and (min-width: 1280px){
        position: relative;
        background: none;
        width: 484px;
        padding: 0;
        margin: 0 auto 0 52px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        height: calc(100vh - 112px); /* Fallback for browsers that do not support Custom Properties */
        height: calc((var(--vh, 1vh) * 100) - 112px);
    }
  .back__all-user{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-bottom: 19px;
    cursor: pointer;
    img{
      margin-right: 4px;
    }
    div{
      color: #49812F;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
    }
  }
`
export const Title = styled.h3<{color?: string}>`
  text-align: center;
  font-size: 100px;
  line-height: 120%;
  color:  #37751A;

`

export const InfoText = styled.h5`
  text-align: center;
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  color: #37751A;

`
export const Notification = styled.div`
    width: 402px;
    /* height: 232px; */
    margin-top: 50px;
    background: #c4c4c433;
    box-shadow: inset 0px 1px 12px rgba(0, 0, 0, 0.15);
    border-radius: 12px 0 0 12px;
    padding: 25px 35px 28px 32px;
    position: absolute;
    top: 152px;
    right: 0;
    margin: 0;
    display: none;
    overflow: hidden;
    transition: 0.4s 1s;
    z-index: -1;

    p {

        font-size: 18px;
        line-height: 140%;
        color: #616A5C;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: -100;
        transform: translateY(-120%);
        transition: 0.4s;
        max-width: 516px;
        margin: 0 auto;

        span {
            font-weight: 700;
        }

        &.active {
            position: relative;
            transition: 0.5s 0.3s;
            opacity: 0.8;
            transform: translateY(0);
            z-index: 1;
        }
    }

    @media screen and (min-width: 1280px){
        
        display: block;
    }
`
