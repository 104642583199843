import {Button, Input, } from '../common';
import {LOGIN} from "../../api";
import React, {KeyboardEvent, useEffect, useState, } from 'react';
import {Text, Title, Wrapper, Prefix} from "./styled";
import {useMutation} from "@apollo/client";
import logo from '../../assets/logo.svg'
import {useNavigate} from "react-router";
import EnterCode from './enterCode';
import Loader from '../loader';
import {Navigate} from 'react-router-dom'
import NoWork from './noWork';
import ReferredFriend from './referredFriend';
import {local} from '../../utils'
import InvalidCode from "./invalidCode";
import WeDidnt from "./weDidnt";
import {detect} from "detect-browser";

const Auth = ({isAuth}: { isAuth: (auth: boolean) => void }) => {
    const user = local.getToken()
    const [phone, setPhone] = useState<string>('')
    const [route, setRoute] = useState('main')
    const [noNumber, setNoNumber] = useState(false)
    const [isReferred, setReferred] = useState(false)
    const [token, setToken] = useState<string>('')
    const [active, setActive] = useState<boolean>(false)
    const [safari, setSafari] = useState<boolean>(false)

    const navigate = useNavigate()
    const [intro, setNoIntro] = useState(false)
    const [login, {loading, data}] = useMutation(LOGIN)
    const [placeholder, setPlaceholder] = useState<string>('+1 XXX XXX XX XX')
    console.log('safari:', safari)
    // console.log('auth', user)
    // const oneString = 'We didn’t find an account'
    // const text = noNumber ?
    //     `Please use our bot instead. Click here https://t.me/crazyawesomerbot to access the bot`
    //     : "Enter your phone number to login"
    //
    // const title = noNumber ? "Sorry today web shop is unavailable!"
    //     : "Hey there!"
    const oneString = 'We didn’t find an account'
    const text = noNumber ?
        'We didn’t find an account \n attached to this number.'
        : "Enter your phone number to login"

    const title = noNumber ? "We couldn’t find you"
        : "Hey there!"
    const getUser = async () => {
        // console.log(phone.length);
        if (phone.length > 6) {
            let changePhone
            const phoneReplaceNumber = phone.replace(/[^0-9.]/g, '')
            if (phoneReplaceNumber.length === 11) {
                if (phoneReplaceNumber.split('')[0] === '7') {
                    changePhone = phoneReplaceNumber
                }
                if (phoneReplaceNumber.split('')[0] === '1') {
                    changePhone = phoneReplaceNumber
                }
            }
            if (phoneReplaceNumber.length === 10) {
                changePhone = `1${phoneReplaceNumber}`
            }
            await login({
                variables: {
                    phone: changePhone
                }
            }).then(({data}) => {
                // console.log(data);
                if (data?.login && !data?.login.new_profile) {
                    setRoute('code')
                    setToken(data?.login?.token)
                    setActive(false)

                } else {
                    // setRoute('wedidnt')
                    navigate(`/intro/${data?.login.id}`)

                }
            }).catch(err => {
                console.log('error');
                setNoNumber(true)
            })


        }
    }

    const handleKeypress = (e: KeyboardEvent) => {
        //it triggers by pressing the enter key
        if (e.key === "Enter" && e.code === "Enter") {
            getUser()
            setActive(false)
        }
    };

    if (user) return <Navigate to='/' replace/>
    if (loading) return <Loader/>
    const iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
    console.log('IOS:', iOS)
    return (
        <Wrapper>
            <div>
                {route === 'main' && <>
                    <Title>{title}</Title>
                    <Text>{text}</Text>
                        <Prefix margin={!iOS ?'20px 0 0px 88px' : '22px 0 -1px 88px'} className={active
                            ? 'prefix__active' : ''}>
                            +1
                        </Prefix>
                        <Input required type="tel" value={phone} pattern="/+[0-9]{3}-[0-9]{3}/"
                               placeholder={placeholder}
                               maxLength={11}
                               className={active ? 'prefix__input-active' : ''}
                               onChange={(e) => {
                                   setActive(true)
                                   setPhone(e.target.value.replace(/[a-zа-яё]/g, "").replace(/[+]/g, ""))
                                   if (e.target.value.replace(/[a-zа-яё]/g, "").replace(/[+]/g, "") === '') {
                                       setPlaceholder('')
                                   }
                               }}
                               onClick={() => {
                                   setActive(true)
                                   setPlaceholder('')
                               }
                               }
                               onKeyPress={handleKeypress}
                               autoFocus
                        />


                    {!noNumber &&
                        <Button margin={"18px 0 0 0"} value={'Notification'} onClick={getUser}>Continue</Button>}
                    {noNumber &&
                        <Button margin={"18px 0 0 0"} value={'Notification'} onClick={getUser}>Try again</Button>}
                </>}
                {route === 'code' && <EnterCode
                    setRoute={setRoute} userToken={token} setIsAuth={isAuth} phone={phone.replace(/[^0-9.]/g, '')}
                    profileId={data?.login?.last_profile}/>}
                {isReferred && <ReferredFriend/>}
                {route === 'nowork' && <NoWork setRoute={setRoute}/>}
                {route === 'wedidnt' && <WeDidnt setRoute={setRoute}/>}

                {route === 'invalid' && <InvalidCode setRoute={setRoute} setNoNumber={setNoNumber}/>}
            </div>
        </Wrapper>
    );
};

export default Auth;