import {WrapperRating} from "./styled";
import React from "react";
import Star from "../../../assets/Star.svg"
import StarActive from "../../../assets/StarActive.svg"
import {Image} from "../../common";
const RatingUserUse = ({
                           rating,
                           user_id,

                       }: PropsType) => {
    const stars = Array.from({length: 5}, () => '🟊')
    return (
        <WrapperRating>
            {stars.map((s, index) => {
                let style = Star;
                if (index < rating) {
                    style= StarActive
                }
                return (
                    <button
                          key={index}
                          >
                        <Image className='cart__info-image' src={style}/>
                    </button>
                )
            })}

        </WrapperRating>
        )


}
export default RatingUserUse
interface PropsType {
    user_id: number
    rating: number
    setDisabled: (disabled: boolean) => void
}
