import styled from 'styled-components'

export const Wrapper = styled.div`

    //@media screen and (min-width: 1280px){
    //    flex-grow: 1;
    //}
`
export const SearchInput = styled.input`
  width: 100%;
  background: #F7F7F7;
  border: 2px solid rgba(217, 217, 217, 0.3);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 17px 35px;
  margin-top: 20px;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  color: #616A5C;
  opacity: 0.7;
  height: 53px;
  width: 209px;
  
  @media screen and (min-width: 743px) {
    width: 209px;
    height: 60px;
    font-size: 18px;
    padding: 19px 24px;
    margin: 20px auto 20px auto;
    border-radius: 13.8214px;
    
    &.instruction {
      height: 96px;
      padding: 19px 24px 54px;
    }
  }
`
export const AdminWrapper = styled.div`
  padding: 30px 12px;
  max-width: 700px;
  border-radius: 12px;
  .wrapper-admin-link {
    display: flex;
    justify-content: space-between;
  }
  .back__all-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-bottom: 30px;
    img{
      margin-right: 4px;
      width: 24px;
      height: 22px;
      
    }
    div{
      color: #49812F;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
    }
  }
  .flex-input {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    //width: 209px;
    .img-search-close {
      width: 19px;
      position: absolute;
      margin: 17px 0 0 175px;
      cursor: pointer;
      z-index: 100;
    }
    .img-search {
      z-index: 100;
      width: 19px;
      position: absolute;
      margin: 17px 0 0 12px;
    }
  }
  .search__all-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    //img{
    //  margin-right: 4px;
    //  width: 24px;
    //  height: 22px;
    //
    //}
    
    //div{
    //  color: #49812F;
    //  font-weight: 400;
    //  font-size: 18px;
    //  line-height: 120%;
    //}
  }
  .text-search {
    width: 115px;
    height: 7px;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #616A5C;
    opacity: 0.8;
    margin-left: 12px;
  }
    @media screen and (min-width: 1280px){
        position: relative;
        background: none;
        width: 484px;
        padding: 0;
        //margin: 0 0 16px 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        //height: calc(100vh - 112px); /* Fallback for browsers that do not support Custom Properties */
        //height: calc((var(--vh, 1vh) * 100) - 112px);
    }

  
`

export const TitleAdmin = styled.h3<{color?: string}>`
  height: 31px;
  font-weight: 600;
  font-size: 26px;
  width: 100%;
  text-align: left;
  line-height: 120%;
  color: #445A3B;
  margin-bottom: 20px;
  opacity: 0.9;
`

export const AdminMessage = styled.div`
  margin: 0 0 16px 0;
  background: #FFFFFF;
    border-radius: 12px;
    padding: 14px 12px 14px 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
  cursor: pointer;
  .wrapper-message {
    display: flex;
    justify-content: space-between;
  }
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 7px;

        p {
            font-weight: bold;
            font-size: 8px;
            line-height: 130%;
          
            text-transform: uppercase;
            color: #616A5C;
            opacity: 0.6;
        }
    }
`

export const TimeAdmin = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 61, 0, 0.8);
    opacity: 0.8;
    align-self: flex-end;
`
export const TimeAdminRead = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #616A5C;
    opacity: 0.8;
    align-self: flex-end;
`

export const Date = styled.p`
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #616A5C;
    opacity: 0.8;
    margin-top: 18px;
    margin-bottom: 12px;
    @media screen and (min-width: 743px){
        font-size: 16px;
    }
`

export const Text = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;
  margin-bottom: 8px;


`
export const LastMessage = styled.p`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;
`
export const LastLogin = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #616A5C;
  opacity: 0.8;
`
