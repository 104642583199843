import {BackButton, ButtonFlex, Image, MainButton, MainContainerAddress, SelectButton} from "../../common";
import { Content, DefaultButton, Header, Input, RefferFrienText, TitleBlock } from './styled';
import React, {KeyboardEvent, useState} from 'react';

import { AnimatePage } from '../..';
import {Link, useNavigate} from 'react-router-dom';
import arrowLeft from "../../../assets/icons/arrowLeftWhite.svg";
import {useToasts} from "react-toast-notifications";
import {useMutation} from "@apollo/client";
import {useWindowDimension} from "../../../hooks/useWindowDimension";
import {CREATE_BROAD_CAST} from "../../../api";

const BroadCast = ({phone, count, isAdmin}: PropsType) => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [createBroadCast] = useMutation(CREATE_BROAD_CAST)
    const [broadcast, setBroadCast] = useState<string>('')
    const [window, setWindow] = useState<boolean>(false)
    const disable = broadcast.length === 0
    const [google, setGoogle] = useState<boolean>(true)
    const broadCastAllTrustedUsers = async () => {
        if (broadcast.length) {

            await createBroadCast({
                variables: {
                    phone,
                    comment: broadcast,
                    role: 'admin',
                    image: '',
                    isRead: false,
                    google: google
                }
            }).then(data => {
                setBroadCast('')
                addToast(`Broadcast was sent!`, {appearance: 'success', autoDismiss: true});
                navigate('/')
            })
        } else {
        }

    }

    const handleKeypress = (e: KeyboardEvent) => {
        if (e.key === "Enter" && e.code === "Enter") {
            setWindow(true)
        }
    };
    function messagePreview() {
        setWindow(true)
    }

    return (
        <AnimatePage>
            <MainContainerAddress className='reffer__container'>
                <Header className='mobile__header'>Send broadcast</Header>
                <TitleBlock className='desktop__header'><h3>{window ? 'Message preview' : 'Send broadcast'}</h3></TitleBlock>
                <Content className='friend__content'>
                    {window
                    ?
                        <RefferFrienText>
                            {broadcast}
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            Ready to send it?<br/>
                            It will be sent to {count} users
                        </RefferFrienText>
                    :
                        <RefferFrienText>
                            Write the message you want to send.<br/>
                            Active users will receive this message<br/>
                            in “Customer Service” tab.
                            <br/>
                            <br/>
                            <span>{count} users</span> will receive this message
                        </RefferFrienText>
                    }


                    {!window && <Input
                            rows={4}
                            placeholder='Write your message here'
                            value={broadcast}
                            onChange={(e) => setBroadCast(e.target.value)}
                            onKeyPress={handleKeypress}
                    />}

                    {window
                        ?
                        <>
                            <ButtonFlex className="desktop__btn-wrapper" justify='center' gap="0" margin="10px 0 10px 0">
                                <SelectButton
                                    // disabled={google}
                                    width='120px'
                                    radius='30px 0px 0px 30px'
                                    onClick={() => setGoogle(true)}
                                    className={!google ? 'disabled' : ''}
                                >Google</SelectButton>
                                <SelectButton
                                    // disabled={!google}
                                    radius='0px 30px 30px 0px'
                                    width='120px'
                                    onClick={() => setGoogle(false)}
                                    className={google ? 'disabled' : ''}
                                >Sms</SelectButton>
                            </ButtonFlex>
                            <ButtonFlex className="desktop__btn-wrapper" margin="46px 0 46px 0">
                                <DefaultButton
                                    onClick={() => setWindow(false)}
                                    width={'133px'}>
                                    <Image src={arrowLeft}/>
                                    Back
                                </DefaultButton>
                                <DefaultButton
                                    width={'198px'}
                                    padding='17px 25px'
                                    disabled={disable}
                                    className={disable ? 'disabled' : ''}
                                    onClick={broadCastAllTrustedUsers}
                                >Send</DefaultButton>
                            </ButtonFlex>
                        </>

                        :
                        <>
                            <ButtonFlex className="desktop__btn-wrapper" justify='center' gap="0" margin="10px 0 10px 0">
                                <SelectButton
                                    // disabled={google}
                                    width='120px'
                                    radius='30px 0px 0px 30px'
                                    onClick={() => setGoogle(true)}
                                    className={!google ? 'disabled' : ''}
                                >Google</SelectButton>
                                <SelectButton
                                    // disabled={!google}
                                    radius='0px 30px 30px 0px'
                                    width='120px'
                                    onClick={() => setGoogle(false)}
                                    className={google ? 'disabled' : ''}
                                >Sms</SelectButton>
                            </ButtonFlex>
                            <ButtonFlex className="desktop__btn-wrapper" margin="10px 0 10px 0">
                                <Link to='/'>
                                    <DefaultButton
                                        width={'133px'}>
                                        <Image src={arrowLeft}/>
                                        Back
                                    </DefaultButton>
                                </Link>
                                <DefaultButton
                                    width={'198px'}
                                    padding='17px 25px'
                                    disabled={disable}
                                    className={disable ? 'disabled' : ''}
                                    onClick={messagePreview}
                                >Proceed</DefaultButton>
                            </ButtonFlex>
                        </>
                    }

                </Content>
                {window
                    ?
                    <>
                        <ButtonFlex className="reffer__buttons" justify='center' gap="0">
                            <SelectButton
                                // disabled={google}
                                width='120px'
                                radius='30px 0px 0px 30px'
                                onClick={() => setGoogle(true)}
                                className={!google ? 'disabled' : ''}
                            >Google</SelectButton>
                            <SelectButton
                                // disabled={!google}
                                radius='0px 30px 30px 0px'
                                width='120px'
                                onClick={() => setGoogle(false)}
                                className={google ? 'disabled' : ''}
                            >Sms</SelectButton>
                        </ButtonFlex>
                        <ButtonFlex className="reffer__buttons">
                            <BackButton
                                onClick={() => setWindow(false)}
                            >
                                <Image src={arrowLeft}/>
                                Back
                            </BackButton>
                            <MainButton
                                disabled={disable}
                                width='170px'
                                onClick={broadCastAllTrustedUsers}
                                className={disable ? 'disabled' : ''}
                            >Send</MainButton>
                        </ButtonFlex>
                    </>

                    :
                    <>
                        <ButtonFlex className="reffer__buttons" justify='center' gap="0">
                            <SelectButton
                                // disabled={google}
                                width='120px'
                                radius='30px 0px 0px 30px'

                                onClick={() => setGoogle(true)}
                                className={!google ? 'disabled' : ''}
                            >Google</SelectButton>
                            <SelectButton
                                // disabled={!google}
                                width='120px'
                                radius='0px 30px 30px 0px'
                                onClick={() => setGoogle(false)}
                                className={google ? 'disabled' : ''}
                            >Sms</SelectButton>
                        </ButtonFlex>
                        <ButtonFlex className="reffer__buttons">
                            <Link to='/'>
                                <BackButton>
                                    <Image src={arrowLeft}/>
                                    Back
                                </BackButton>
                            </Link>
                            <MainButton
                                disabled={disable}
                                width='170px'
                                onClick={messagePreview}
                                className={disable ? 'disabled' : ''}
                            >Proceed</MainButton>
                        </ButtonFlex>
                    </>
                }

            </MainContainerAddress>
        </AnimatePage>
    );
};

export default BroadCast;

interface PropsType {
    phone: string
    count: string
    isAdmin: boolean
}
