import { ProductsType } from "../types"

export const formedCart = (cart: ProductsType[]) => {
    return cart.map(el => {
        let {name, category, measure, cost, pack, img_url, id, group, count, costs, procurement_cost, cost_terra } = el
        costs = formedCosts(costs)
        return {name, category, measure, cost, pack, img_url, id, group,count, costs, procurement_cost, cost_terra}
    })
}
export const formedCosts = (costs: {cost: number, unit: number}[]) => {
    return costs.map(el => {
        const {cost, unit} = el
        return {cost, unit}
    })
}
export const formedGifts = (gifts: any) => {
    const {name, is_gift, id, gift_holder, cost} = gifts
    return {name, is_gift, id, gift_holder, cost}
}
