//@ts-nocheck
import {ApolloClient, InMemoryCache, split} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';

const wsLink = new WebSocketLink({
  uri: `wss://terra.menu/subscriptions`,
   // uri: `ws://localhost:8081/subscriptions`,
    options: {
    reconnect: true,
    timeout: 30000
  }
});
const uploadLink = createUploadLink({
    uri: `https://terra.menu/api/graphql`,
    // uri: `http://localhost:8081/api/graphql`,
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadLink
);
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('Terracy_user_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
export const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache()
})
