import React, {useState} from 'react';
import { WrapperRating } from "./styled";
import {useMutation} from "@apollo/client";
import {CHANGE_RATING} from "../../../api";
import Star from "../../../assets/Star.svg"
import {Image} from "../../common";
import StarActive from "../../../assets/StarActive.svg";

 const RatingUser = ({user_id, setDisabled, disabled, message_id}: PropsType) => {
     const [rating, setRating] = useState<number>(0);
     const handleChange = (value: number) => {
         setRating(value);
     }
     return (
         <>
             <StarRating
                 count={5}
                 size={34}
                 value={rating}
                 activeColor ={'#70A05A'}
                 inactiveColor={'#E5E5E5'}
                 onChange={handleChange}
                 user_id={user_id}
                 setDisabled={setDisabled}
                 disabled={disabled}
                 message_id={message_id}
             />

         </>
     )
}
interface PropsType {
    user_id: number
    setDisabled: (disabled: boolean) => void
    disabled: boolean
    message_id: string
}
export default RatingUser

const StarRating = ({
                        count,
                        value,
                        inactiveColor,
                        size,
                        activeColor,
                        onChange,
                        user_id,
                        setDisabled,
                        disabled,
                        message_id
                    }: RatingType) => {
    const [changeRating] = useMutation(CHANGE_RATING)
    const stars = Array.from({length: count}, () => '🟊')
    const ratingService = async (index: number) => {
        await sendUserRating(index)

    };
    const sendUserRating = async (index: number) => {
        if(index === 5) {
            await changeRating({
                variables: {
                    id: user_id,
                    comment: `Ooooh, it's so sweet :) We appreciate it, stay awesome!`,
                    role: 'max_rating',
                    image: '',
                    rating: index,
                    message_id
                }
            })
        } else {
            await changeRating({
                variables: {
                    id: user_id,
                    comment: `Thank you! Could you inform us what we can  improve? Write it in the chat! I'II read it asap and we'll get to the bottom of this :)`,
                    role: 'dont_max_rating',
                    image: '',
                    rating: index,
                    message_id

                }
            })
        }
    }
    const handleChange = async (value: number) => {
        onChange(value + 1);
    }
    return (
        <WrapperRating>
            {stars.map((s, index) => {
                let style = Star;
                if (index < value) {
                    style= StarActive
                }
                return (
                    <button
                          key={index}
                          disabled={disabled}
                          onClick={async ()=>
                          {
                              await handleChange(index)
                              await ratingService(index + 1)
                              setDisabled(true)
                          }}>

                        <Image  className='cart__info-image' src={style}/>
                    </button>
                )
            })}

        </WrapperRating>
    )
}

interface RatingType {
    inactiveColor: string
    activeColor: string
    size: number
    user_id: number
    count: number
    value: number
    onChange: (value: number) => void
    setDisabled: (disabled: boolean) => void
    disabled: boolean
    message_id: string
}


