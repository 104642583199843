import React, {KeyboardEvent, useState} from 'react';
import { AnimatePage } from '..';
import {useQuery} from "@apollo/client";
import {GET_STATISTICS} from "../../api";
import {StatisticsType} from "../../types";
import Loader from "../loader";
import {
    Block,
    MainContainerWindow,
    TitleBlock,
    ContentHeader,
    ContentHeaderDay,
    ContentBody,
    ContentHeaderHigh,
    ContentHeaderMon,
    BlockAut,
    ContentHeaderOne
} from "./styled";
import moment from "moment";

const Statistics = ({isAdmin, phone}: PropsType) => {
    const {data: statistics, loading} = useQuery<{getStatistics: StatisticsType[]}>(GET_STATISTICS, {variables: {phone: phone}, fetchPolicy: 'no-cache'})
    if (loading) {
        return <Loader/>
    }
    return (
        <AnimatePage>
            <MainContainerWindow>
                <Block>
                <TitleBlock><h3>Statistics</h3></TitleBlock>
                    <ContentHeader>
                        <ContentHeaderDay>Name</ContentHeaderDay>
            {
                statistics && statistics?.getStatistics.map((el, idx) =>
                        <>
                            <ContentHeaderDay key={idx * 25}>{el.year}</ContentHeaderDay>
                        </>
                )
            }
                    </ContentHeader>
                    <ContentBody>
                        <ContentHeaderHigh>Total</ContentHeaderHigh>
                        {
                            statistics && statistics?.getStatistics.map((el, idx) =>
                                <>
                                    <ContentHeaderHigh key={idx * 20}>{el.total_year + el.total_etf_year}</ContentHeaderHigh>
                                </>
                            )
                        }
                    </ContentBody>
                    <ContentBody>
                        <ContentHeaderHigh>Total(discount)</ContentHeaderHigh>
                        {
                            statistics && statistics?.getStatistics.map((el, idx) =>
                                <>
                                    <ContentHeaderHigh key={idx * 20}>{(el.total_after_discount_year + el.total_after_discount_etf_year).toFixed(0)}</ContentHeaderHigh>
                                </>
                            )
                        }
                    </ContentBody>
                    <ContentBody>
                        <ContentHeaderHigh>Profit</ContentHeaderHigh>
                        {
                            statistics && statistics?.getStatistics.map((el, idx) =>
                                <>
                                    <ContentHeaderHigh key={idx * 20}>{(el.profit_etf_year + el.profit_year).toFixed(0)}</ContentHeaderHigh>
                                </>
                            )
                        }
                    </ContentBody>
                    <ContentBody>
                        <ContentHeaderHigh>Total(other)</ContentHeaderHigh>
                        {
                            statistics && statistics?.getStatistics.map((el, idx) =>
                                    <>
                                        <ContentHeaderHigh key={idx * 20}>{el.total_year}</ContentHeaderHigh>
                                    </>
                            )
                        }
                    </ContentBody>
                    <ContentBody>
                        <ContentHeaderHigh>Discount(other)</ContentHeaderHigh>
                        {
                            statistics && statistics?.getStatistics.map((el, idx) =>
                                <>
                                    <ContentHeaderHigh key={idx * 20}>{el.total_after_discount_year.toFixed(0)}</ContentHeaderHigh>
                                </>
                            )
                        }
                    </ContentBody>
                    <ContentBody>
                        <ContentHeaderHigh>Profit(other)</ContentHeaderHigh>
                        {
                            statistics && statistics?.getStatistics.map((el, idx) =>
                                <>
                                    <ContentHeaderHigh key={idx * 20}>{el.profit_year.toFixed(0)}</ContentHeaderHigh>
                                </>
                            )
                        }
                    </ContentBody>
                    <ContentBody>
                        <ContentHeaderHigh>Total(etf)</ContentHeaderHigh>
                        {
                            statistics && statistics?.getStatistics.map((el, idx) =>
                                <>
                                    <ContentHeaderHigh key={idx * 20}>{el.total_etf_year.toFixed(0)}</ContentHeaderHigh>
                                </>

                            )
                        }
                    </ContentBody>
                    <ContentBody>
                        <ContentHeaderHigh>Discount(etf)</ContentHeaderHigh>
                        {
                            statistics && statistics?.getStatistics.map((el, idx) =>
                                <>
                                    <ContentHeaderHigh key={idx * 20}>{el.total_after_discount_etf_year.toFixed(0)}</ContentHeaderHigh>
                                </>

                            )
                        }
                    </ContentBody>
                    <ContentBody>
                        <ContentHeaderHigh>Profit(etf)</ContentHeaderHigh>
                        {
                            statistics && statistics?.getStatistics.map((el, idx) =>
                                <>
                                    <ContentHeaderHigh key={idx * 20}>{el.profit_etf_year.toFixed(0)}</ContentHeaderHigh>
                                </>

                            )
                        }
                    </ContentBody>
                    <Block>
                        <TitleBlock><h3>Months 2024</h3></TitleBlock>
                        <BlockAut>
                        <ContentHeaderOne>
                            <ContentHeaderDay >
                                M
                            </ContentHeaderDay>
                                {
                                    statistics && statistics?.getStatistics[1].months.map((el, idx) => {
                                        let moth = moment(el.month).format("MMM")
                                        return (<ContentHeaderDay key={idx * 25}>
                                            {el.month}
                                        </ContentHeaderDay>)
                                        }
                                    )
                                }
                        </ContentHeaderOne>
                        <ContentHeaderMon>
                            <ContentHeaderDay >
                                Total
                            </ContentHeaderDay>
                            {
                                statistics && statistics?.getStatistics[1].months.map((el, idx) => {
                                        let moth = moment(el.month).format("MMM")
                                        return (<ContentHeaderDay key={idx * 25}>
                                            {el.total}
                                        </ContentHeaderDay>)
                                    }
                                )
                            }
                        </ContentHeaderMon>
                            <ContentHeaderMon>
                                <ContentHeaderDay >
                                    Disc
                                </ContentHeaderDay>
                                {
                                    statistics && statistics?.getStatistics[1].months.map((el, idx) => {
                                            let moth = moment(el.month).format("MMM")
                                            return (<ContentHeaderDay key={idx * 25}>
                                                {el.total_after_discount}
                                            </ContentHeaderDay>)
                                        }
                                    )
                                }
                            </ContentHeaderMon>
                            <ContentHeaderMon>
                                <ContentHeaderDay >
                                    Prof
                                </ContentHeaderDay>
                                {
                                    statistics && statistics?.getStatistics[1].months.map((el, idx) => {
                                            let moth = moment(el.month).format("MMM")
                                            return (<ContentHeaderDay key={idx * 25}>
                                                {el.profit.toFixed()}
                                            </ContentHeaderDay>)
                                        }
                                    )
                                }
                            </ContentHeaderMon>
                            <ContentHeaderMon>
                                <ContentHeaderDay >
                                    Etf
                                </ContentHeaderDay>
                                {
                                    statistics && statistics?.getStatistics[1].months.map((el, idx) => {
                                            let moth = moment(el.month).format("MMM")
                                            return (<ContentHeaderDay key={idx * 25}>
                                                {el.total_etf}
                                            </ContentHeaderDay>)
                                        }
                                    )
                                }
                            </ContentHeaderMon>
                            <ContentHeaderMon>
                                <ContentHeaderDay >
                                    Etf(d)
                                </ContentHeaderDay>
                                {
                                    statistics && statistics?.getStatistics[1].months.map((el, idx) => {
                                            let moth = moment(el.month).format("MMM")
                                            return (<ContentHeaderDay key={idx * 25}>
                                                {el.total_after_discount_etf}
                                            </ContentHeaderDay>)
                                        }
                                    )
                                }
                            </ContentHeaderMon>
                            <ContentHeaderMon>
                                <ContentHeaderDay >
                                    Etf(p)
                                </ContentHeaderDay>
                                {
                                    statistics && statistics?.getStatistics[1].months.map((el, idx) => {
                                            return (<ContentHeaderDay key={idx * 25}>
                                                {el.profit_etf.toFixed()}
                                            </ContentHeaderDay>)
                                        }
                                    )
                                }
                            </ContentHeaderMon>
                        </BlockAut>

                    </Block>
            </Block>
            </MainContainerWindow>

        </AnimatePage>
    );
};

export default Statistics;

interface PropsType {
    phone: string

    isAdmin: boolean
}
