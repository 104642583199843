import { local } from "..";
import moment from "moment";

const users = {
    countPoints: (points: number | undefined, total?: number): {totalPoints: number, availablePoints: number, discount: number} => {
        if (!points) return {totalPoints: 0, availablePoints: 0, discount: 0};
        let totalPoints = (points % 2 === 0 ? points : points - 1) * 2.5;
        let availablePoints
        function roundTo5(disc:number) {
            return Math.round(disc/5)*5;
        }
        function roundTo0(disc:number) {
            return Math.floor(disc/5)*5;
        }
        if (total && total *0.15 < totalPoints) {
            const t = roundTo5(total * 0.10)
            const remainder = Math.floor(total * 0.10) % 5
            availablePoints = t - remainder
        } else {
            availablePoints = totalPoints
        }
        let discount = 0
        if(total) {
            discount = (total/100)*10;
        }

        if(discount) {
            discount = roundTo5(discount)
        }
        if(availablePoints < 5){
            availablePoints = 0
        }
        availablePoints = roundTo0(availablePoints)
        return {totalPoints, availablePoints, discount}
    },
    checkExpire: (time: any) => {
        const current = moment().format()
        const expired = moment(time).format()
        if (moment(expired).isBefore(current)) {
            local.removeAll()
            window.location.reload()
        }
    }
}

export default users
