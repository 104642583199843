import {
    AnimatePage,
    CartButton,
    ChangeAddress,
    ChangeNumber,
    Chat,
    Created,
    Edit,
    EditOrder,
    Loader,
    NewAddress,
    NoOrder,
    Notification,
    DeliveryNotification,
    Refer,
    RoutesWindow
} from "../";
import {CartWrapper, ContentWrapper, OrderContent, TabletContainer} from './styled'
import {Item, MenuList, MenuWrapper} from "../../pages/main/styled";
import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {AnimatePresence} from 'framer-motion'
import {GET_CART, GET_NUMBER_TRUSTED_USERS} from "../../api";
import {OrderType} from "../../types";
import PlugContainer from "../chat/Plug";
import Settings from "./settings";
import {local} from '../../utils'
import useGetDraftOrder from "../../hooks/useGetDraftOrders";
import {useQuery} from "@apollo/client";
import {useWindowDimension} from "../../hooks/useWindowDimension";
import {v4 as uuidv4} from 'uuid';
import Driver from "../driver";
import BroadCast from "../admin/broadCast";
import Statistics from "../statistics";

let routes = [
    {path: '/customer_service', menu: 'Customer service'},
    {path: '/delivery_window', menu: 'Delivery window'},
    {path: '/refer_friend', menu: 'Refer a friend'},
    {path: '/profile', menu: 'Your Profile'},
]
// const admins = ['79518890307', '12045574931', '79956880708' ]

const include = ['/order/edit', '/order', '/customer_service', '/change_address']

const Home = ({isCheckedSavings, setIsCheckedSavings, isProfile, setProfile}: {isCheckedSavings: boolean,
    setIsCheckedSavings: (isCheckedSavings: boolean) => void, isProfile: boolean,
    setProfile: (isProfile: boolean) => void}) => {
    const location = useLocation()
    const token = local.getToken() || '';
    const [width] = useWindowDimension();
    const navigate = useNavigate()
    const is744 = width >= 744
    const is360 = width < 744
    const is1280 = width >= 1280
    const notEditOrder = !include.includes(location.pathname)
    console.log('token:',token)
    const {isDraft} = useGetDraftOrder(token)
    const {data, refetch, loading} = useQuery(GET_CART, {variables: {phone: token}, fetchPolicy: "cache-and-network"})
    const {data: countData, refetch: countRefetch, loading: countLoading} = useQuery(GET_NUMBER_TRUSTED_USERS)
    const [order, setOrder] = useState<OrderType>()
    const [changeAddress, setChangeAddress] = useState<boolean>(false)
    const [count, setCount] = useState<string>('')
    let isAdmin = false
    let isDriverActive = false
    let isActiveAdmin = false
    let isCustomer = true
    if(data?.getUser.role === 'admin') {
        isAdmin = true
        isActiveAdmin = true
        isCustomer = false
    }
    if(data?.getUser.role === 'driver') {
        isDriverActive = true
        isActiveAdmin = true
        isCustomer = false
    }
    if(isAdmin) {
        routes = [
            {path: '/customer_service', menu: 'Customer service'},
        ]
    }
    if(isDriverActive) {
        routes = [
            {path: '/customer_service', menu: 'Customer service'},
        ]
    }
    if(isCustomer) {
        routes = [
            {path: '/customer_service', menu: 'Customer service'},
            {path: '/delivery_window', menu: 'Delivery window'},
            {path: '/refer_friend', menu: 'Refer a friend'},
            {path: '/profile', menu: 'Your Profile'},
        ]
    }

    console.log('isAdmin:', isAdmin)
    console.log('isDriverActive:', isDriverActive)

    useEffect(() => {
        if (token) {
            if (data?.getUser) {
                setOrder(data?.getUser?.cart)
            }
        }
        if (isAdmin) {
            if (countData?.getNumberTrustedUsers) {
                setCount(countData?.getNumberTrustedUsers?.count)
            }
        }
        refetch()
        countRefetch()
    }, [data, countData])

    useEffect(() => {
        if (isDraft) {
            local.setUserSettings({address: isDraft.address})
        }
    }, [isDraft]);

    const showDeliveryWindow = (window: string) => {
        if (is1280 && window === 'Delivery window') {
            setChangeAddress(false)
        }
    }
    if (loading) return <Loader/>
    if (isAdmin && countLoading) return <Loader/>
    return (<>

            {!notEditOrder &&
                <OrderContent isChat={location.pathname === '/customer_service'}>
                    {is1280 && (location.pathname === '/customer_service') &&
                        <div className="menu__wrapper chat__menu">
                            <MenuList id="main__menu">
                                {routes.map((item, idx) => {
                                        if (isAdmin && item.menu !== 'Customer service') {
                                            return <Item width={!is744 ? '290px' : ''} key={uuidv4()} to={item.path}>{item.menu}</Item>
                                        }
                                    if (isDriverActive && item.menu !== 'Customer service') {
                                        return <Item width={!is744 ? '290px' : ''} key={uuidv4()} to={item.path}>{item.menu}</Item>
                                    }
                                        if (!isAdmin && !isDriverActive) {
                                            if(item.menu === 'Refer a friend') {
                                                return <Item width={!is744 ? '290px' : ''} key={uuidv4()} to={item.path}>{item.menu} {is744 && !is1280 ? <div className="refer-bonus__planshet">+30$</div>: <div className="refer-bonus__mobile">+30$</div>}</Item>
                                            } else {
                                                return <Item width={!is744 ? '290px' : ''} key={uuidv4()} to={item.path}>{item.menu}</Item>
                                            }

                                        }
                                    }
                                )
                                }
                            </MenuList>
                            <CartWrapper><CartButton
                                isCheckedSavings={isCheckedSavings}
                                setIsCheckedSavings={setIsCheckedSavings}
                                points={data?.getUser?.points}
                                isMain={true}
                                text='Your cart'
                            /></CartWrapper>
                        </div>}
                    <AnimatePage>
                        <AnimatePresence exitBeforeEnter>
                            <Routes key={location.pathname} location={location}>
                                <Route path='/order' element={<Edit
                                    token={token}
                                    id={order?.id}
                                    order={order}
                                    isAdmin={isAdmin}
                                    isDriverActive={isDriverActive}
                                />}/>
                                <Route path='/order/edit'
                                       element={<EditOrder
                                           isAdmin={isAdmin}
                                           isDriverActive={isDriverActive}
                                           order={order} id={order?.id}
                                           token={token}/>}/>
                                <Route path={'/customer_service'} element={
                                    <Chat isAdmin={isAdmin}
                                          isDriverActive={isDriverActive}
                                          role={data?.getUser?.role}
                                          id={data?.getUser?.id}
                                          orderId={data?.getUser?.cart?.id}
                                    />}/>
                                {!is1280 && location.pathname === '/change_address' &&
                                    <Route path='/change_address'
                                           element={<ChangeAddress userId={data?.getUser?.id} token={token}/>}/>}
                            </Routes>
                        </AnimatePresence>
                    </AnimatePage>
                </OrderContent>}





            {notEditOrder &&
                <ContentWrapper justify={isProfile ? "flex-end" : "flex-start"} isAdmin={isActiveAdmin} isMain={location.pathname === '/'}>

                    {is744 && !is1280 && location.pathname === '/' && token &&
                        <>
                            <DeliveryNotification
                                order={order}
                                phone={token}
                                id={data?.getUser?.id}
                                first_name={data?.getUser?.first_name}
                                isAdmin={isAdmin}
                                isDriverActive={isDriverActive}

                            />
                            <Notification
                                order={order}
                                phone={token}
                                id={data?.getUser?.id}
                                first_name={data?.getUser?.first_name}
                                isAdmin={isAdmin}
                                isDriverActive={isDriverActive}
                            />
                        </>
                        }
                    {is1280 && notEditOrder && token &&

                        <>
                            <DeliveryNotification
                                order={order}
                                phone={token}
                                id={data?.getUser?.id}
                                first_name={data?.getUser?.first_name}
                                isAdmin={isAdmin}
                                isDriverActive={isDriverActive}

                            /> <Notification
                            order={order}
                            phone={token}
                            id={data?.getUser?.id}
                            first_name={data?.getUser?.first_name}
                            isAdmin={isAdmin}
                            isDriverActive={isDriverActive}
                        />
                        </>
                         }
                    {!isProfile && is360 && notEditOrder && token &&location.pathname === '/' && <>
                        <DeliveryNotification
                            order={order}
                            phone={token}
                            id={data?.getUser?.id}
                            first_name={data?.getUser?.first_name}
                            isAdmin={isAdmin}
                            isDriverActive={isDriverActive}

                        />
                    </>}
                    {!isProfile && is360 && notEditOrder && token &&location.pathname === '/' && <>
                        <Notification
                        order={order}
                        phone={token}
                        id={data?.getUser?.id}
                        first_name={data?.getUser?.first_name}
                        isAdmin={isAdmin}
                        isDriverActive={isDriverActive}
                    />
                    </>}
                    <TabletContainer isMain={location.pathname === '/'}>
                        <AnimatePage>
                            <AnimatePresence exitBeforeEnter>
                                <Routes key={location.pathname} location={location}>
                                    <Route path='/' element={<Main
                                        order={order} token={token}
                                        isAdmin={isAdmin}
                                        isProfile={isProfile}
                                        setProfile={setProfile}
                                        isDriverActive={isDriverActive}
                                        isCustomer={isCustomer}/>}/>
                                    <Route path='/refer_friend' element={<Refer phone={token}/>}/>
                                    {isAdmin && <Route path='/broadcast' element={<BroadCast phone={token} count={count}
                                                                                             isAdmin={isAdmin}/>}/>}
                                    <Route path='/statistics' element={<Statistics isAdmin={isAdmin} phone={token}/>}/>
                                    {isAdmin &&
                                        <Route path='/customer_service/:userId' element={<Chat
                                            isAdmin={isAdmin}
                                            isDriverActive={isDriverActive}
                                            role={data?.getUser?.role}
                                            id={data?.getUser?.id}
                                            orderId={data?.getUser?.cart?.id}

                                        />}/>
                                    }
                                    {isDriverActive &&
                                        <Route path='/customer_service/:userId' element={<Chat
                                            isAdmin={isAdmin}
                                            isDriverActive={isDriverActive}
                                            role={data?.getUser?.role}
                                            id={data?.getUser?.id}
                                            orderId={data?.getUser?.cart?.id}

                                        />}/>
                                    }

                                    <Route path='/delivery_window' element={<RoutesWindow
                                        isCheckedSavings={isCheckedSavings}
                                        setIsCheckedSavings={setIsCheckedSavings}
                                        userId={data?.getUser?.id}
                                        points={data?.getUser?.points}
                                        changeAddress={changeAddress}
                                        setChangeAddress={setChangeAddress}/>}/>
                                    <Route path='/profile' element={<Settings user={data?.getUser}/>}/>
                                    <Route path='/profile/new_profile' element={<NewAddress phone={token}/>}/>
                                    <Route path='/profile/change_number'
                                           element={<ChangeNumber userId={data?.getUser?.id}/>}/>
                                </Routes>
                            </AnimatePresence>
                        </AnimatePage>
                    </TabletContainer>
                    {is744 && notEditOrder && location.pathname !== '/change_address' &&
                        <div className="menu__wrapper">
                            {!is1280 && <CartWrapper><CartButton
                                points={data?.getUser?.points}
                                isMain={true}
                                text='Open cart'
                                isCheckedSavings={isCheckedSavings}
                                setIsCheckedSavings={setIsCheckedSavings}
                            /></CartWrapper>}
                            <MenuList id="main__menu">
                                {routes.map((item, idx) => {
                                        if (isAdmin && item.menu !== 'Customer service') {
                                            return <Item width={!is744 ? '290px' : ''} key={uuidv4()} to={item.path}
                                                         onClick={() => showDeliveryWindow(item.menu)}>{item.menu}</Item>
                                        }
                                    if (isDriverActive && item.menu !== 'Customer service') {
                                        return <Item width={!is744 ? '290px' : ''} key={uuidv4()} to={item.path}
                                                     onClick={() => showDeliveryWindow(item.menu)}>{item.menu}</Item>
                                    }
                                    if (!isAdmin && !isDriverActive) {
                                        if(item.menu === 'Refer a friend') {
                                            return <Item padding='37px 0 37px 50px' className={item.menu === 'Refer a friend' ? 'refer-bonus__planshet': ''} width={!is744 ? '290px' : ''} key={uuidv4()} to={item.path}
                                                         onClick={() => showDeliveryWindow(item.menu)}>{item.menu} {is744 && !is1280 ? <span>+30$</span>: <div className="refer-bonus__mobile">+30$</div>}</Item>
                                        } else {
                                            return <Item width={!is744 ? '290px' : ''} key={uuidv4()} to={item.path}
                                                         onClick={() => showDeliveryWindow(item.menu)}>{item.menu}</Item>
                                        }

                                    }
                                    }
                                )}
                            </MenuList>
                            {isCustomer && is1280 && <CartWrapper><CartButton
                                points={data?.getUser?.points}
                                isMain={true}
                                isCheckedSavings={isCheckedSavings}
                                setIsCheckedSavings={setIsCheckedSavings}
                                text='Open cart'/></CartWrapper>}
                        </div>}
                </ContentWrapper>}
        </>
    );
};

const Main = ({order, token, isAdmin, isDriverActive, isCustomer, isProfile, setProfile}: {order: OrderType | undefined, token: string, isAdmin: boolean, isDriverActive: boolean, isCustomer: boolean,
    isProfile: boolean,
    setProfile: (isProfile: boolean) => void
}) => {
    const location = useLocation()
    const main_page = location.pathname === '/'
    const [width] = useWindowDimension();
    const is744 = width >= 744
    const is1280 = width >= 1280
    useEffect(() => {

    },[isProfile])
    return (
        <AnimatePage>
            <>

                {isAdmin && <Driver isAdmin={isAdmin} isDriverActive={isDriverActive} token={token}/>}
                {isDriverActive && <Driver isAdmin={isAdmin} isDriverActive={isDriverActive} token={token}/>}
                {isCustomer && order && main_page && token && <Created cart={order} phone={token}/>}
                {!isDriverActive && !isAdmin && !order && is744 && <NoOrder width={width}/>}
                {main_page && !is744 &&
                <>
                    {isProfile && <MenuWrapper>
                        <MenuList padding={"200px"}  id="main__menu">
                        {routes.map((item, idx) =>
                            {
                                if(isAdmin && item.menu !== 'Customer service') {
                                    return <Item key={uuidv4()} to={item.path}>{item.menu}</Item>
                                }
                                if(isDriverActive && item.menu !== 'Customer service') {
                                    return <Item key={uuidv4()} to={item.path}>{item.menu}</Item>
                                }
                                if (!isAdmin && !isDriverActive) {
                                    if(item.menu === 'Refer a friend') {
                                        return <Item width={!is744 ? '290px' : ''} key={uuidv4()} to={item.path}>{item.menu} {is744 && !is1280 ? <div className="refer-bonus__planshet">+30$</div>: <div className="refer-bonus__mobile">+30$</div>} </Item>
                                    } else {
                                        return <Item key={uuidv4()} to={item.path}>{item.menu}</Item>
                                    }
                                }
                            }
                        )}
                    </MenuList>
                    </MenuWrapper>


                    }

                </>}
            </>
        </AnimatePage>
    )
}

export default Home;



