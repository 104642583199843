import ammonia from '../assets/icons/iconsFlavour/ammonia.svg'
import berry from '../assets/icons/iconsFlavour/berry.svg'
import cheese from '../assets/icons/iconsFlavour/cheese.svg'
import chemical from '../assets/icons/iconsFlavour/chemical.svg'
import citrus from '../assets/icons/iconsFlavour/citrus.svg'
import coffee from '../assets/icons/iconsFlavour/coffee.svg'
import diesel from '../assets/icons/iconsFlavour/diesel.svg'
import earthy from '../assets/icons/iconsFlavour/earthy.svg'
import flowery from '../assets/icons/iconsFlavour/flowery.svg'
import fruity from '../assets/icons/iconsFlavour/fruity.svg'
import herbal from '../assets/icons/iconsFlavour/herbal.svg'
import honey from '../assets/icons/iconsFlavour/honey.svg'
import mint from '../assets/icons/iconsFlavour/mint.svg'
import nutty from '../assets/icons/iconsFlavour/nutty.svg'
import pine from '../assets/icons/iconsFlavour/pine.svg'
import pungent from '../assets/icons/iconsFlavour/pungent.svg'
import skunk from '../assets/icons/iconsFlavour/skunk.svg'
import sweet from '../assets/icons/iconsFlavour/sweet.svg'
import tar from '../assets/icons/iconsFlavour/tar.svg'
import tobacco from '../assets/icons/iconsFlavour/tobacco.svg'
import tropical from '../assets/icons/iconsFlavour/tropical.svg'
import vanilla from '../assets/icons/iconsFlavour/vanilla.svg'
import woody from '../assets/icons/iconsFlavour/woody.svg'

import calm from '../assets/icons/iconsEffects/calm.svg'
import energy from '../assets/icons/iconsEffects/energy.svg'
import creative from '../assets/icons/iconsEffects/creative.svg'
import happy from '../assets/icons/iconsEffects/happy.svg'
import sleepy from '../assets/icons/iconsEffects/sleepy.svg'

export const dataIconsFromEffects = {
    calm: {
        id: 1,
        name: "Calm",
        img_url: calm
    },
    energy: {
        id: 2,
        name: "Energy",
        img_url: energy
    },
    creative: {
        id: 3,
        name: "Creative",
        img_url: creative
    },
    happy: {
        id: 4,
        name: "Happy",
        img_url: happy
    },
    sleepy: {
        id: 5,
        name: "Sleepy",
        img_url: sleepy
    },
}

export const dataIconsArray = [
    {
        id: 1,
        name: "Ammonia",
        img_url: ammonia
    },

    {
        id: 2,
        name: "Berry",
        img_url: berry
    },
    {
        id: 3,
        name: "Cheese",
        img_url: cheese
    },
    {
        id: 4,
        name: "Chemical",
        img_url: chemical
    },
    {
        id: 5,
        name: "Citrus",
        img_url: citrus
    },
    {
        id: 6,
        name: "Coffee",
        img_url: coffee
    },
    {
        id: 7,
        name: "Diesel",
        img_url: diesel
    },
    {
        id: 8,
        name: "Earthy",
        img_url: earthy
    },
    {
        id: 9,
        name: "Flowery",
        img_url: flowery
    },
    {
        id: 10,
        name: "Fruity",
        img_url: fruity
    },
    {
        id: 11,
        name: "Herbal",
        img_url: herbal
    },
    {
        id: 12,
        name: "Honey",
        img_url: honey
    },
    {
        id: 13,
        name: "Mint",
        img_url: mint
    },
    {
        id: 14,
        name: "Nutty",
        img_url: nutty
    },
    {
        id: 15,
        name: "Pine",
        img_url: pine
    },
    {
        id: 16,
        name: "Pungent",
        img_url: pungent
    },
    {
        id: 17,
        name: "Skunk",
        img_url: skunk
    },
    {
        id: 18,
        name: "Sweet",
        img_url: sweet
    },
    {
        id: 19,
        name: "Tar",
        img_url: tar
    },
    {
        id: 20,
        name: "Tobacco",
        img_url: tobacco
    },
    {
        id: 21,
        name: "Tropical",
        img_url: tropical
    },
    {
        id: 22,
        name: "Vanilla",
        img_url: vanilla
    },
    {
        id: 23,
        name: "Woody",
        img_url: woody
    }
]




export const dataIcons = {
    ammonia: {
        id: 1,
        name: "Ammonia",
        img_url: ammonia
    },

    berry: {
        id: 2,
        name: "Berry",
        img_url: berry
    },
    cheese: {
        id: 3,
        name: "Cheese",
        img_url: cheese
    },
    chemical: {
        id: 4,
        name: "Chemical",
        img_url: chemical
    },
    citrus: {
        id: 5,
        name: "Citrus",
        img_url: citrus
    },
    coffee: {
        id: 6,
        name: "Coffee",
        img_url: coffee
    },
    diesel: {
        id: 7,
        name: "Diesel",
        img_url: diesel
    },
    earthy: {
        id: 8,
        name: "Earthy",
        img_url: earthy
    },
    flowery: {
        id: 9,
        name: "Flowery",
        img_url: flowery
    },
    fruity: {
        id: 10,
        name: "Fruity",
        img_url: fruity
    },
    herbal: {
        id: 11,
        name: "Herbal",
        img_url: herbal
    },
    honey: {
        id: 12,
        name: "Honey",
        img_url: honey
    },
    mint: {
        id: 13,
        name: "Mint",
        img_url: mint
    },
    nutty: {
        id: 14,
        name: "Nutty",
        img_url: nutty
    },
    pine: {
        id: 15,
        name: "Pine",
        img_url: pine
    },
    pungent: {
        id: 16,
        name: "Pungent",
        img_url: pungent
    },
    skunk: {
        id: 17,
        name: "Skunk",
        img_url: skunk
    },
    sweet: {
        id: 18,
        name: "Sweet",
        img_url: sweet
    },
    tar: {
        id: 19,
        name: "Tar",
        img_url: tar
    },
    tobacco: {
        id: 20,
        name: "Tobacco",
        img_url: tobacco
    },
    tropical: {
        id: 21,
        name: "Tropical",
        img_url: tropical
    },
    vanilla: {
        id: 22,
        name: "Vanilla",
        img_url: vanilla
    },
    woody: {
        id: 23,
        name: "Woody",
        img_url: woody
    }
}