import {BackButton, ButtonFlex, Image, MainButton, MainContainerAddress} from "../../common";
import { Content, DefaultButton, Header, Input, RefferFrienText, TitleBlock } from '../styled';
import React, {KeyboardEvent, useState} from 'react';

import { AnimatePage } from '../..';
import {Link, useNavigate} from 'react-router-dom';
import arrowLeft from "../../../assets/icons/arrowLeftWhite.svg";
import {useToasts} from "react-toast-notifications";
import {useMutation} from "@apollo/client";
import {REFER_FRIEND} from "../../../api";
import {useWindowDimension} from "../../../hooks/useWindowDimension";

const Refer = ({phone}: PropsType) => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [referFriend] = useMutation(REFER_FRIEND)
    const [phone_friend, setPhone] = useState<string>('')
    const [address_friend, setAddress] = useState<string>('')
    const [width] = useWindowDimension();
    const is743 = width >= 743
    const disable = phone_friend.length < 10 || address_friend.length < 6
    const create = async () => {
        let changePhone
        const phoneReplaceNumber = phone_friend.replace(/[^0-9.]/g, '')
        if (phoneReplaceNumber.length === 11) {
            if (phoneReplaceNumber.split('')[0] === '1') {
                changePhone = phoneReplaceNumber
            }
        }
        if (phoneReplaceNumber.length === 10) {
            changePhone = `1${phoneReplaceNumber}`
        }
        await referFriend({
            variables: {
                phone,
                address_friend,
                phone_friend: changePhone
            }
        }).then(({ data }) => {
            if(data.referFriend.danger === "danger"){
                addToast(`This phone was previously registered in the system.` , {appearance: 'error', autoDismiss: true})
            } else {
                setAddress('')
                setPhone('')
                addToast(`Your request was sent, please wait the admin to approve your friend` , {appearance: 'success', autoDismiss: true})
                if(!is743) {
                    return  navigate('/')
                }  else {
                    navigate('/')
                }
            }



        }).catch((err) => console.log('error', err))
    }

    const handleKeypress = (e: KeyboardEvent) => {
        //it triggers by pressing the enter key
        if (e.key === "Enter" && e.code === "Enter") {
            create()
        }
    };
    return (
        <AnimatePage>
        <MainContainerAddress className='reffer__container'>
            <Header className='mobile__header'>Refer a friend</Header>
            <TitleBlock className='desktop__header'><h3>Refer a friend</h3></TitleBlock>
            <Content className='friend__content'>
                {!is743 && <RefferFrienText margin={'25px auto'} className='text-refer'>
                    We rely on word of mouth. As a result, we
                    appreciate every single referral whole
                    heartedly! Every referral is worth $30. You get
                    $20 and they get $10 to user after their first
                    order, the $ is added to both your accounts.
                    <br/>
                    <br/>
                    All we need is their physical address and
                    phone number. Once we have those and
                    confirm the address is correct, we'll send you a
                    personalized link to give them.
                </RefferFrienText>}
                {is743 && <RefferFrienText margin={'25px auto'} font={'16px'} className='text-refer'>
                    We rely on word of mouth. As a result, we
                    appreciate every single referral whole
                    heartedly! Every referral is worth $30. You get
                    $20 and they get $10 to user after their first
                    order, the $ is added to both your accounts.
                </RefferFrienText>}
                <Input type='text' placeholder='Address'
                    value={address_friend}
                    onChange={(e) => setAddress(e.target.value)}
                    onKeyPress={handleKeypress}

                />
                <Input type='text' placeholder='Phone number'
                    value={phone_friend}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyPress={handleKeypress}
                    maxLength={11}
                />

                    <ButtonFlex className="desktop__btn-wrapper">
                        <Link to='/'>
                            <DefaultButton width={'133px'}>
                                <Image src={arrowLeft}/>
                                Back
                            </DefaultButton>
                        </Link>
                        <DefaultButton
                            width={'198px'}
                            padding='17px 25px'
                            disabled={disable}
                            onClick={create}
                            className={disable ? 'disabled' : ''}
                        >Invite</DefaultButton>
                    </ButtonFlex>

            </Content>

            <ButtonFlex className="reffer__buttons">
                <Link to='/'>
                    <BackButton>
                        <Image src={arrowLeft}/>
                        Back
                    </BackButton>
                </Link>
                <MainButton
                    disabled={disable}
                    width='170px'
                    onClick={create}
                    className={disable ? 'disabled' : ''}
                >Invite</MainButton>
            </ButtonFlex>
        </MainContainerAddress>
        </AnimatePage>
    );
};

export default Refer;

interface PropsType {
    phone: string
}
