import {gql} from '@apollo/client'

export const LOGIN = gql`
    mutation Login($phone: String!) {
        login(phone: {phone: $phone}) {
            token
            last_profile
            new_profile
            id
        }
    }
`

export const UPLOAD_FILE = gql`
    mutation UploadFile($file: Upload!, $userId: Float!, $isAdmin: Boolean!, $toggle: String!, $isDriverActive: Boolean!, $isCustomerActive: Boolean!) {
        uploadFile(file:{file: $file, id: $userId, isAdmin: $isAdmin, toggle: $toggle, isDriverActive: $isDriverActive, isCustomerActive: $isCustomerActive})
    }
`

export const CONFIRM = gql`
    mutation ConfirmUser($phone: String!, $token: Float!) {
        confirmUser(input: {phone: $phone, token: $token}) {
            phone
            is_token_right
            is_token_reverse
            is_token_invalid
            role
        }
    }
`

export const CREATE_USER_COMMENT = gql`
    mutation userComments($id: Float!, $isRead: Boolean, $rating: Float, $comment: String!, $role: String!, $image: String!, $message_id: String) {
        userComments(input: {user_id: $id, isRead: $isRead, text: $comment, role: $role, image: $image, rating: $rating, message_id: $message_id}) {
            role
            user_id
            text
            created
            image
            rating
            message_id
            order_id
            isRead
            isDriver
            refer_code
            isAdminRead
        }
    }
`
export const CREATE_ADMIN_COMMENT = gql`
    mutation CreateAdminComment($id: Float,$phone: String!, $isRead: Boolean, $rating: Float, $comment: String!, $role: String!, $image: String!, $message_id: String) {
        createAdminComment(input: {user_id: $id, phone: $phone, isRead: $isRead, text: $comment, role: $role, image: $image, rating: $rating, message_id: $message_id}) {
            role
            user_id
            text
            created
            image
            rating
            message_id
            order_id
            isRead
            isDriver
            refer_code
            isAdminRead
        }
    }
`
export const CREATE_BROAD_CAST = gql`
    mutation CreateBroadCast($id: Float,$phone: String, $isRead: Boolean, $rating: Float, $comment: String!, $role: String!, $image: String!, $message_id: String, $google: Boolean) {
        createBroadCast(input: {user_id: $id, phone: $phone, isRead: $isRead, text: $comment, role: $role, image: $image, rating: $rating, message_id: $message_id, google: $google}) {
            text
        }
    }
`
export const CREATE_ORDER_COMMENT = gql`
    mutation orderUserComments($id: Float!,$isRead: Boolean, $rating: Float, $comment: String!, $role: String!, $image: String!, $message_id: String, $order_id: String) {
        orderUserComments(input: {user_id: $id, isRead: $isRead, text: $comment, role: $role, image: $image, rating: $rating, message_id: $message_id, order_id: $order_id}) {
            role
            user_id
            text
            created
            image
            rating
            message_id
            order_id
            isRead
            isDriver
            refer_code
            isAdminRead
        }
    }
`

export const CHANGE_USER_COMMENT = gql`
    mutation changeComments($id: Float!, $rating: Float, $comment: String!, $role: String!, $image: String!, $message_id: String) {
        changeComments(input: {user_id: $id, text: $comment, role: $role, image: $image, rating: $rating, message_id: $message_id}) {
            role
            user_id
            text
            created
            image
            rating
            message_id
            order_id
            isRead
            isDriver
            refer_code
            isAdminRead
        }
    }
`

export const CHANGE_RATING = gql`
    mutation changeRating($id: Float!, $rating: Float, $comment: String!, $role: String!, $image: String!, $message_id: String) {
        changeRating(input: {user_id: $id, text: $comment, role: $role, image: $image, rating: $rating, message_id: $message_id}) {
            role
            user_id
            text
            created
            image
            rating
            message_id
            order_id
            isRead
            isDriver
            refer_code
            isAdminRead
        }
    }
`
export const SET_USER_COMMENT_READ = gql`
    mutation SetUserCommentRead($phone: String!) {
        setUserCommentRead(input: {phone: $phone}) {
            role
            user_id
            text
            created
            image
            rating
            message_id
            order_id
            isRead
            isDriver
            refer_code
            isAdminRead
        }
    }
`

export const SET_ADMIN_COMMENT_READ = gql`
    mutation SetAdminCommentRead($phone: String!, $isAdmin: Boolean!, $isDriverActive: Boolean!, $toggle: String!) {
        setAdminCommentRead(input: {phone: $phone, isAdmin: $isAdmin, isDriverActive: $isDriverActive, toggle: $toggle}) {
            role
            user_id
            text
            created
            image
            rating
            message_id
            order_id
            isRead
            isDriver
            refer_code
            isAdminRead
        }
    }
`

export const ADD_ADDRESS = gql`
    mutation AddAddress($phone: String!, $address: String!) {
        addAddress(input: {phone: $phone, address: $address}) {
            id
            username
            first_name
            tokens
            orders
            tokens
            phones
            addresses
            phone
            role
            first_name
            last_name
            username
            inventory
            cart{
                id
                products {
                id
             }
      
            }
        }
    }
`
export const SET_FIRST_NAME = gql`
    mutation SetUserFirstName($phone: String!, $first_name: String!) {
        setUserFirstName(input: {phone: $phone, first_name: $first_name}) {
            first_name
            phone

        }
    }
`
export const SET_USE_SAFARI = gql`
    mutation SetUseSafari($phone: String!) {
        setUseSafari(input: {phone: $phone}) {
            phone
            use_safari
        }
    }
`

export const SET_NEW_PROFILE_FALSE = gql`
    mutation NewProfileFalse($phone: String!) {
        newProfileFalse(input: {phone: $phone}) {
            phone

        }
    }
`

export const REFER_FRIEND = gql`
    mutation ReferFriend($phone: String! $address_friend: String!, $phone_friend: String!) {
        referFriend(input: {phone: $phone address_friend: $address_friend, phone_friend: $phone_friend}) {
            referral_code
            danger
        }
    }
`


