import {Cart, Header, Home, Menu} from '../../components';
import {CartDesktop, Checkout, FreePods, Products} from '../';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import React, {useEffect, useState} from 'react';

import {AnimatePresence} from 'framer-motion'
import { UserType } from '../../types';
import { useWindowDimension } from '../../hooks/useWindowDimension';

const routes = {
    home: '/*',
    auth: '/auth',
    products: '/products',
    checkout: '/checkout/*',
    FreePod: '/products/free/:id',
    cart: '/cart',
}

const path = ['/', '/products', '/cart']
const include = ['/checkout', '/profile/change_number']

const Main = ({data}: UserDataType) => {
    const location = useLocation()
    const [width] = useWindowDimension();
    const [isProfile, setProfile] = useState<boolean>(false)
    const isMain = path.includes(location.pathname)
    const isCheckout = include.includes(location.pathname)
    const isDesktop = width >= 744;
    const [isCheckedSavings, setIsCheckedSavings] = useState<boolean>(true)

    let isAdmin = false
    let isCustomer = true
    if(data?.role === 'admin') {
        isAdmin = true
        isCustomer = false
    }
    let isDriverActive = false
    if(data?.role === 'driver') {
        isDriverActive = true
        isCustomer = false
    }
    return (
        <div className='container'>
        {!isDesktop && !isCheckout && <Header setProfile={setProfile} isProfile={isProfile} first_name={data?.first_name}/>}
        {isDesktop && <Header setProfile={setProfile} isProfile={isProfile} first_name={data?.first_name}/>}

        <AnimatePresence exitBeforeEnter>
            <Routes key={location.pathname} location={location}>
                {!isDesktop && <Route path='/cart' element={<Navigate to={routes.products}/>}/>}
                <Route path={routes.home} element={<Home
                    setProfile={setProfile}
                    isProfile={isProfile}
                    isCheckedSavings={isCheckedSavings}
                    setIsCheckedSavings={setIsCheckedSavings}

                />}/>
                <Route path={routes.checkout} element={<Checkout/>}/>
                <Route path={routes.products} element={<Products
                    points={data?.points}
                    isCheckedSavings={isCheckedSavings}
                    setIsCheckedSavings={setIsCheckedSavings}
                />}/>
                <Route path={routes.FreePod} element={<FreePods/>}/>
                {isDesktop && <Route path={routes.cart} element={<CartDesktop
                    points={data?.points}
                    six_weeks_limited={data?.six_weeks_limited}
                    seven_weeks_limited={data?.seven_weeks_limited}
                    isAdmin={isAdmin}
                    isDriverActive={isDriverActive}
                    isCheckedSavings={isCheckedSavings}
                    setIsCheckedSavings={setIsCheckedSavings}
                />}/>}
            </Routes>
        </AnimatePresence>
            {isCustomer && isMain && <>
                <Menu
                    points={data?.points}
                    isCheckedSavings={isCheckedSavings}
                    setIsCheckedSavings={setIsCheckedSavings}/>
                <>
                    <Cart
                        points={data?.points}
                        isMobile={true}
                        six_weeks_limited={data?.six_weeks_limited}
                        seven_weeks_limited={data?.seven_weeks_limited}
                        isAdmin={isAdmin}
                        isDriverActive={isDriverActive}
                        isCheckedSavings={isCheckedSavings}
                        setIsCheckedSavings={setIsCheckedSavings}
                    />
                </>
            </>}
        </div>
    );
};

export default Main;

interface UserDataType {
    data?: UserType
}

