import {GET_USER_COMMENTS, USER_COMMENTS_SUBSCRIPTION} from '../../api';
import React, {useCallback, useEffect} from 'react'

import { CommentType } from '../../types';
import { useQuery } from '@apollo/client';

export default function useDriverComments(phone: string) {

    const {data: getUserComments, subscribeToMore, refetch} = useQuery<{getUserComments: CommentType[]}>(GET_USER_COMMENTS, {variables: {phone}, fetchPolicy: 'cache-and-network', pollInterval: 10000,})
    const reloadDriver = useCallback(() => {
        refetch()
    },[])
    useEffect(() => {
        subscribeToMore({
            document: USER_COMMENTS_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return Object.assign({}, prev, {
                    getUserComments: [...prev.getUserComments, subscriptionData.data]
                })}
        })
    },[])

    return {getDriverComments: getUserComments?.getUserComments, reloadDriver}
}
