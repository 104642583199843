import {
    Customer,
    CustomerMessage,
    CustomerWrapper,
    Driver,
    DriverWrapper,
    Text,
    Time,
    Admin,
    AdminWrapper,
    AdminMessage,
    ImageAdminWrapper,
    ImageCustomerWrapper
} from './styled'
import {Image} from '../../common';
import {chat} from '../../../utils/chat';
import driver from '../../../assets/icons/driver.svg'
import {v4 as uuidv4} from 'uuid';
import React, {useState} from "react";
import {useMutation} from "@apollo/client";
import {CHANGE_USER_COMMENT} from "../../../api";
import RatingUser from "./ratingUser";
import RatingUserUse from "./ratingUserUse";
import Modal from "../modal";

const includes = {
    admin: ['admin'],
    notification: ['notification'],
    announcement: ['announcement'],
    costumer: ['trusted', 'newbie'],
    refer_a_friend: ['refer_a_friend'],
    copied: ['copied'],
    orders: ['draft', 'approved', 'in 20 min', 'in 10 min', 'in 5 min', 'here', 'etf', 'canceled'],
    ratings: ['completed', 'etf-completed'],
    max_rating: ['max_rating'],
    dont_max_rating: ['dont_max_rating'],
    send_order_comments_user: ['send_order_comments_user'],
    driver_order: ['driver_order'],
    images: ['images'],
    images_client_driver: ['images_client_driver'],
    images_admin: ['images_admin'],
    images_admin_driver: ['images_admin_driver'],
    images_driver: ['images_driver'],
    five_weeks: ['five_weeks'],
    six_weeks: ['six_weeks'],
    seven_weeks: ['seven_weeks'],
}

const Dialogue = ({
                      created,
                      text,
                      role,
                      isDriver,
                      refer_code,
                      user_id,
                      setRefer,
                      refer,
                      setDisabled,
                      disabled,
                      toggle,
                      rating,
                      message_id,
                      image,
                      isAdmin,
                      isDriverActive,
                      isCustomerActive
                  }: CommetType) => {
    const [changeComments] = useMutation(CHANGE_USER_COMMENT)
    const [active, setActive] = useState(false)
    const onShareClick = async () => {
        await changeUserComment()
        await navigator.clipboard.writeText(refer_code)
    };
    const onShareClickNew = async () => {
        await navigator.clipboard.writeText(refer_code)
    };

    const changeUserComment = async () => {
        await changeComments({
            variables: {
                id: user_id,
                comment: 'Copied! Now send it to your friend via any messaging platform. Just press paste and it should show up.',
                role: 'copied',
                image: '',
                message_id,
                refer_code,
            }
        }).then(data => {
        })
    }

    if (toggle === 'Customer') {
        if (includes.costumer.includes(role)) {
            return (
                <>
                    {isAdmin
                        ?
                        <AdminWrapper key={uuidv4()}>
                            <div className={'driver__icon'}>
                                <Image src={driver}/>
                            </div>
                            <Admin>
                                <Text>{text}</Text>
                                <Time>{chat.time(created)}</Time>
                            </Admin>
                        </AdminWrapper>
                        :
                        <CustomerWrapper key={uuidv4()}>
                            <CustomerMessage>
                                <Text>{text}</Text>
                                <Time>{chat.time(created)}</Time>
                            </CustomerMessage>
                        </CustomerWrapper>
                    }
                </>
            )
        }


        if (includes.admin.includes(role)) {
            return (
                <>
                    {isAdmin
                        ?
                        <CustomerWrapper key={uuidv4()}>
                            <AdminMessage>
                                <Text>{text}</Text>
                                <Time>{chat.time(created)}</Time>
                            </AdminMessage>
                        </CustomerWrapper>
                        :
                        <DriverWrapper key={uuidv4()}>
                            <div className={'driver__icon'}>
                                <Image src={driver}/>
                            </div>

                            <Driver>
                                <Text>{text}</Text>
                                <Time>{chat.time(created)}</Time>
                            </Driver>
                        </DriverWrapper>
                    }
                </>
            )
        }

        if (includes.notification.includes(role)) {
            return (
                <DriverWrapper>
                    <Driver key={uuidv4()}>
                        <Text>{text}</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>
                    </Driver>
                </DriverWrapper>
            )
        }
        if (includes.five_weeks.includes(role)) {
            return (
                <DriverWrapper>
                    <Driver key={uuidv4()}>
                        <Text>We miss you <br/>
                            <br/>
                            {text}</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>
                    </Driver>
                </DriverWrapper>
            )
        }
        if (includes.six_weeks.includes(role)) {
            return (
                <DriverWrapper>
                    <Driver key={uuidv4()}>
                        <Text>We're a mess without you.. <br/>
                            <br/>
                            {text}</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>
                    </Driver>
                </DriverWrapper>
            )
        }
        if (includes.seven_weeks.includes(role)) {
            return (
                <DriverWrapper>
                    <Driver key={uuidv4()}>
                        <Text>10% Discount is expired<br/>
                            <br/>
                            {text}</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>
                    </Driver>
                </DriverWrapper>
            )
        }
        if (includes.images.includes(role)) {
            return (
                <>
                    {isAdmin
                        ?
                        <>
                            <Modal active={active} setActive={setActive} image={image}/>
                            <DriverWrapper key={uuidv4()} onClick={() => setActive(true)}>
                                <div className={'driver__icon'}>
                                    <Image src={driver}/>
                                </div>
                                <ImageCustomerWrapper background={`url(${image}) no-repeat`} key={uuidv4()}>
                                </ImageCustomerWrapper>
                            </DriverWrapper>
                        </>
                        :
                        <>
                        <Modal active={active} setActive={setActive} image={image}/>
                        <ImageAdminWrapper background={`url(${image}) no-repeat`} key={uuidv4()} onClick={() => setActive(true)}>
                        </ImageAdminWrapper>
                        </>
                    }
                </>
            )
        }
        if (includes.images_admin.includes(role)) {
            return (
                <>
                    {isAdmin
                        ?
                        <>
                            <Modal active={active} setActive={setActive} image={image}/>
                            <ImageAdminWrapper background={`url(${image}) no-repeat`} key={uuidv4()} onClick={() => setActive(true)} >
                            </ImageAdminWrapper>
                        </>
                        :
                        <>
                            <Modal active={active} setActive={setActive} image={image}/>
                            <DriverWrapper key={uuidv4()} onClick={() => setActive(true)}>
                                <div className={'driver__icon'}>
                                    <Image src={driver}/>
                                </div>
                                <ImageCustomerWrapper background={`url(${image}) no-repeat`} key={uuidv4()}>
                                </ImageCustomerWrapper>
                            </DriverWrapper>
                        </>
                    }
                </>
            )
        }

        if (includes.refer_a_friend.includes(role)) {
            return (
                <>
                    <Driver key={uuidv4()}>
                        <Text>Your invite was approved! We will send the invite link to your referral.</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>
                    </Driver>
                </>
            )
        }

        if (includes.copied.includes(role)) {
            return (
                <>
                    <Driver key={uuidv4()}>
                        <Text>Your invite was approved! We will send the invite link to your referral.</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>
                    </Driver>
                </>
            )
        }

        if (includes.announcement.includes(role)) {
            return (
                <Driver key={uuidv4()}>
                    <Text>{text}</Text>
                    <div><p>Announcement</p><Time>{chat.time(created)}</Time></div>
                </Driver>)
        }

        if (includes.ratings.includes(role)) {
            return (
                <>
                    <Driver key={uuidv4()}>
                        <Text>{text}</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>

                    </Driver>
                    <Driver key={uuidv4()}>
                        <Text>Please rate the service</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>

                    </Driver>
                    <RatingUser
                        user_id={user_id}
                        setDisabled={setDisabled}
                        disabled={disabled}
                        message_id={message_id}
                    />
                </>
            )
        }

        if (includes.max_rating.includes(role)) {
            return (
                <>
                    <Driver key={uuidv4()}>
                        <Text>{text}</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>
                    </Driver>
                    <RatingUserUse
                        setDisabled={setDisabled}
                        user_id={user_id}
                        rating={rating}
                    />
                </>
            )
        }

        if (includes.dont_max_rating.includes(role)) {
            return (
                <>
                    <Driver key={uuidv4()}>
                        <Text>{text}</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>
                    </Driver>
                    <RatingUserUse
                        setDisabled={setDisabled}
                        user_id={user_id}
                        rating={rating}
                    />
                </>
            )
        }
    }

    if (toggle === 'Driver') {
        if (includes.images_driver.includes(role)) {
            return (
                <>
                    {isDriverActive &&
                        <>
                            <Modal active={active} setActive={setActive} image={image}/>
                            <ImageAdminWrapper background={`url(${image}) no-repeat`} key={uuidv4()} onClick={() => setActive(true)} >
                            </ImageAdminWrapper>
                        </>
                    }
                </>
            )
        }
        if (includes.images_admin_driver.includes(role)) {
            return (
                <>
                    {isAdmin
                        ?
                        <>
                            <Modal active={active} setActive={setActive} image={image}/>
                            <ImageAdminWrapper background={`url(${image}) no-repeat`} key={uuidv4()} onClick={() => setActive(true)} >
                            </ImageAdminWrapper>
                        </>
                        :
                        <>
                        <Modal active={active} setActive={setActive} image={image}/>
                        <DriverWrapper key={uuidv4()} onClick={() => setActive(true)}>
                        <div className={'driver__icon'}>
                        <Image src={driver}/>
                        </div>
                        <ImageCustomerWrapper background={`url(${image}) no-repeat`} key={uuidv4()}>
                        </ImageCustomerWrapper>
                        </DriverWrapper>
                        </>

                    }
                </>
            )
        }

        if (includes.images_client_driver.includes(role)) {
            return (
                <>
                    {isCustomerActive &&
                        <>
                            <Modal active={active} setActive={setActive} image={image}/>
                            <ImageAdminWrapper background={`url(${image}) no-repeat`} key={uuidv4()} onClick={() => setActive(true)}>
                            </ImageAdminWrapper>
                        </>}
                    {isAdmin && <>
                        <Modal active={active} setActive={setActive} image={image}/>
                        <DriverWrapper key={uuidv4()} onClick={() => setActive(true)}>
                            <div className={'driver__icon'}>
                                <Image src={driver}/>
                            </div>
                            <ImageCustomerWrapper background={`url(${image}) no-repeat`} key={uuidv4()}>
                            </ImageCustomerWrapper>
                        </DriverWrapper>
                    </>}
                    {isDriverActive && <>
                        <Modal active={active} setActive={setActive} image={image}/>
                        <DriverWrapper key={uuidv4()} onClick={() => setActive(true)}>
                            <div className={'driver__icon'}>
                                <Image src={driver}/>
                            </div>
                            <ImageCustomerWrapper background={`url(${image}) no-repeat`} key={uuidv4()}>
                            </ImageCustomerWrapper>
                        </DriverWrapper>
                    </>}

                </>
            )
        }
        if (includes.images_driver.includes(role)) {
            return (
                <>
                    {isDriverActive &&
                        <>
                            <Modal active={active} setActive={setActive} image={image}/>
                            <ImageAdminWrapper background={`url(${image}) no-repeat`} key={uuidv4()} onClick={() => setActive(true)}>
                            </ImageAdminWrapper>
                        </>}
                    {isAdmin &&
                        <>
                            <Modal active={active} setActive={setActive} image={image}/>
                            <ImageAdminWrapper background={`url(${image}) no-repeat`} key={uuidv4()} onClick={() => setActive(true)}>
                            </ImageAdminWrapper>
                        </>}
                    {isCustomerActive &&
                        <>
                            <Modal active={active} setActive={setActive} image={image}/>
                            <DriverWrapper key={uuidv4()} onClick={() => setActive(true)}>
                                <div className={'driver__icon'}>
                                    <Image src={driver}/>
                                </div>
                                <ImageCustomerWrapper background={`url(${image}) no-repeat`} key={uuidv4()}>
                                </ImageCustomerWrapper>
                            </DriverWrapper>
                        </>
                    }
                </>
            )
        }


        if (includes.orders.includes(role)) {
            return (
                <>
                    <Driver key={uuidv4()}>
                        <Text>{text}</Text>
                        <div><p>NOTIFICATION</p><Time>{chat.time(created)}</Time></div>
                    </Driver>
                </>
            )
        }

        if (includes.send_order_comments_user.includes(role)) {
            return (
                <>
                    {isAdmin &&
                        <AdminWrapper key={uuidv4()}>
                            <div className={'driver__icon'}>
                                <Image src={driver}/>
                            </div>
                            <Admin>
                                <Text>{text}</Text>
                                <Time>{chat.time(created)}</Time>
                            </Admin>
                        </AdminWrapper>}
                    {isDriverActive &&
                        <AdminWrapper key={uuidv4()}>
                            <div className={'driver__icon'}>
                                <Image src={driver}/>
                            </div>
                            <Admin>
                                <Text>{text}</Text>
                                <Time>{chat.time(created)}</Time>
                            </Admin>
                        </AdminWrapper>}
                    {isCustomerActive &&
                        <CustomerWrapper key={uuidv4()}>
                            <CustomerMessage>
                                <Text>{text}</Text>
                                <Time>{chat.time(created)}</Time>
                            </CustomerMessage>
                        </CustomerWrapper>
                    }

                </>


            )
        }

        if (includes.driver_order.includes(role)) {
            return (
                <>
                    {isAdmin &&
                        <CustomerWrapper key={uuidv4()}>
                            <AdminMessage>
                                <Text>{text}</Text>
                                <Time>{chat.time(created)}</Time>
                            </AdminMessage>
                        </CustomerWrapper>}
                    {isDriverActive &&
                        <CustomerWrapper key={uuidv4()}>
                            <AdminMessage>
                                <Text>{text}</Text>
                                <Time>{chat.time(created)}</Time>
                            </AdminMessage>
                        </CustomerWrapper>}
                    {isCustomerActive && <DriverWrapper key={uuidv4()}>
                        <div className={'driver__icon'}>
                            <Image src={driver}/>
                        </div>
                        <Driver>
                            <Text>{text}</Text>
                            <Time>{chat.time(created)}</Time>
                        </Driver>
                    </DriverWrapper>}
                </>
                )
        }

    }
    return <></>
}

export default Dialogue

interface CommetType {
    role: string
    created: string
    text: string
    image: string
    user_id: number
    isDriver?: boolean
    refer_code: string
    setRefer: (refer: boolean) => void
    refer: boolean
    rating: number
    toggle: string
    setDisabled: (disabled: boolean) => void
    disabled: boolean
    message_id: string
    isAdmin: boolean
    isDriverActive: boolean
    isCustomerActive: boolean

}
