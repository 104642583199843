//@ts-nocheck
import {Button, Container, Content, MoreText, NotiSubText, NotiText, Wrapper} from './styled'
import React, {ReactElement} from 'react';
import {Link} from 'react-router-dom'
import {OrderType} from '../../../types';
import moment from 'moment';
import useUserNotification from '../../../hooks/subscription/useUserNotification';
import logo from '../../../assets/logo.svg'
import {detect} from "detect-browser";
import {subscribeUser} from "../../../subscription";
import {useNavigate} from "react-router";

const Notification = ({order, phone, id, first_name, isAdmin, isDriverActive}: { order?: OrderType, phone: string, id: number, first_name: string, isAdmin: boolean, isDriverActive: boolean }) => {
    const getUserNotification = useUserNotification(phone)
    const messageCreated = moment(getUserNotification?.created).fromNow(true)
    moment.updateLocale('en', {
        relativeTime: {
            future: "in %s ago",
            past: "%s ago",
            s: '%d s ago',
            ss: '%d s ago',
            m: "%d min ago",
            mm: "%d min ago",
            h: "an hour ago",
            hh: "%d hours ago",
            d: "a day ago",
            dd: "%d days ago",
            w: "a week ago",
            ww: "%d weeks ago",
            M: "a month ago",
            MM: "%d months ago",
            y: "a year ago",
            yy: "%d years ago"
        }
    });
    return (
        <>
            {getUserNotification &&
                <Message
                    created={messageCreated}
                    title={getUserNotification.title}
                    desc={getUserNotification?.description}
                    link={getUserNotification?.link}
                    phone={phone}
                    isRead={getUserNotification?.isRead}
                    id={id}
                    first_name={first_name}
                    isAdmin={isAdmin}
                    isDriverActive={isDriverActive}
                />}
        </>
    );
};

const Message = ({created, title, desc, phone, isRead, first_name, id, isAdmin, link, isDriverActive}: PropsType) => {
    const navigate = useNavigate()
    const splitTitle = title.split(' ')
    const defaultTitle = splitTitle[splitTitle.length - 1] === 'messages!' ? 'messages!' : title
    const onShareClick = () => {
        navigate('/customer_service')
    };
    let isCustomer = true
    if(isAdmin) {
        isCustomer = false
    }
    if(isDriverActive) {
        isCustomer = false
    }
    const browser = detect();
    if (browser && browser.name !== 'safari' || browser && browser.name !== 'ios' || browser && browser.name !== 'ios-webview' || browser && browser.name !== 'miui') {
        let descriptionText = desc
        let titleText = title
        if (titleText === 'Invitation approved') {
            descriptionText = 'We welcome your friend with open arms! He will receive an invite link from us'
            titleText = 'Invite approved!'
        }
        if(!isRead) {
            if(isAdmin) {
                if(link){
                    subscribeUser(descriptionText, titleText, logo, phone, id, first_name, isAdmin, link)
                }
            } else {
                subscribeUser(descriptionText, titleText, logo, phone, id, first_name, isAdmin)

            }
        } else {
            if(!isCustomer) {
                subscribeUser(descriptionText, titleText, logo, phone, id, first_name, isAdmin)
            }
        }

    }
    let subLink
    if(link) {
        subLink = link
    } else {
        link = '/'
    }
    return (
        <Wrapper>
            {isAdmin && ""}
            {isCustomer &&
                <Container>
                    <Content padding={object[defaultTitle]?.padding}>
                        <NotiText welcome={object[defaultTitle]?.title === 'welcome'}>
                            {title}
                            <span>{created}</span>
                        </NotiText>
                        {desc.includes('http')
                            ?
                            <>
                                {desc.split(',')[0].includes('We welcome your friend')
                                    ? <NotiSubText>
                                        {desc.split(',')[0].split('!')[0]}! <br/>
                                        <br/>
                                        {desc.split(',')[0].split('!')[1]} <br/>
                                        <br/>
                                    </NotiSubText>
                                    : ''}
                            </>
                            : object[defaultTitle]?.isMoreText ?
                                <MoreText>
                                    {desc}
                                </MoreText>
                                : desc.includes("Your friend")
                                    ?
                                    <NotiSubText
                                        welcome={object[defaultTitle]?.title === 'welcome'}
                                        dangerouslySetInnerHTML={{__html: `${desc.split('!')[0]}!<br/><br/>${desc.split('!')[1]}!`}}/>
                                    :
                                    <NotiSubText
                                        welcome={object[defaultTitle]?.title === 'welcome'}
                                        dangerouslySetInnerHTML={{__html: `${desc}`}}/>
                        }
                    </Content>
                    {object[defaultTitle]?.link && <Link to={isAdmin ? link.substring(19) : object[defaultTitle]?.link}>
                        <Button width={object[defaultTitle]?.width}
                                opacity={object[defaultTitle].opacity}>{object[defaultTitle]?.buttonText} </Button>
                    </Link>}
                </Container>
            }

        </Wrapper>
    );
};

export default Notification;

interface PropsType {
    created?: string
    title: string
    desc: string
    phone: string
    isRead: boolean
    first_name: string
    id: number
    isAdmin: boolean
    link: string
    isDriverActive: boolean
}

interface ObjectType {
    [key: string]: {
        title?: string,
        desc?: ReactElement,
        padding?: string,
        link?: string,
        buttonText?: string
        width?: string
        isMoreText?: boolean
        opacity?: number
    }
}

const object: ObjectType = {
    'Your order has been made!': {
        title: 'Your order has been made!',
        desc: <>You will be notified when <br/>we will confirm it. Please wait</>
    },
    'welcome': {
        title: 'welcome',
        desc:
            <>
                We're a small local service focusing on quality and honesty over everything
                <br/> <br/>
                Too many websites say AAAA when it's AA, 30% when it's 15%, whole plant extract
                when it's just shake and stem extract. We promise to be forward about all of
                this and not lie. You'll know what we know.
                <br/> <br/>
                Feel free to browse around using the Menu button.
                <br/> <br/>
                If something isn't up to your standards, have any questions, suggestions,
                feedback, or wanna just send pictures of dogs licking windows, contact
                Customer Service.
                <br/> <br/>
                10 out of 10 you'll get me, Travis 🙂
                <br/> <br/> <br/>
                Send this link to your friend
            </>
    },
    'New message!': {
        padding: '42px',
        buttonText: 'Reply',
        link: '/customer_service?toggle=Driver',
        opacity: 0.9,
        isMoreText: true
    },
    'Your invite was accepted': {
        padding: '30px',
        opacity: 0.9,
        isMoreText: false
    },
    'messages!': {
        padding: '42px',
        buttonText: 'Check updates',
        link: '/customer_service',
        opacity: 0.9,
        isMoreText: true,
        width: '206px'
    },
    'Your order is accepted!': {
        padding: '26px',
    },
    'Your order was canceled': {
        padding: '42px',
        buttonText: 'Chat',
        opacity: 0.9,
        // link: '/',
        link: '/customer_service?toggle=Driver',
    },
    'Order is delivered!': {
        padding: '37px',
        buttonText: 'Rate service',
        // link: '/',
        opacity: 0.9,
        link: '/customer_service?toggle=Customer',
    },
    'Address is approved': {},
    'We miss you!': {
        padding: '37px',
        buttonText: 'Chat with customer service',
        // link: '/',
        link: '/customer_service',
        opacity: 0.9,
        width: '294px'
    },
    "We're a mess without you..": {
        padding: '37px',
        buttonText: 'Open the Menu',
        link: '/products',
        width: '254px'
    }
}
