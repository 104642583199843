import {gql} from "@apollo/client";
export const CREATE_ORDER = gql`
    mutation CreateUserOrder($phone: String!, $products: [ProductResponseInput]!, $delivery: Delivery) {
        createUserOrder(input: {phone: $phone, products: $products, delivery: $delivery}) {
            id
            
        }
    }
`

export const UPDATE_ORDER = gql`
    mutation UpdateOrder($phone: String!, $products: [ProductUpdateInput]! $id: String!) {
        updateOrder(input: {phone: $phone, products: $products, id: $id}) {
            id
        }
    }
`

export const DELETE_ORDER = gql`
    mutation DeleteOrder($phone: String!, $id: String!) {
        deleteOrder(input: {phone: $phone, id: $id}) {
            id
        }
    }
`
export const REMOVE_ADMIN_ORDER = gql`
    mutation RemoveAdminOrder($phone: String!, $id: String!) {
        removeAdminOrder(input: {phone: $phone, id: $id}) {
            id
        }
    }
`
export const ACCEPT_ADMIN_ORDER = gql`
    mutation AcceptAdminOrder($phone: String!, $id: String!) {
        acceptAdminOrder(input: {phone: $phone, id: $id}) {
            id
        }
    }
`

export const FIVE_MINUTES_ADMIN_ORDER = gql`
    mutation FiveMinutesAdminOrder($phone: String!, $id: String!) {
        fiveMinutesAdminOrder(input: {phone: $phone, id: $id}) {
            id
        }
    }
`

export const TEN_MINUTES_ADMIN_ORDER = gql`
    mutation TenMinutesAdminOrder($phone: String!, $id: String!) {
        tenMinutesAdminOrder(input: {phone: $phone, id: $id}) {
            id
        }
    }
`
export const TWENTY_MINUTES_ADMIN_ORDER = gql`
    mutation TwentyMinutesAdminOrder($phone: String!, $id: String!) {
        twentyMinutesAdminOrder(input: {phone: $phone, id: $id}) {
            id
        }
    }
`

export const HERE_ADMIN_ORDER = gql`
    mutation HereAdminOrder($phone: String!, $id: String!) {
        hereAdminOrder(input: {phone: $phone, id: $id}) {
            id
        }
    }
`

export const ETF_ADMIN_ORDER = gql`
    mutation EtfAdminOrder($phone: String!, $id: String!) {
        etfAdminOrder(input: {phone: $phone, id: $id}) {
            id
        }
    }
`
export const ETF_PAYMENT_ADMIN_ORDER = gql`
    mutation EtfPaymentOrderAdmin($phone: String!, $id: String!) {
        etfPaymentOrderAdmin(input: {phone: $phone, id: $id}) {
            id
        }
    }
`

export const COMPLETED_ADMIN_ORDER = gql`
    mutation CompletedAdminOrder($phone: String!, $id: String!) {
        completedAdminOrder(input: {phone: $phone, id: $id}) {
            id
        }
    }
`
