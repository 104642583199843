import {GET_ACTIVE_ORDERS, GET_NUMBER_TRUSTED_USERS, GET_USERS} from '../../api'
import React, {memo, useEffect, useState} from 'react'
import {useLazyQuery, useQuery} from '@apollo/client'
import {ButtonFlex, Image, BackButtonAdmin, Flex, StatisticAdmin} from '../common';
import {
    ChatWrapper,
    Orders,
    Filters,
    SortBy,
    Show,
    ToggleSortBy,
    ToggleShow,
    CommentsOrder,
    TooltipPhoneWrapper,
    TooltipPhoneContent,
    AdminWrapper,
    AdminMessage,
    Text,
    TimeAdmin,
    LastMessage,
    ToggleAdmin,
    TitleAdmin,
    TimeAdminRead, FlexDriver
} from './styled'
import {v4 as uuidv4} from "uuid";
import Loader from "../loader";
import moment from "moment";
import {useNavigate, useParams} from "react-router";
import {Link, Route, Routes, useLocation, useSearchParams} from "react-router-dom";
import Address from "../../assets/orders/Address.svg";
import Cart from "../../assets/orders/Cart.svg";
import Comments from "../../assets/orders/Comments.svg";
import NoComments from "../../assets/orders/NoComments.svg";
import Name from "../../assets/orders/Name.svg";
import Price from "../../assets/orders/Price.svg";
import Change from "../../assets/orders/Change.svg"
import DeliveryDate from "../../assets/orders/DeliveryDate.svg";
import Call from "../../assets/orders/Call.svg";
import Dollar from "../../assets/orders/dollar.svg";
import Status from "../../assets/orders/Status.svg";
import {HeaderToggleWrapper, HeaderWrapper, Toggle} from "../chat/header/styled";
import Revise from "./revise";
import {OrderReviseType} from "../../types";
import Admin from "../admin";
import Statistics from "../statistics";

const map_status = {
    'here': 1,
    'in 5 min': 2,
    'in 10 min': 3,
    'in 20 min': 4,
    'approved': 5,
    'draft': 6,
    'pending': 7
} as { [key: string]: number };

const Driver = ({token, isAdmin, isDriverActive}: PropsType) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const adminToggle = searchParams.get("adminToggle")
    const orderFromClient = searchParams.get("orderFromClient")
    console.log('orderFromClient:',orderFromClient)
    console.log('adminToggle:',adminToggle)
    // adminToggle=Driver&orderFromClient=100008
    const location = useLocation()
    const [currentId, setCurrentId] = useState<string | null>(null)
    // const [getUsersCommentsForAdmin, {loading, data}] = useLazyQuery(GET_USERS)
    const [getActiveOrders, {loading: loadingOrders, data: dataOrders}] = useLazyQuery(GET_ACTIVE_ORDERS, {fetchPolicy: 'no-cache'})
    const [route, setRoute] = useState('orders')
    const [users, setUsers] = useState(null)
    const [orders, setOrders] = useState<OrderReviseType[]>()
    const [toggle, setToggle] = useState(false)
    const navigate = useNavigate()
    const [all, setAll] = useState(true)
    const [etf, setEtf] = useState(false)
    const [competed, setCompleted] = useState(false)
    const [activeUnread, setActiveUread] = useState(false)
    const [activeSDate, setActiveDate] = useState(true)
    const [idClient, setIdClient] = useState<string>('')
    const [tokenClient, setTokenClient] = useState<string>('')
    const [orderClient, setOrderClient] = useState<OrderReviseType>()
    const [dayClient, setDay] = useState<string>('')
    const [dataClient, setData] = useState<string>('')
    const [countClient, setCount] = useState<number>(0)
    const [commentsClient, setComments] = useState<number>(0)
    const [orderId, setOrderId] = useState<string>()
    const [discount, setDiscount] = useState<string>()

    useEffect(() => {
        if(adminToggle === 'Customer'){
            setToggle(true)
            // setTimeout(()=>{
            //     setSearchParams('')
            // }, 10000)
        }
        if(adminToggle === 'Driver' && !orderFromClient){
            setToggle(false)
            // setTimeout(()=>{
            //     setSearchParams('')
            // }, 10000)
        }
        if(orderFromClient){
            dataOrders?.getActiveOrders.forEach((order:OrderReviseType)  => {
                if(order.id === orderFromClient){
                    setIdClient(orderFromClient)
                    setTokenClient(order.phone)
                    setOrderClient(order)
                    setOrderId(order.id)
                    let allCount = order.products.reduce((a, c) => a + c.count, 0)
                    const dateDelivery = order.delivery_date && moment(order?.delivery_date).format('D MMM').toLowerCase()
                    const today = moment().format('dddd')
                    const tomorrow = moment().add(1, 'day').format('dddd');
                    console.log('presentTime_____________', moment().format('DD-MM-YYYY h:mma'))
                    moment.tz.setDefault('America/Winnipeg')


                    let day = ''
                    if (today === moment(order.delivery_date).format('dddd')) {
                        day = 'Today'
                    }
                    if (tomorrow === moment(order.delivery_date).format('dddd')) {
                        day = 'Tomorrow'
                    }
                    if (today !== moment(order.delivery_date).format('dddd') && tomorrow !== moment(order.delivery_date).format('dddd')) {
                        day = moment(order.delivery_date).format('dddd')
                    }
                    if(order.status === 'etf') {
                        day = moment(order.delivery_date).format('dddd')
                    }
                    const isUserRead = order.user.comments?.filter(i =>
                        i.role === 'send_order_comments_user' ||
                        i.role === 'driver_order' ||
                        i.role === 'images' ||
                        i.role === 'images_admin' ||
                        i.role === 'images_driver' ||
                        i.role === 'images_client_driver' ||
                        i.role === 'images_admin_driver'
                    ).filter(i => !i.isAdminRead)
                    setDay(day)
                    setData(dateDelivery)
                    setCount(allCount)
                    setComments(isUserRead.length)
                }
                    })
            setRoute('work_order')
            setTimeout(()=>{
                setSearchParams('')
            }, 20000)

        }
        if (isDriverActive) {
            getActiveOrders({
                variables: {phone: token, isAdmin, isDriverActive, toggle}
            })
                .then(({data}) => {
                    console.log(data)
                })
        }
        if (isAdmin) {
            console.log("token, isAdmin, isDriverActive, toggle+++++++++++++++++++:",token, isAdmin, isDriverActive, toggle)
            getActiveOrders({

                variables: {phone: token, isAdmin, isDriverActive, toggle}
            })
                .then(({data}) => {
                    console.log("data=-=================================", data)
                })
        }
            if (all && activeSDate) {
                if (dataOrders?.getActiveOrders) {
                    // @ts-ignore
                    let arrayFilter = []
                    let filterFromUnread = dataOrders?.getActiveOrders.filter((i: OrderReviseType) =>
                        i.status === 'draft' ||
                        i.status === 'pending' ||
                        i.status === 'approved' ||
                        i.status === 'in 5 min' ||
                        i.status === 'in 10 min' ||
                        i.status === 'in 20 min' ||
                        i.status === 'here'
                    ).sort((a: OrderReviseType, b: OrderReviseType) => {
                        if (moment(a.delivery_date).isAfter(b.delivery_date)) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                    console.log('active && date:', filterFromUnread)
                    const today = moment().format('dddd')
                    const tomorrow = moment().add(1, "day").format('dddd')
                    moment.tz.setDefault('America/Winnipeg')
                    const ordersToday = filterFromUnread.filter((o: OrderReviseType) => today === moment(o.delivery_date).format('dddd'))
                    const ordersTomorrow = filterFromUnread.filter((o: OrderReviseType) => tomorrow === moment(o.delivery_date).format('dddd'))
                    const noTodayAndNoTomorrow = filterFromUnread.filter((o: OrderReviseType) => tomorrow !== moment(o.delivery_date).format('dddd') && today !== moment(o.delivery_date).format('dddd'))
                    let todayArray = [] as any
                    ordersToday.forEach((o: any) => {
                        let arr = [] as any
                        ordersToday.forEach((i: any) => {
                            if(o.timeslot === i.timeslot){
                                    arr.push(i)
                            }
                        })
                            todayArray.push(arr.sort((a: any, b: any) => map_status[a.status] - map_status[b.status]))
                    })
                    // @ts-ignore
                    const uniqueDriverInFriday = [...new Set(todayArray.flat())];

                    const arrayFin = [...noTodayAndNoTomorrow, ...uniqueDriverInFriday, ...ordersTomorrow ]
                    // noTodayAndNoTomorrow.concat(uniqueDriverInFriday,ordersTomorrow)
                    console.log("todarray:",arrayFin)
                    setOrders([...noTodayAndNoTomorrow, ...uniqueDriverInFriday, ...ordersTomorrow ])
                }
            }

            if (etf && activeSDate) {
                if (dataOrders?.getActiveOrders) {
                    const filterFromUnread = dataOrders?.getActiveOrders.filter((i: OrderReviseType) =>
                        i.status === 'etf'
                    ).sort((a: OrderReviseType, b: OrderReviseType) => {
                        if (moment(a.delivery_date).isAfter(b.delivery_date)) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                    console.log('etf && date:', filterFromUnread)
                    setOrders(filterFromUnread)
                }
            }
            if (competed && activeSDate) {
                if (dataOrders?.getActiveOrders) {
                    const filterFromUnread = dataOrders?.getActiveOrders.filter((i: OrderReviseType) =>
                        i.status === 'completed' || i.status === 'etf-completed' || i.status === 'canceled' || i.status === 'closed'
                    ).sort((a: OrderReviseType, b: OrderReviseType) => {
                        if (moment(a.delivery_date).isAfter(b.delivery_date)) {
                            return -1
                        } else {
                            return 1
                        }
                    })
                    console.log('completed && date:', filterFromUnread)
                    setOrders(filterFromUnread)
                }
            }
            if (all && activeUnread) {
                if (dataOrders?.getActiveOrders) {
                    let unreadComments = [] as OrderReviseType[]
                    const filterFromUnread = dataOrders?.getActiveOrders.filter((i: OrderReviseType) =>
                        i.status === 'draft' ||
                        i.status === 'pending' ||
                        i.status === 'approved' ||
                        i.status === 'in 5 min' ||
                        i.status === 'in 10 min' ||
                        i.status === 'in 20 min' ||
                        i.status === 'here'
                    ).forEach((order: OrderReviseType) => {
                        const isAdminRead = order.user.comments?.filter(i =>
                            // i.role === 'draft' ||
                            // i.role === 'approved' ||
                            // i.role === 'in 20 min' ||
                            // i.role === 'in 10 min' ||
                            // i.role === 'in 5 min' ||
                            // i.role === 'here' ||
                            // i.role === 'etf' ||
                            // i.role === 'canceled' ||
                            i.role === 'send_order_comments_user' ||
                            i.role === 'driver_order' ||
                            i.role === 'images' ||
                            i.role === 'images_admin' ||
                            i.role === 'images_driver' ||
                            i.role === 'images_client_driver' ||
                            i.role === 'images_admin_driver'
                        ).some(el => !el.isAdminRead)
                        if(isAdminRead){
                            unreadComments.push(order)
                        }
                    })

                    unreadComments.sort((a: OrderReviseType, b: OrderReviseType) => {
                        if (moment(a.delivery_date).isAfter(b.delivery_date)) {
                            return -1
                        } else {
                            return 1
                        }
                    })

                    console.log('completed && unread:', unreadComments)
                    setOrders(unreadComments)
                }
            }
            if (etf && activeUnread) {
                if (dataOrders?.getActiveOrders) {
                    let unreadComments = [] as OrderReviseType[]
                    const filterFromUnread = dataOrders?.getActiveOrders.filter((i: OrderReviseType) =>
                        i.status === 'etf'
                    ).forEach((order: OrderReviseType) => {
                        const isAdminRead = order.user.comments?.filter(i =>
                            // i.role === 'draft' ||
                            // i.role === 'approved' ||
                            // i.role === 'in 20 min' ||
                            // i.role === 'in 10 min' ||
                            // i.role === 'in 5 min' ||
                            // i.role === 'here' ||
                            // i.role === 'etf' ||
                            // i.role === 'canceled' ||
                            i.role === 'send_order_comments_user' ||
                            i.role === 'driver_order' ||
                            i.role === 'images' ||
                            i.role === 'images_admin' ||
                            i.role === 'images_driver' ||
                            i.role === 'images_client_driver' ||
                            i.role === 'images_admin_driver'
                        ).some(el => !el.isAdminRead)
                        if(isAdminRead){
                            unreadComments.push(order)
                        }
                    })
                    unreadComments.sort((a: OrderReviseType, b: OrderReviseType) => {
                        if (moment(a.delivery_date).isAfter(b.delivery_date)) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                    console.log('completed && unread:', unreadComments)
                    setOrders(unreadComments)
                }
            }
            if (competed && activeUnread) {
                if (dataOrders?.getActiveOrders) {
                    let unreadComments = [] as OrderReviseType[]
                    const filterFromUnread = dataOrders?.getActiveOrders.filter((i: OrderReviseType) =>
                        i.status === 'completed'|| i.status === 'etf-completed' || i.status === 'canceled' || i.status === 'closed'
                    ).forEach((order: OrderReviseType) => {
                        const isAdminRead = order.user.comments?.filter(i =>
                            // i.role === 'draft' ||
                            // i.role === 'approved' ||
                            // i.role === 'in 20 min' ||
                            // i.role === 'in 10 min' ||
                            // i.role === 'in 5 min' ||
                            // i.role === 'here' ||
                            // i.role === 'etf' ||
                            // i.role === 'canceled' ||
                            i.role === 'send_order_comments_user' ||
                            i.role === 'driver_order' ||
                            i.role === 'images' ||
                            i.role === 'images_admin' ||
                            i.role === 'images_driver' ||
                            i.role === 'images_client_driver' ||
                            i.role === 'images_admin_driver'
                        ).some(el => !el.isAdminRead)
                        if(isAdminRead){
                            unreadComments.push(order)
                        }
                    })

                    unreadComments.sort((a: OrderReviseType, b: OrderReviseType) => {
                        if (moment(a.delivery_date).isAfter(b.delivery_date)) {
                            return -1
                        } else {
                            return 1
                        }
                    })

                    console.log('completed && unread:', unreadComments)
                    setOrders(unreadComments)
                }
            }



    }, [token, orderFromClient, adminToggle, all, activeSDate, activeUnread, competed, etf, setEtf, setActiveDate, setAll, setActiveDate, setActiveUread, setOrders, isAdmin, isDriverActive, dataOrders, dataOrders?.getActiveOrders])
    if (loadingOrders) {
        return <Loader/>
    }

    function changeFilter(value: string) {
        console.log('value:', value)
        if (value === 'unread' || value === 'date') {
            if (value === 'unread') {
                setActiveUread(true)
                setActiveDate(false)

            }
            if (value === 'date') {
                setActiveDate(true)
                setActiveUread(false)

            }
        }
        if (value === 'all' || value === 'etf' || value === 'completed') {
            if (value === 'all') {
                setAll(true)
                setEtf(false)
                setCompleted(false)
            }
            if (value === 'etf') {
                setEtf(true)
                setAll(false)
                setCompleted(false)
            }
            if (value === 'completed') {
                setCompleted(true)
                setAll(false)
                setEtf(false)
            }
        }
    }


    function viewOrder(id: string, phone: string, order: OrderReviseType, day: string, dateDelivery: string, allCount: number, isUserRead: number) {
        if (id) {
            setIdClient(id)
        }
        if (phone) {
            setTokenClient(phone)
        }
        if (order) {
            setOrderClient(order)
            setOrderId(order.id)
        }
        if (day) {
            setDay(day)
        }
        if (dateDelivery) {
            setData(dateDelivery)
        }
        if (allCount) {
            setCount(allCount)
        }
        if (isUserRead) {
            setComments(isUserRead)
        }
        setRoute('work_order')
    }


    return (
        <>
            {isAdmin &&
                <>
                    <ButtonFlex margin={'30px 0 10px 0'} className={"margin__top"}>
                        <Link to={'/statistics'}>
                        <StatisticAdmin  width={"140px"}>Statistics</StatisticAdmin>
                        </Link>
                    </ButtonFlex>
                </>
            }

            {route === 'orders' &&
                <>

                    <ChatWrapper>
                        <HeaderWrapper>
                            <HeaderToggleWrapper>
                                {isDriverActive && <Toggle noOrders={true}>
                                    <span>Orders</span>
                                </Toggle>}
                                {isAdmin && <>
                                    <ToggleAdmin className={!toggle ? 'active' : ''} onClick={() => setToggle(false)}>
                                        <span>Orders</span>
                                    </ToggleAdmin>
                                    <ToggleAdmin className={toggle ? 'active' : ''} onClick={() => setToggle(true)}>
                                        <span>Customer service</span>
                                    </ToggleAdmin>
                                </>}
                            </HeaderToggleWrapper>
                        </HeaderWrapper>
                        {!toggle &&
                            <>
                                <Orders>Orders</Orders>
                                <Filters>
                                    <SortBy>
                                        <div>Sort by:</div>
                                        <ToggleSortBy>
                                            <div onClick={() => {
                                                changeFilter('date')
                                            }}><a className={activeSDate ? 'active__sort' : ''}>Date</a></div>
                                            <div onClick={() => {
                                                changeFilter('unread')
                                            }}><a className={activeUnread ? 'active__sort' : ''}>Unread</a></div>
                                        </ToggleSortBy>
                                    </SortBy>
                                    <Show>
                                        <div>Show:</div>
                                        <ToggleShow>
                                            <div onClick={() => {
                                                changeFilter('all')
                                            }}><a className={all ? 'active' : ''}>Aсtive</a></div>
                                            <div onClick={() => {
                                                changeFilter('etf')
                                            }}><a className={etf ? 'active' : ''}>ETF</a></div>
                                            <div onClick={() => {
                                                changeFilter('completed')
                                            }}><a className={competed ? 'active' : ''}>Completed</a></div>
                                        </ToggleShow>
                                    </Show>
                                </Filters>
                                {orders && orders.map((i: OrderReviseType, idx) => {
                                    const isUserRead = i.user.comments?.filter(i =>
                                        // i.role === 'draft' ||
                                        // i.role === 'approved' ||
                                        // i.role === 'in 20 min' ||
                                        // i.role === 'in 10 min' ||
                                        // i.role === 'in 5 min' ||
                                        // i.role === 'here' ||
                                        // i.role === 'etf' ||
                                        // i.role === 'canceled' ||
                                        i.role === 'send_order_comments_user' ||
                                        i.role === 'driver_order' ||
                                        i.role === 'images' ||
                                        i.role === 'images_admin' ||
                                        i.role === 'images_driver' ||
                                        i.role === 'images_client_driver' ||
                                        i.role === 'images_admin_driver'
                                    ).filter(i => !i.isAdminRead)
                                    console.log('isUserReadComments:', isUserRead)
                                    // console.log('isUserRead:',isUserRead)
                                    let allCount = i.products.reduce((a, c) => a + c.count, 0)
                                    const dateDelivery = i.delivery_date && moment(i?.delivery_date).format('D MMM').toLowerCase()
                                    const today = moment().format('dddd')
                                    moment.tz.setDefault('America/Winnipeg')
                                    // const curr = moment().format()
                                    // const currentDay = moment(curr).format('dddd');
                                    console.log('presentTime_____________', moment().format('DD-MM-YYYY h:mma'))
                                    const tomorrow = moment().add(1, 'day').format('dddd');
                                    let day = ''
                                    if (today === moment(i.delivery_date).format('dddd')) {
                                        day = 'Today'
                                    }
                                    if (tomorrow === moment(i.delivery_date).format('dddd')) {
                                        day = 'Tomorrow'
                                    }
                                    if (today !== moment(i.delivery_date).format('dddd') && tomorrow !== moment(i.delivery_date).format('dddd')) {
                                        day = moment(i.delivery_date).format('dddd')
                                    }
                                    if(i.status === 'etf') {
                                        day = moment(i.delivery_date).format('dddd')
                                    }
                                    let sixWeeks
                                    let sevenWeeks
                                    if(i.user.six_weeks_limited && i.user.seven_weeks_limited){
                                        console.log('i.user?.six_weeks_limited:', i.user.six_weeks_limited)
                                        console.log('i.user?.seven_weeks_limited:', i.user.seven_weeks_limited)

                                        sixWeeks = moment().isAfter(i.user.six_weeks_limited)
                                        sevenWeeks = moment().isBefore(i.user.seven_weeks_limited)
                                        console.log('sixWeeks, sevenWeeks-driver:',sixWeeks, sevenWeeks)

                                        // if(sixWeeks && sevenWeeks) {
                                        //     setDiscount('10%')
                                        // }
                                    }
                                    let stat = i.status.split("")
                                    stat.shift()
                                    stat.unshift(i.status.split("")[0].toUpperCase())
                                    const status = stat.join("")
                                    let savings = i.total - i.total_after_discount
                                    return (
                                        <>
                                            <AdminMessage
                                            >

                                                <FlexDriver >
                                                    <img alt={'name'} src={Name}/>
                                                    <Text>{i.user?.first_name}</Text>
                                                    <div >
                                                        <TooltipPhoneWrapper id={i.id} onClick={(e) => {
                                                            console.log('target:',e.currentTarget.id)
                                                            setCurrentId(e.currentTarget.id)
                                                            setTimeout(()=>{setCurrentId('')},3000)
                                                        }} className={currentId === i.id? 'tooltip' : ''}>
                                                            <a className='cart__info-image' >{i.phone}</a>
                                                            <TooltipPhoneContent className={currentId === i.id? 'tooltip' : ''} >
                                                                <a href={`tel:${i.phone}`}>
                                                                    <div className="text">
                                                                        <img alt={'call'} src={Call}/>
                                                                        <p>Call</p>
                                                                    </div>
                                                                </a>
                                                            </TooltipPhoneContent>

                                                        </TooltipPhoneWrapper>
                                                    </div>
                                                </FlexDriver>
                                                <FlexDriver onClick={() => {
                                                    viewOrder(i.id, i.phone, i, day, dateDelivery, allCount, isUserRead && isUserRead.length ? isUserRead.length : 0)
                                                }}>
                                                    <img alt={'date'}
                                                         src={DeliveryDate}/><Text>{day} {dateDelivery}, {i.timeslot}</Text>
                                                </FlexDriver>
                                                <FlexDriver onClick={() => {
                                                    viewOrder(i.id, i.phone, i, day, dateDelivery, allCount, isUserRead && isUserRead.length ? isUserRead.length : 0)
                                                }}>
                                                    <img alt={'address'}
                                                         src={Address}/><Text>{i.address} | {i.district.split('.')[0]}</Text>
                                                </FlexDriver>
                                                <FlexDriver onClick={() => {
                                                    viewOrder(i.id, i.phone, i, day, dateDelivery, allCount, isUserRead && isUserRead.length ? isUserRead.length : 0)
                                                }}>
                                                    <img alt={'status'}
                                                         src={Status}/><Text>{i.status === "draft" ? "Scheduled" : status}</Text>
                                                </FlexDriver>
                                                <FlexDriver onClick={() => {
                                                    viewOrder(i.id, i.phone, i, day, dateDelivery, allCount, isUserRead && isUserRead.length ? isUserRead.length : 0)
                                                }}>
                                                    <img alt={'price'} src={Price}/>
                                                    <Text>${i.total_after_discount} ({sixWeeks && sevenWeeks ? `10%` : `$${savings}`})</Text>
                                                    <img alt={'change'} src={Change}/>
                                                    {i.change !== 'no change'
                                                        ?<Text>${i.change}</Text>
                                                        :<Text>$0</Text>
                                                    }
                                                </FlexDriver>
                                                <FlexDriver onClick={() => {
                                                    viewOrder(i.id, i.phone, i, day, dateDelivery, allCount, isUserRead && isUserRead.length ? isUserRead.length : 0)
                                                }}>
                                                    <img alt={'dollar'}
                                                         src={Dollar}/><Text>${i.profit}</Text>
                                                    <img alt={'cart'} src={Cart}/>
                                                    <Text> x{i.products.length}, {allCount}</Text>
                                                </FlexDriver>
                                                <CommentsOrder onClick={() => {
                                                    navigate(`customer_service/${i?.user?.id}?first_name=${i?.user?.first_name}&phone_user=${i?.phone}&toggle=Driver&orderFromDriver=${i.id}`, {
                                                        // replace: true,
                                                        // state: {phone_user: i.phone, first_name: i.first_name, id_user: i.id},
                                                    })
                                                }}>
                                                    {isUserRead && isUserRead.length && isUserRead.length > 0
                                                        ?
                                                        <>
                                                            {isUserRead.length > 9
                                                                ?
                                                                <>
                                                                    <img alt={'comments'} src={Comments}/>
                                                                    <Text>9+ new</Text>
                                                                </>
                                                                :
                                                                <>
                                                                    <img alt={'comments'} src={Comments}/>
                                                                    <Text>{isUserRead.length} new</Text>
                                                                </>

                                                            }

                                                        </>
                                                        :
                                                        <>
                                                            <img alt={'comments'} src={NoComments}/>
                                                            <Text> </Text>
                                                        </>

                                                    }
                                                </CommentsOrder>
                                            </AdminMessage>

                                        </>
                                    )
                                })
                                }
                            </>
                        }
                        {toggle && <>
                            <Admin token={token} isAdmin={isAdmin}/>
                        </>}

                    </ChatWrapper>
                </>

            }
            {route === 'work_order' && <Revise
                setRoute={setRoute}
                id={idClient}
                token={tokenClient}
                order={orderClient}
                day={dayClient}
                data={dataClient}
                allCount={countClient}
                comments={commentsClient}
                isAdmin={isAdmin}
                isDriverActive={isDriverActive}
                setActiveUread={setActiveUread}
                setActiveDate={setActiveDate}
                setAll={setAll}
                setEtf={setEtf}
                setCompleted={setCompleted}
                getActiveOrders={getActiveOrders}

            />}


        </>
    )
}

export default Driver

interface PropsType {

    token: string,
    isAdmin: boolean
    isDriverActive: boolean
}
