import styled from 'styled-components'

export const DialogueScroll = styled.div<{ view?: string }>`
  overflow: auto;
  padding: 5px 0 10px 0;
  height: calc(100vh - (var(--chatHeader) + 180px));
  flex-grow: 1;
  width: 100%;
  //overflow: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(108, 172, 79, 0.4);
    border-radius: 5px;
  }


  @media screen and (min-width: 743px) {
    height: calc(100vh - (var(--chatHeader) + 180px));
    //height:100vh;
    width: 100%;

    background: #FFFFFF;
    box-shadow: 0px 3px 2px rgba(67, 86, 59, 0.2);
    padding: 12px 0 30px 0;
  }

  @media screen and (min-width: 1280px) {
    /* padding: 12px 0 20px; */
    width: 100%;

    height: calc(100vh - (var(--chatHeader) + 180px));
  }
`


export const DialogueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  .call__user-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #616A5C;
    opacity: 0.8;
    text-decoration-line: initial;
  }
  .wrapper__p {
    padding: 0 24px 10px 24px;
    z-index: -105;
    &.hide {
      display: none;
    }
    &.active {
      z-index: 1;
      background: #c4c4c433;
      border-radius: 12px;
    }
  }
  .dialogue__p {
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #616A5C;
    opacity: 0;
    padding-top: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -100;
    transform: translateY(-120%);
    transition: 0.4s;
    max-width: 516px;
    margin: 0 auto;
    
    span {
      font-weight: 700;
    }

    &.active {
      position: relative;
      transition: 0.5s 0.3s;
      opacity: 0.8;
      transform: translateY(0);
      z-index: 1;
    }
  }
  :last-child {
    margin-bottom: 50px;
  }

  @media screen and (min-width: 743px) {
    gap: 8px;
    padding: 0 0;
    .dialogue__p {
      text-align: right;
      font-size: 18px;
      line-height: 140%;
    }
    :last-child {
      margin-bottom: 50px;
    }
  }

  @media screen and (min-width: 1280px) {
    gap: 8px;
    padding: 0 0 0 0;
    .dialogue__p, .dialogue__p.active {
      display: none;
    }
    
    :last-child {
      margin-bottom: 50px;
    }
  }
`

export const Customer = styled.div`
  padding: 14px 11px 9px 17px;
  background: #c4c4c433;
  border-radius: 12px;
  margin-left: 32px;
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 743px) {
    margin-left: 32px;
    width: calc(100% - 32px);
  }
`

export const DriverWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  //img {
  //    transform: translateX(-14px);
  //  //margin-left: 15px;
  //}
  .driver__icon {
    width: 32px;
    height: 32px;
    align-self: flex-end;

  }

  @media screen and (min-width: 743px) {
    //.driver__icon{
    //  width: 32px;
    //  height: 32px;
    //  //align-self: flex-end;
    //
    //}
    img {
      transform: translateX(0px);
      //height: 32px;
    }

  }
`

export const AdminWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  //img {
  //    transform: translateX(-14px);
  //  //margin-left: 15px;
  //}
  .driver__icon {
    width: 32px;
    height: 32px;
    align-self: flex-end;

  }

  @media screen and (min-width: 743px) {
    //.driver__icon{
    //  width: 32px;
    //  height: 32px;
    //  //align-self: flex-end;
    //
    //}
    img {
      transform: translateX(0px);
      height: 32px;
    }

  }
`
export const CustomerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  //margin-left: 25px;
  margin-left: 40px;
  width: calc(100% - 40px);

  img {
    align-self: center;
    //transform: translateX(-14px);
    border-radius: 12px;

  }

  @media screen and (min-width: 743px) {
    margin-left: 40px;
    width: calc(100% - 40px);
    gap: 8px;
    img {
      transform: translateX(0px);
      //height: 40px;
    }
  }
`
export const ImageAdminWrapper = styled.div<{ background?: string }>`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  height: 280px;
  background-size: cover;
  margin-left: 40px;
  width: calc(100% - 40px);
  border-radius: 12px;
  background: ${({background}) => background};
  background-position: center;

  @media screen and (min-width: 743px) {
    margin-left: 40px;
    width: calc(100% - 40px);
    background-size: cover;
    height: 280px;
    gap: 8px;
    border-radius: 12px;
    background: ${({background}) => background};
    background-position: center;
  }
`
export const ImageCustomerWrapper = styled.div<{ background?: string }>`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  height: 280px;
  background-size: cover;
  width: calc(100% - 40px);
  border-radius: 12px;
  background: ${({background}) => background};
  background-position: center;

  @media screen and (min-width: 743px) {
    width: calc(100% - 40px);
    background-size: cover;
    height: 280px;
    gap: 8px;
    border-radius: 12px;
    background: ${({background}) => background};
    background-position: center;
  }
`
export const ReferButton = styled.button`
  box-sizing: border-box;
  //width: 316px;
  height: 42px;
  border: 1px solid #72AE56;
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  color: #418A1F;

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    opacity: 0.7;
  }


`
export const Driver = styled.div`
  background: #55b4284d;
  border-radius: 12px;
  padding: 13px 11px 9px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;

    p {

      font-weight: bold;
      font-size: 8px;
      line-height: 130%;

      text-transform: uppercase;
      color: #616A5C;
      opacity: 0.6;
    }
  }
`

export const Admin = styled.div`
  background: #c4c4c433;
  border-radius: 12px;
  padding: 13px 11px 9px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;

    p {

      font-weight: bold;
      font-size: 8px;
      line-height: 130%;

      text-transform: uppercase;
      color: #616A5C;
      opacity: 0.6;
    }
  }
`
export const CustomerMessage = styled.div`
  background: #c4c4c433;
  border-radius: 12px;
  padding: 13px 11px 9px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;

    p {
      font-weight: bold;
      font-size: 8px;
      line-height: 130%;

      text-transform: uppercase;
      color: #616A5C;
      opacity: 0.6;
    }
  }
`
export const AdminMessage = styled.div`
  background: #55b4284d;
  border-radius: 12px;
  padding: 13px 11px 9px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;

    p {
      font-weight: bold;
      font-size: 8px;
      line-height: 130%;

      text-transform: uppercase;
      color: #616A5C;
      opacity: 0.6;
    }
  }
`
export const WrapperRating = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  min-width: 170px;
  height: 34px;

  button {
    cursor: pointer;
    flex-grow: 1;
    width: 100%;
    height: 100%;

  }

  .cart__info-image {
    padding-top: 5px;
  }
`


export const Time = styled.span`
  font-weight: 600;
  font-size: 8px;
  line-height: 130%;
  color: #616A5C;
  opacity: 0.6;
  align-self: flex-end;
`

export const Date = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #616A5C;
  opacity: 0.8;
  margin-top: 18px;
  margin-bottom: 12px;
  @media screen and (min-width: 743px) {
    font-size: 16px;
  }
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 130%;
  color: #616A5C;
  opacity: 0.8;
  //text-align: justify;

  @media screen and (min-width: 743px) and (max-width: 1279px) {
    font-size: 18px;
  }
`
