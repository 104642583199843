import React from 'react';
import './modal.css'
import {Image} from '../../common';
const Modal = ({active, setActive, image}:PropsType) => {

    return (
        <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <div className={active ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
                <div className='wrapper__cart'>
                    <Image className={"image__modal"} width={'100%'} src={image}/>
                </div>
            </div>
        </div>
    )
}
export default Modal
interface PropsType {
    active: boolean
    setActive: (active: boolean) => void
    image: string
}